require("./overrides.scss");
export default {
    theme: {
        themes: {
            light: {
                primary: "#0065b3",
                secondary: "#008dcd",
                accent: "#00b1c6",
                info: "#a4abbd",
                success: "#8ce983",
                warning: "#fd9644",
                error: "#C35354",
                navbutton: "#5A85A9",
                mapbutton: "#BD7C37",
                scenariobutton: "#98A753",
                textDark: "#2d3436",
                greyLight: "#E5E5E5",
                customWhite: "#FFFDFA"
            },
        },
    },
};
