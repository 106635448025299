<template>
    <v-dialog
      v-model="isOpen"
      class="popup_wrapper"
      content-class="custom-dialog"
      max-width="555"
    >
      <div>
        <v-sheet 
          v-if="isLoading"
          class="content_wrapper"
        >
          <v-skeleton-loader
            class="pa-3"
            type="table-heading, image, list-item, list-item, image, list-item"
          ></v-skeleton-loader>
        </v-sheet>
        <v-container v-else class="pa-1 ma-0">
          <v-sheet rounded class="pa-3 custom-shadow content_wrapper">
            <div class="d-flex justify-space-between">
              <div class="justify-start flex-column">
                <v-card-title
                  class="mb-2 text-h4 custom-gap scenariobutton--text d-flex align-center"
                >
                  <v-icon normal color="textDark" class="scenariobutton pa-1 rounded">
                    mdi mdi-layers-search
                  </v-icon>
                  <div class="textDark--text">
                    |
                  </div>
                    <span class="custom-title">{{ selectedPolygon?.content?.Area }}</span> 
                </v-card-title>
                <v-card-subtitle>
                  {{ $t(`polygonDialog.title`) }}
                </v-card-subtitle>
              </div>
              <div class="justify-end">
                <v-btn
                  depressed
                  outlined
                  style="border: none;"
                  class="mt-4 white--text"
                  @click="isOpen = !isOpen"
                >
                  <v-icon normal class="scenariobutton--text">
                    mdi mdi-close-thick
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <!-- General Emissions -->
            <h5 class="text-h6 font-weight-bold mb-5 d-flex align-center custom-gap justify-center">
                General Emissions
                <span class="text-body-1 font-weight-light">(t/year)</span>
              </h5>
            <v-slide-group
              v-if="Object.keys(selectedPolygon) != 0"
              multiple
              show-arrows
              center-active
              class="my-3"
            >
            <div 
              v-for="(value, name) in formatSelectedPolygon"
              :key="name"
              class="px-auto mx-auto"
            >
              <v-card
                outlined
                class="card_wrapper textDark--text ma-1 flex-column text-center d-flex custom-shadow"
                width="200"
                :height="value.value ? 200 : 150"
              >
                <v-card-title class="font-weight-bold text-h5 navbutton--text ma-auto">
                  {{ name }}
                </v-card-title>
                <v-divider class="divider-container ma-auto"></v-divider>
                <v-card-text class="font-weight-bold py-3 text-h6">
                  {{ formatNumbers(determinePolygonValue(value)) }}
                  <span class="font-weight-light text-caption">(t)</span>
                </v-card-text>
                <div v-if="value.diff">
                  <span class="text-caption navbutton--text">
                    {{ $t(`polygonDialog.difference`) }}
                  </span>
                  <v-card-text class="font-weight-bold text-subtitle-1">
                    <count-to-animation
                      :decimals="2"
                      :start-val="0"
                      separator="0"
                      :end-val="formatToNumbers(value.diff)"
                      :duration="1500"
                    >
                    </count-to-animation>
                    <span class="font-weight-light text-caption">(%)</span>
                  </v-card-text>
                </div>
              </v-card>
            </div>
            </v-slide-group>
            <v-divider class="my-5"></v-divider>
            <!-- Road Emissions -->
            <div v-if="Object.keys(selectedPolygon) != 0 && selectedPolygon.selectedRoad">
              <h5 class="text-h6 font-weight-bold mb-5 text-center align-center custom-gap">
                Selected Road Emissions
                <span class="text-body-1 font-weight-light">(t/year)</span>
              </h5>
              <div class="d-flex align-center text-center justify-center">
                <v-card-text>
                  Name:
                  <span class="font-weight-bold">{{ selectedPolygon.selectedRoad.int_name }}</span>
                </v-card-text>
                <v-card-text>
                  CO2eq_kt:
                  <span class="font-weight-bold">
                    {{ selectedPolygon.selectedRoad.CO2eq_kt }}
                    <span class="text-caption font-weight-light">(t)</span>
                  </span>
                </v-card-text>
              </div>
            </div>
            <v-divider v-if="Object.keys(selectedPolygon) != 0 && selectedPolygon.selectedRoad" class="my-5"></v-divider>
            <!-- General Road Emissions -->
            <div v-if="Object.keys(selectedPolygon) != 0">
              <h5 class="text-h6 font-weight-bold mb-5 d-flex align-center custom-gap justify-center">
                General Road Emissions
                <span class="text-body-1 font-weight-light">(t/year)</span>
              </h5>
              <div
                v-for="road in determineSelectedRoadScenario.roads"
                :key="road.id"
              >
                <h5 class="text-center font-weight-light text-h6">
                  {{ road.name }}
                </h5>
                <v-slide-group
                  multiple
                  show-arrows
                  center-active
                  class="my-3"
                >
                    <v-card
                      v-for="(pollutant, name) in road.pollutants"
                      :key="name"
                      outlined
                      class="card-_wrapper textDark--text ma-1 flex-column text-center d-flex custom-shadow"
                      width="125"
                      height="150"
                    >
                      <v-card-title class="font-weight-bold text-h5 navbutton--text ma-auto">
                        {{ name }}
                      </v-card-title>
                      <v-divider class="divider-container ma-auto"></v-divider>
                      <v-card-text class="font-weight-light py-3 text-h6">
                        {{ formatNumbers(pollutant) }}
                        <span class="text-caption font-weight-light">(t)</span>
                      </v-card-text>
                    </v-card>
                </v-slide-group>
              </div>
            </div>
          </v-sheet>
        </v-container>
      </div>
  </v-dialog>
</template>
  
<script>
// Importing counter animation library
import countTo from 'vue-count-to';

  export default {
    name: "BasePolygonDialog",
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: true,
      },
      selectedPolygon: {
        type: [Array, Object],
        required: true,
      },
      selectedLayer: {
        type: String,
        required: false,
        default: ""
      }
    },
    components: {
      "count-to-animation": countTo
    },
    data() {
        return {
            isOpen: false
        }
    },
    computed: {
      // Exclude proprty of an object
      formatSelectedPolygon() {
          return Object.keys(this.selectedPolygon.content)
          .filter(key => key !== "Area")
          .reduce((obj, key) => {
            obj[key] = this.selectedPolygon.content[key];
            return obj;
          }, {});
      },
      // Find road data for selected scenario
      determineSelectedRoadScenario () {
        return this.selectedPolygon.roads.scenarios.find(scenario => this.selectedLayer.includes(scenario.name));
      }
    },
    methods: {
      determinePolygonValue(value) {
        return value.value ? value.value : value;
      },

      formatToNumbers(input) {
        return Number(input);
      },

      // addCommasToNumbers(input) {
      //   if (!input) return "";

      //   const formatToNumber = Number(input).toFixed();

      //   return formatToNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // },
      // addCommasToNumbers(input) {
      //   if (!input) return "";
      
      //   let parts = input.split(".");
      //   let integerPart = parts[0];
      //   let decimalPart = parts[1] || "";

      //   // Add commas to the integer part
      //   integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      //   // Reconstruct the number with commas
      //   return decimalPart ? integerPart + "." + decimalPart : integerPart;
      // },

      formatNumbers (input) {
        if (!input) return "";

        let num = parseFloat(input.replace(",", "."));
        let formattedNum = num.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2});

        // Remove decimal part if comma exists
        if (formattedNum.includes(",")) {
            formattedNum = formattedNum.split(".")[0];
        }

        return formattedNum;
      }
    },
    watch: {
      dialog(_newVal) {
        this.isOpen = !this.isOpen;
      }
    },
  };
  </script>
  
  <style scoped>
  .popup_wrapper {
    z-index: 9999;
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for IE and Edge */
  }
  .popup_wrapper::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for Chrome and Safari */
  }
  .content_wrapper {
    background: rgba(255,255,255,0.8);
  }
  .card_wrapper {
    background: rgba(255,255,255,0.4);
  }
  .custom-dialog {
    position: absolute !important;
    top: 100px; /* Adjust the top position as needed */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
  }
  .divider-container {
      width: 50px;
      animation: dividerAnimation 2s ease infinite alternate;
  }
  .custom-title {
  font-weight: 900 !important;
  text-shadow: 1px 0px 1px #98A753, 0px 1px 0px rgba(0,0,0,0.15);
}
  
  /* Animated divider color */
  @keyframes dividerAnimation {
    0% {
      border-bottom: 2px solid #98A753;
    }
    50% {
      border-bottom: 2px solid #BD7C37;
    }
    100% {
      border-bottom: 2px solid #5A85A9;
    }
  }
  </style>
  