<template>
    <div class="text-center d-flex">
        <v-tooltip left>
            <template #activator="{ on, attrs }">
                <v-btn
                    depressed
                    class="mx-2 py-4 rounded custom-shadow custom-scale"
                    dark
                    x-small
                    elevation-12
                    color="textDark"
                    v-bind="attrs"
                    v-on="on"
                    @click="closeLayer()"
                >
                    <v-icon dark small>
                        mdi-layers-remove
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ $t("toggleWms.tooltip.title") }} {{ selectedLayer.layers }}</span>
        </v-tooltip>
    </div>
</template>


<script>
    export default {
        name: "ToggleWmsComponent",
        props: {
            selectedLayer: {
                type: Object,
                required: false,
                default: function() {
                   return {}
                }
            }
        },
        data() {
            return {}
        },
        methods: {
            closeLayer() {
                this.$emit("close-layer");
            }
        },
    }
</script>


<style scoped>
</style>