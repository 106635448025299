import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify/index";
import 'leaflet/dist/leaflet.css';
import i18n from './plugins/i18n';
import Vuelidate from 'vuelidate';

// Importing global styles
import './styles/global.css';


Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  name: "VueApp",
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
