<template>
  <div 
    v-if="Object.keys(selectedPolygon).length != 0" 
    class="d-flex"
  >
    <div class="hidden-md-and-down">
      <v-card
        class="d-flex flex-column custom-shadow card-wrapper"
        elevation="0"
        min-width="200"
        max-width="315"
        min-height="215"
        height="270"
        style="overflow-y: auto;"
      >
        <v-card-title class="card-inner-wrapper mb-3 py-0">
          <div class="d-flex align-center custom-gap justify-space-between">
            <div class="navbutton pa-1 rounded custom-shadow">
              <v-img
                contain
                width="30"
                height="30"
                :src="require(`../assets/polutants/${selectedPolygon.key}.svg`)"
                :alt="`${selectedPolygon.key} image`"
              >
              </v-img>
            </div>
            <div class="my-3 textDark--text font-weight-thin">
              |
            </div>
            <span class="text-h6 textDark--text font-weight-light custom-title-text">
              {{ obtainSuperScripts[selectedPolygon.key] }}
            </span>
          </div>
        </v-card-title>
        <v-card-text class="textDark--text font-weight-light custom-font">
          {{ obtainDesc[selectedPolygon.key] }}
        </v-card-text>
        <v-card-subtitle class="justify-self-end mt-auto py-1">
          <div class="d-flex align-center custom-gap">
            <v-icon x-small color="navbutton">
              mdi mdi-circle
            </v-icon>
            <span class="text-h6 mapbutton--text font-weight-bold custom-dsc-text">
              {{ formatNumbers(determinePolygonValue) }}
            </span>
            <span class="textDark--text font-weight-light custom-font"> (t) / year</span>
          </div>
          <div v-if="selectedPolygon.value.diff">
            <v-divider class="px-3 my-2"></v-divider>
            <span class="d-block pt-2 pb-1">Difference (%) based on Baseline.</span>
            <div class="d-flex align-center custom-gap">
              <v-icon x-small color="navbutton">
                mdi mdi-poll
              </v-icon>
              <span class="text-h6 mapbutton--text custom-dsc-text">
                <count-to-animation
                  :decimals="isDecimal(formatToNumbers(selectedPolygon.value.diff)) ? 2 : 0"
                  :start-val="0"
                  separator="0"
                  :end-val="formatToNumbers(selectedPolygon.value.diff)"
                  :duration="1500"
                >
                </count-to-animation>
              </span>
              <span class="textDark--text font-weight-light custom-font">%</span>
            </div>
          </div>
          <v-divider class="px-3 my-2"></v-divider>
          <span class="d-block pt-2 pb-1">Road emissions (t).</span>
        </v-card-subtitle>
        <div v-if="selectedPolygon.roads">
          <v-card-subtitle
            v-for="road in selectedPolygon.roads"
            :key="road.id"
            class="justify-self-end mt-auto py-1"
          >
            <div class="d-flex flex-column custom-gap">
              <div class="d-flex align-center custom-gap">
                <v-icon x-small color="navbutton">
                  mdi mdi-road-variant
                </v-icon>
                <span class="textDark--text font-weight-light custom-font">{{ road.name }}:</span>
                <span class="text-p mapbutton--text custom-dsc-text">
                  {{ formatNumbers(road.pollutant) }}
                </span>
                <span class="textDark--text font-weight-light custom-font">(t) / year</span>
              </div>
            </div>
          </v-card-subtitle>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
// Importing counter animation library
import countTo from 'vue-count-to';

export default {
    name: "BaseMapBanner",
    props: {
      selectedPolygon: {
        type: [Object, Array],
        required: true,
      },
    },
    components: {
      "count-to-animation": countTo
    },
    data() {
        return {
          obtainSuperScripts: {
            CO2EQ: "CO₂eq",
            CO2: "CO₂",
            N2O: "N₂O",
            CH4: "CH₄"
          },
          obtainDesc: {
            "CO2": "Carbon dioxide (CO₂) is the main by-product of fuel combustion in automotive engines.",
            "N2O": "Nitrous oxide gas (N₂O) is a potent GHG released in small quantities as a by-product of fuel combustion in automotive engines.",
            "CH4": "Methane gas (CH₄) is another by-product of fuel combustion in automotive engines and a potent GHG.",
            "CO2EQ": "A carbon dioxide equivalent or CO₂ equivalent, abbreviated as CO₂eq is a metric measure used to aggregate GHG emissions."
          }
        }
    },
    computed: {
      determinePolygonValue() {
        if (this.selectedPolygon.value.value) {
          return this.selectedPolygon.value.value;
        } else {
          return this.selectedPolygon.value
        }
      }
    },
    methods: {
      formatToNumbers(input) {
        return Number(input);
      },

      isDecimal(input) {
        return !Number.isInteger(input);
      },
      // addCommasToNumbers(input) {
      //   if (!input) return "";
      
      //   let parts = input.split(".");
      //   let integerPart = parts[0];
      //   let decimalPart = parts[1] || "";

      //   // Add commas to the integer part
      //   integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      //   // Reconstruct the number with commas
      //   const result = decimalPart ? integerPart + "." + decimalPart : integerPart;

      //   return result;
      // },
      formatNumbers (input) {
        if (!input) return "";

        let num = parseFloat(input.replace(",", "."));
        let formattedNum = num.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 2});

        // Remove decimal part if comma exists
        if (formattedNum.includes(",")) {
            formattedNum = formattedNum.split(".")[0];
        }

        return formattedNum;
      }
    }
}
</script>

<style scoped>
  .card-wrapper {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
    border: 2px solid white;
  }
  .card-inner-wrapper {
    background: rgba(255, 255, 255, 0.5);
  }
  .custom-title-text {
    text-shadow: 1px 0px 1px #2d3436, 0px 1px 0px rgba(0,0,0,0.30);
    font-weight: 900 !important;
  }
  .custom-dsc-text {
    font-style: italic;
    text-shadow: 1px 0px 0px #BD7C37cf, 0px 1px 0px rgba(0,0,0,0.15);
    font-weight: 900 !important;
  }
</style>