import { v4 as uuidv4 } from 'uuid';

export const SCENARIOS = [
  {
    id: uuidv4(),
    name: "buldings",
    dsc: "This application aims at facilitating the process of setting-up and elaborating effective strategic plans for the improvement of the buildings’ energy performance and greenhouse gas (GHG) abatement at local (municipality) and regional (prefecture) scale. Energy consumption and GHG emissions are calculated using a bottom-up building stock model (BSM) enhanced by Earth Observations (EO). The BSM is based on the TABULA typology concept and it is applied on residential buildings, offices and schools classified according to size and vintage. Statistical data from the Census 2011, in situ observations and socioeconomic data are used to feed the BSM. In the absence of specific data, electricity is assumed to be the main energy carrier for office heating/cooling, while oil and natural gas are considered as the most commonly used energy carriers for space heating in schools. The enhanced BSM is applied to map the studied building stock at its present state and assess the impact of a combination of measures on its energy performance.",
    // scenario: "Envelope refurbishment of all buildings up to the standard of KENAK. Use electricity as the only energy carrier for heating, cooling in all building types, replacing existing heating systems with heat pumps (COP2.5) and considering that 60% of the energy demand for domestic hot water is covered by solar thermal systems",
    // layer: "eiffel:buildings",
    subScenarios: [
      {
        id: uuidv4(),
        name: "Baseline",
        dsc: "The baseline scenario aims at mapping the current state of energy consumption and GHG emissions of the building stock at local and regional level. Results serve as a reference for assessing different GHG mitigation scenaria.",
        scenarios: [
          {
            id: uuidv4(),
            name: "Regional",
            dsc: "For the regional scale, the pilot area is the Attica prefecture. It includes 31 municipalities and covers the high density urban area of region. The BSM is fed with data from only open and freely available Earth Observation as well as other public access datasets. BSM results are aggregated at municipality level. The user may get an overview of the results for the Attica prefecture by selecting among the following maps from the menu on the left of the screen",
            subScenarios: [
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual GHG emissions per heated area (CO2eq, t/m²)",
                style: "building_regional_baseline_emissions",
                layer: "eiffel:building_regional_baseline_emissions",
                dsc: "Pending..."
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual Final Energy Use per Heated Area (MWh/m²)",
                style: "building_regional_baseline_energy_final",
                layer: "eiffel:building_regional_baseline_energy_final",
                dsc: "Pending..."
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Energy Performance",
                style: "building_regional_baseline_performance_energy",
                layer: "eiffel:building_regional_baseline_performance_energy",
                dsc: "Pending..."
              },
              // {
              //   id: uuidv4(),
              //   expanded: true,
              //   name: "Annual GHG Emissions Savings (%)",
              //   style: "building_regional_baseline_ghg_savings",
              //   layer: "eiffel:building_regional_baseline_ghg_savings",
              //   dsc: "Pending..."
              // }
            ],
          },
          {
            id: uuidv4(),
            name: "Local",
            dsc: "For the local scale, the pilot area is the Municipality of Penteli, a low density, mainly residential area in the north suburbs of metropolitan Athens. According to published data from the latest publically available Census 2011, the Municipality building stock includes a total of 7023 buildings, 97% of which are residential, office and educational use buildings. The majority of the studied building types have been built after 1980 that defines the period when the first building Thermal Insulation Regulation was implemented in Greece; most of them are low-rise, their size not exceeding two floors above ground floor. Oil is the most common energy carrier for space heating, while natural gas is used in only 11% of the buildings. Electricity and solar energy are used for domestic hot water preparation in 35% and 40% of the buildings respectively. BSM results are aggregated at building block level. The user may get an overview of the results for the municipality by selecting among the following maps from the menu on the left of the screen.",
            subScenarios: [
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual GHG Emissions per Heated Area (CO₂-eq kg/m²)",
                style: "buildings_local_baseline_emissions",
                layer: "eiffel:building_local_baseline_emissions",
                dsc: "Pending...",
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual Final Energy Use per Heated Area (kWh/m²)",
                style: "buildings_local_baseline_energy_final",
                layer: "eiffel:building_local_baseline_energy_final",
                dsc: "Pending...",
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Energy Performance",
                style: "buildings_local_baseline_performance_energy",
                layer: "eiffel:building_local_baseline_performance_energy",
                dsc: "Pending...",
              },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        name: "Electrification Scenario",
        dsc: "This scenario involves: - Envelope refurbishment of buildings (wall-roof-floor insulation, openings with double glazings, aluminium frame with thermal breaks) up to the standard of the national regulation on the energy performance of buildings (KENAK). - Use of electricity as the only energy carrier for heating/cooling, replacing existing heating systems with heat pumps (COP2.5) and considering that 60% of the energy demand for domestic hot water is covered by solar thermal systems. This scenario is assessed for three different implementation depths: 30%, 60% and 100% of the studied building stock. The scenario does not consider the inclusion of PV systems for in situ electricity production. This combined effect of the electrification scenario and the rooftop PVs is assessed in a separate scenario (see Hybrid scenario).",
        scenarios: [
          {
            id: uuidv4(),
            name: "Regional",
            dsc: "For the regional scale (the Attica prefecture) results are aggregated at municipality level. The user may get an overview of the results for the Attica prefecture by selecting among the following maps from the menu on the left of the screen.",
            subScenarios: [
              {
                id: uuidv4(),
                name: "30%",
                dsc: "The scenario is implemented in 30% of the buildings in the Municipality, while the rest of the building stock remains at its initial state.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_30_ghg_emissions",
                    style: "building_regional_scenario_elecrtif_30_ghg_emissions",
                    dsc: "Pending...",
                    name: "GHG Emissions per heated area (kgr/m²)"
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_30_ghg_final_energy",
                    style: "building_regional_scenario_elecrtif_30_ghg_final_energy",
                    dsc: "Pending...",
                    name: "Final Energy Consumption per heated area (kWh/m²)"
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_30_ghg_energy",
                    style: "building_regional_scenario_elecrtif_30_ghg_energy",
                    dsc: "Pending...",
                    name: "Energy Performance",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_30_ghg_savings",
                    style: "building_regional_scenario_elecrtif_30_ghg_savings",
                    dsc: "Pending...",
                    name: "Annual GHG Emissions Savings (%)",
                  },
                ],
              },
              {
                id: uuidv4(),
                name: "60%",
                dsc: "The scenario is implemented in 60% of the buildings in the Municipality, while the rest of the building stock remains at its initial state.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_60_ghg_emissions",
                    style: "building_regional_scenario_elecrtif_60_ghg_emissions",
                    dsc: "Pending...",
                    name: "GHG Emissions per heated area (kgr/m²)"
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_60_ghg_final_energy",
                    style: "building_regional_scenario_elecrtif_60_ghg_final_energy",
                    dsc: "Pending...",
                    name: "Final Energy Consumption per heated area (kWh/m²)"
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_60_ghg_energy",
                    style: "building_regional_scenario_elecrtif_60_ghg_energy",
                    dsc: "Pending...",
                    name: "Energy Performance"
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_60_ghg_savings",
                    style: "building_regional_scenario_elecrtif_60_ghg_savings",
                    dsc: "Pending...",
                    name: "Annual GHG Emissions Savings (%)",
                  },
                ],
              },
              {
                id: uuidv4(),
                name: "100%",
                dsc: "The scenario is implemented in the entire building stock of the Municipality.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_100_ghg_emissions",
                    style: "building_regional_scenario_elecrtif_100_ghg_emissions",
                    dsc: "Pending...",
                    name: "GHG Emissions per heated area (kgr/m²)"
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_100_ghg_final_energy",
                    style: "building_regional_scenario_elecrtif_100_ghg_final_energy",
                    dsc: "Pending...",
                    name: "Final Energy Consumption per heated area (kWh/m²)"
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_100_ghg_energy",
                    style: "building_regional_scenario_elecrtif_100_ghg_energy",
                    dsc: "Pending...",
                    name: "Energy Performance"
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_regional_scenario_elecrtif_100_ghg_savings",
                    style: "building_regional_scenario_elecrtif_100_ghg_savings",
                    dsc: "Pending...",
                    name: "Annual GHG Emissions Savings (%)",
                  },
                ],
              },
            ],
          },
          {
            id: uuidv4(),
            name: "Local",
            dsc: "For the local scale (Municipality of Penteli) results are aggregated at building block level. The user may get an overview of the results for the municipality by selecting among the following maps from the menu on the left of the screen.",
            subScenarios: [
              {
                id: uuidv4(),
                name: "30%",
                dsc: "The scenario is implemented in 30% of the buildings in the Municipality, while the rest of the building stock remains at its initial state.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_local_scenario_elecrtif_30_ghg_emissions",
                    style: "buildings_local_scenario_elecrtif_30_ghg_emissions",
                    dsc: "Pending...",
                    name: "GHG Emissions per heated area (kgr/m²)",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_local_scenario_elecrtif_30_final_energy",
                    style: "buildings_local_scenario_elecrtif_30_final_energy",
                    dsc: "Pending...",
                    name: "Final Energy Consumption per heated area (kWh/m²)",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_local_scenario_elecrtif_30_energy",
                    style: "buildings_local_scenario_elecrtif_30_energy",
                    dsc: "Pending...",
                    name: "Energy Performance",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_local_scenario_elecrtif_30_ghg_savings",
                    style: "buildings_local_scenario_elecrtif_30_ghg_savings",
                    dsc: "Pending...",
                    name: "Annual GHG Emissions Savings (%)",
                  },
                ],
              },
              {
                id: uuidv4(),
                name: "60%",
                dsc: "The scenario is implemented in 60% of the buildings in the Municipality, while the rest of the building stock remains at its initial state.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_local_scenario_elecrtif_60_ghg_emissions",
                    style: "buildings_local_scenario_elecrtif_60_ghg_emissions",
                    dsc: "Pending...",
                    name: "GHG Emissons per heated area (kgr/m²)",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_local_scenario_elecrtif_60_final_energy",
                    style: "buildings_local_scenario_elecrtif_60_final_energy",
                    dsc: "Pending...",
                    name: "Final Energy Consumption per heated area (kWh/m²)",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_local_scenario_elecrtif_60_energy",
                    style: "buildings_local_scenario_elecrtif_60_energy",
                    dsc: "Pending...",
                    name: "Energy Performance",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_local_scenario_elecrtif_60_ghg_savings",
                    style: "buildings_local_scenario_elecrtif_60_ghg_savings",
                    dsc: "Pending...",
                    name: "Annual GHG Emissions Savings (%)",
                  },
                ],
              },
              {
                id: uuidv4(),
                name: "100%",
                dsc: "The scenario is implemented in the entire building stock of the Municipality",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer:
                      "eiffel:building_local_scenario_elecrtif_100_ghg_emissions",
                    style:
                      "buildings_local_scenario_elecrtif_100_ghg_emissions",
                    dsc: "Pending...",
                    name: "GHG Emissons per heated area (kgr/m²)",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer:
                      "eiffel:building_local_scenario_elecrtif_100_final_energy",
                    style: "buildings_local_scenario_elecrtif_100_final_energy",
                    dsc: "Pending...",
                    name: "Final Energy Consumption per heated area (kWh/m²)",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:building_local_scenario_elecrtif_100_energy",
                    style: "buildings_local_scenario_elecrtif_100_energy",
                    dsc: "Pending...",
                    name: "Energy Performance",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer:
                      "eiffel:building_local_scenario_elecrtif_100_ghg_savings",
                    style: "buildings_local_scenario_elecrtif_100_ghg_savings",
                    dsc: "Pending...",
                    name: "Annual GHG Emissions Savings (%)",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        name: "Hybrid Scenario",
        dsc: "This Sscenario involves: - Envelope refurbishment of buildings (wall-roof-floor insulation, openings with double glazings, aluminium frame with thermal breaks) up to the standard of the national regulation on the energy performance of buildings (KENAK) - Use of electricity as the only energy carrier for heating/cooling, replacing existing heating systems with heat pumps (COP=3.2, EER=2.8) and considering that 60% of the energy demand for domestic hot water is covered by solar thermal systems. Electricity production from PV panels mounted on the exploitable rooftop spaces of the residential, school and office buildings in the studied building stock. This scenario is assessed for the entire building stock of the Municipality.",
        scenarios: [
          {
            id: uuidv4(),
            name: "Regional",
            dsc: "",
            subScenarios: [
              {
                id: uuidv4(),
                expanded: true,
                name: "GHG Emissons per heated area (kgr/m²)",
                style: "building_regional_hybrid_ghg_emissions",
                layer: "eiffel:building_regional_hybrid_ghg_emissions",
                dsc: "Pending..."
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Final Energy Consumption per heated area (kWh/m²)",
                style: "building_regional_hybrid_ghg_final_energy",
                layer: "eiffel:building_regional_hybrid_ghg_final_energy",
                dsc: "Pending..."
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Energy Performance",
                style: "building_regional_hybrid_ghg_energy",
                layer: "eiffel:building_regional_hybrid_ghg_energy",
                dsc: "Pending..."
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual GHG Emissions Savings (%)",
                style: "building_regional_hybrid_ghg_savings",
                layer: "eiffel:building_regional_hybrid_ghg_savings",
                dsc: "Pending..."
              }
            ]
          },
          {
            id: uuidv4(),
            name: "Local",
            dsc: "",
            subScenarios: [
              {
                id: uuidv4(),
                expanded: true,
                name: "GHG Emissons per heated area (kgr/m²)",
                style: "building_local_hybrid_ghg_emissions",
                layer: "eiffel:building_local_hybrid_ghg_emissions",
                dsc: "Pending..."
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Final Energy Consumption per heated area (kWh/m²)",
                style: "building_local_hybrid_ghg_final_energy",
                layer: "eiffel:building_local_hybrid_ghg_final_energy",
                dsc: "Pending..."
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Energy Performance",
                style: "building_local_hybrid_ghg_energy",
                layer: "eiffel:building_local_hybrid_ghg_energy",
                dsc: "Pending..."
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual GHG Emissions Savings (%)",
                style: "building_local_hybrid_ghg_savings",
                layer: "eiffel:building_local_hybrid_ghg_savings",
                dsc: "Pending..."
              }
            ]
          }
        ],
      }
    ],
  },
  {
    id: uuidv4(),
    name: "solar",
    dsc: "",
    // scenario: "The realistic maximum possible (i.e. exploitable and compatible rooftops area) PV penetration for self-production of energy and reduction of the average carbon footprint.",
    subScenarios: [
      {
        id: uuidv4(),
        name: "Baseline",
        dsc: "The realistic maximum possible (i.e. exploitable and compatible rooftops area) PV penetration for self-production of energy and reduction of the average carbon footprint.",
        scenarios: [
          {
            id: uuidv4(),
            name: "Regional",
            dsc: "Solar photovoltaic (PV) energy potential estimation at rooftop level for the metropolitan region of Athens in Greece. For each Municipality polygon, the total annual PV energy production sum in GWh was calculated for the available rooftop areas, as well as the corresponding greenhouse gases (GHG) annual emissions saving in kilotons (kt).",
            subScenarios: [
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual GHG Emissions Savings (kt)",
                layer: "eiffel:photovoltaic_stepwise_approach_regional_baseline_savings",
                style: "photovoltaic_stepwise_approach_regional_baseline_savings",
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual Energy Production (GWh)",
                layer: "eiffel:photovoltaic_stepwise_approach_regional_baseline_energy_production",
                style: "photovoltaic_stepwise_approach_regional_baseline_energy_production",
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Percentage Energy Adequacy (%)",
                layer: "eiffel:photovoltaic_stepwise_approach_regional_baseline_adequacy",
                style: "photovoltaic_stepwise_approach_regional_baseline_adequacy"
              }
            ],
          },
          {
            id: uuidv4(),
            name: "Local",
            dsc: "Solar photovoltaic (PV) energy potential estimation at rooftop level for the Municipality of Penteli in Attica, Greece. For each building block, the total annual PV energy production sum in MWh was calculated for the available rooftop areas, as well as the corresponding greenhouse gases (GHG) annual emissions saving in tons (t).",
            subScenarios: [
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual GHG Emissions Savings (t)",
                layer: "eiffel:photovoltaic_stepwise_approach_local_baseline_savings",
                style: "photovoltaic_stepwise_approach_local_baseline_savings",
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Annual Energy Production (MWh)",
                layer: "eiffel:photovoltaic_stepwise_approach_local_baseline_energy_production",
                style: "photovoltaic_stepwise_approach_local_baseline_energy_production",
              },
              {
                id: uuidv4(),
                expanded: true,
                name: "Percentage Energy Adequacy (%)",
                layer: "eiffel:photovoltaic_stepwise_approach_local_baseline_adequacy",
                style: "photovoltaic_stepwise_approach_local_baseline_adequacy"
              }
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        name: "Stepwise Approach To The Realistic Maximum Possible",
        scenarios: [
          {
            id: uuidv4(),
            name: "Regional",
            subScenarios: [
              {
                id: uuidv4(),
                name: "10%",
                dsc: "The realistic PV penetration level of 10% for self-production of energy and reduction of the average carbon footprint.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_regional_10_savings",
                    style: "photovoltaic_stepwise_approach_regional_10_savings",
                    name: "Annual GHG Emissions Savings (kt)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_regional_10_energy_production",
                    style: "photovoltaic_stepwise_approach_regional_10_energy_production",
                    name: "Annual Energy Production (GWh)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_regional_10_adequacy",
                    style: "photovoltaic_stepwise_approach_regional_10_adequacy",
                    name: "Percentage Energy Adequacy (%)",
                    dsc: "Pending..."
                  }
                ],
              },
              {
                id: uuidv4(),
                name: "40%",
                dsc: "The realistic PV penetration level of 40% for self-production of energy and reduction of the average carbon footprint.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_regional_40_savings",
                    style: "photovoltaic_stepwise_approach_regional_40_savings",
                    name: "Annual GHG Emissions Savings (kt)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_regional_40_energy_production",
                    style: "photovoltaic_stepwise_approach_regional_40_energy_production",
                    name: "Annual Energy Production (GWh)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_regional_40_adequacy",
                    style: "photovoltaic_stepwise_approach_regional_40_adequacy",
                    name: "Percentage Energy Adequacy (%)",
                    dsc: "Pending..."
                  }
                ],
              },
              {
                id: uuidv4(),
                name: "70%",
                dsc: "The realistic PV penetration level of 70% for self-production of energy and reduction of the average carbon footprint.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_regional_70_savings",
                    style: "photovoltaic_stepwise_approach_regional_70_savings",
                    name: "Annual GHG Emissions Savings (kt)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_regional_70_energy_production",
                    style: "photovoltaic_stepwise_approach_regional_70_energy_production",
                    name: "Annual Energy Production (GWh)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_regional_70_adequacy",
                    style: "photovoltaic_stepwise_approach_regional_70_adequacy",
                    name: "Percentage Energy Adequacy (%)",
                    dsc: "Pending..."
                  }
                ],
              },
            ],
          },
          {
            id: uuidv4(),
            name: "Local",
            subScenarios: [
              {
                id: uuidv4(),
                name: "10%",
                dsc: "The realistic PV penetration level of 10% for self-production of energy and reduction of the average carbon footprint.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_local_10_savings",
                    style: "photovoltaic_stepwise_approach_local_10_savings",
                    name: "Annual GHG Emissions Savings (t)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_local_10_energy_production",
                    style: "photovoltaic_stepwise_approach_local_10_energy_production",
                    name: "Annual Energy Production (MWh)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_local_10_adequacy",
                    style: "photovoltaic_stepwise_approach_local_10_adequacy",
                    name: "Percentage Energy Adequacy (%)",
                    dsc: "Pending..."
                  }
                ],
              },
              {
                id: uuidv4(),
                name: "40%",
                dsc: "The realistic PV penetration level of 40% for self-production of energy and reduction of the average carbon footprint.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_local_40_savings",
                    style: "photovoltaic_stepwise_approach_local_40_savings",
                    name: "Annual GHG Emissions Savings (t)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_local_40_energy_production",
                    style: "photovoltaic_stepwise_approach_local_40_energy_production",
                    name: "Annual Energy Production (MWh)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_local_40_adequacy",
                    style: "photovoltaic_stepwise_approach_local_40_adequacy",
                    name: "Percentage Energy Adequacy (%)",
                    dsc: "Pending..."
                  }
                ],
              },
              {
                id: uuidv4(),
                name: "70%",
                dsc: "The realistic PV penetration level of 70% for self-production of energy and reduction of the average carbon footprint.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_local_70_savings",
                    style: "photovoltaic_stepwise_approach_local_70_savings",
                    name: "Annual GHG Emissions Savings (t)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_local_70_energy_production",
                    style: "photovoltaic_stepwise_approach_local_70_energy_production",
                    name: "Annual Energy Production (MWh)",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:photovoltaic_stepwise_approach_local_70_adequacy",
                    style: "photovoltaic_stepwise_approach_local_70_adequacy",
                    name: "Percentage Energy Adequacy (%)",
                    dsc: "Pending..."
                  }
                ],
              },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        name: "Percentage Energy Adequacy of Schools",
        dsc: "Percentage energy coverage of the electricity demand from self production per school building for the Municipality of Penteli.",
        scenarios: [
          {
            id: uuidv4(),
            expanded: true,
            name: "Percentage Energy Adequacy of Schools (%)",
            layer: "eiffel:photovoltaic_schools",
            style: "photovoltaic_schools",
            dsc: "Pending..."
          }
        ]
      }
    ],
  },
  {
    id: uuidv4(),
    name: "copert",
    dsc: "Road transport GHG emissions are calculated for the Region of Attica (Metropolitan Athens and surrounding areas) and the Municipality of Penteli, using the COPERT software. For the baseline scenario, 2019 is chosen as the reference year, because of the availability of a complete series of input data, for both study areas. Fleet composition data for the year 2019 are extracted from the COPERT database for the whole of Greece, then adjusted for the Region of Attica using information from ELSTAT (Motor vehicles in circulation by category and use 2019 data), the TREMOVE database (Vehicle Stock in Metropolitan Areas 2019 data) and ACEA (Vehicles in Use Europe 2019 data). For the Municipality of Penteli, information from ELSTAT (Number of passenger cars per residence for the Municipality of Penteli 2019 data), and the Municipality of Penteli Master Plan for Sustainable Mobility (SVAK) is used for adjusting input data accordingly. Data on annual distance travelled per vehicle category (vkm) were derived from the COPERT model, adjusted for the Municipality of Penteli using information from SVAK regarding local traffic, including passenger cars and public transportation mileage. Future emission scenarios refer to the year 2030 with fleet compositions being adjusted for each scenario according to current policies and economic-environmental-activity projections for Greece and Athens Metropolitan Area. GHG emissions at road level are calculated for eight major roads in the Athens Metropolitan Area and three major roads in the Municipality of Penteli using the COPERT software. Input data for vehicle activity are taken from the Regional Authority of Attica (road traffic data) and from the Municipality of Penteli Master Plan for Sustainable Mobility (SVAK). The same fleet composition data are used as in the case of total GHG emissions calculation at regional and municipal level",
    // dsc: "Road transport GHG emissions are calculated for the Region of Attica (Metropolitan Athens and surrounding areas) and the Municipality of Penteli, using the COPERT software. For the baseline scenario, 2019 is chosen as the reference year, because of the availability of a complete series of input data, for both study areas. Fleet composition data for the year 2019 are extracted from the COPERT database for the whole of Greece, then adjusted for the Region of Attica using information from ELSTAT (Motor vehicles in circulation by category and use 2019 data), the TREMOVE database (Vehicle Stock in Metropolitan Areas 2019 data) and ACEA (Vehicles in Use Europe 2019 data). For the Municipality of Penteli, information from ELSTAT (Number of passenger cars per residence for the Municipality of Penteli 2019 data), and the Municipality of Penteli Master Plan for Sustainable Mobility (SVAK) is used for adjusting input data accordingly. Data on annual distance travelled per vehicle category (vkm) were derived from the COPERT model, adjusted for the Municipality of Penteli using information from SVAK regarding local traffic, including passenger cars and public transportation mileage. Future emission scenarios refer to the year 2030 with fleet compositions being adjusted for each scenario according to current policies and economic-environmental-activity projections for Greece and Athens Metropolitan Area.",
    scenario: "Pending...",
    subScenarios: [
      {
        id: uuidv4(),
        name: "Baseline",
        scenarios: [
          {
            id: uuidv4(),
            expanded: true,
            name: "Region of Attica",
            type: "polygon",
            layer: "eiffel:copert_roads_regional_baseline",
            style: "copert_roads_regional_baseline",
            polygonName: "atticaGeoJson",
            content: {
              Area: "Attica",
              CO2: "16403755.9",
              CH4: "1487.291",
              N2O: "351.039",
              CO2EQ: "16545547.8",
            },
          },
          {
            id: uuidv4(),
            expanded: true,
            name: "Municipality of Penteli",
            type: "polygon",
            layer: "eiffel:copert_roads_local_baseline",
            style: "copert_roads_local_baseline",
            polygonName: "penteliGeoJson",
            content: {
              Area: "Penteli",
              CO2: "22293.75",
              CH4: "3.3551",
              N2O: "0.432164",
              CO2EQ: "22506.4124",
            },
          },
        ],
      },
      // {
      //   id: 2,
      //   name: "GHG Emission Reductions for Different Scenarios",
      //   scenarios: [
      //     {
      //       id: 34,
      //       expanded: true,
      //       name: "Region of Attica",
      //       layer: "polygon",
      //       polygonName: "atticaGeoJson",
      //       content: {
      //         Area: "Attica",
      //         CO2: {
      //           value: "16365925.9",
      //           diff: "-0.230617916",
      //         },
      //         CH4: {
      //           value: "1484.279",
      //           diff: "-0.202515849",
      //         },
      //         N2O: {
      //           value: "350.873",
      //           diff: "-0.047288193",
      //         },
      //         CO2EQ: {
      //           value: "16507593",
      //           diff: "-0.229395657",
      //         },
      //       },
      //     },
      //     {
      //       id: 35,
      //       expanded: true,
      //       name: "Municipality of Penteli",
      //       layer: "polygon",
      //       polygonName: "penteliGeoJson",
      //       content: {
      //         Area: "Penteli",
      //         CO2: {
      //           value: "22234.44",
      //           diff: "-0.266038688",
      //         },
      //         CH4: {
      //           value: "3.350445",
      //           diff: "-0.138744002",
      //         },
      //         N2O: {
      //           value: "0.431903",
      //           diff: "-0.060393739",
      //         },
      //         CO2EQ: {
      //           value: "22446.9082",
      //           diff: "-0.264387553",
      //         },
      //       },
      //     },
      //   ],
      // },
      {
        id: uuidv4(),
        name: "GR Optimistic Scenario",
        dsc: `40% of diesel passenger car fleet = zero emissions by 2030 (20% electric/25% hybrid). 
          20% of unleaded passenger car fleet = zero emissions by 2030 (10% electric/15% hybrid).
          20% of vans = zero emissions by 2030 (10% electric/15% hybrid). 
          20% of motorcycles = zero emissions by 2030 (20% electric). 
          50% buses = zero emissions (50% electric). `,
        scenarios: [
          {
            id: uuidv4(),
            expanded: true,
            name: "Region of Attica",
            type: "polygon",
            layer: "eiffel:copert_roads_regional_optimistic_scenario",
            style: "copert_roads_regional_optimistic_scenario",
            polygonName: "atticaGeoJson",
            content: {
              Area: "Attica",
              CO2: {
                value: "12264825.64",
                diff: "-25.2316011559413",
              },
              CH4: {
                value: "1060.1",
                diff: "-28.7226598736735",
              },
              N2O: {
                value: "289.605",
                diff: "-17.5007553440978",
              },
              CO2EQ: {
                value: "12377630",
                diff: "-25.1905680417064",
              },
            },
          },
          {
            id: uuidv4(),
            expanded: true,
            name: "Municipality of Penteli",
            type: "polygon",
            layer: "eiffel:copert_roads_local_optimistic_scenario",
            style: "copert_roads_local_optimistic_scenario",
            polygonName: "penteliGeoJson",
            content: {
              Area: "Penteli",
              CO2: {
                value: "15880.133",
                diff: "-28.7686781642655",
              },
              CH4: {
                value: "2.10001",
                diff: "-37.4083659112381",
              },
              N2O: {
                value: "0.35504",
                diff: "-17.8450328051615",
              },
              CO2EQ: {
                value: "16038.4363",
                diff: "-28.7383701414196",
              },
            },
          },
        ],
      },
      {
        id: uuidv4(),
        name: "GR Base Scenario",
        dsc: `30% of diesel passenger car fleet = zero emissions by 2030 (15% electric/20% hybrid). 
          15% of unleaded passenger car fleet = zero emissions by 2030 (10% electric/10% hybrid). 
          15% of vans = zero emissions by 2030 (10% electric/10% hybrid). 
          15% of motorcycles = zero emissions by 2030 (15% electric). 
          35% buses = zero emissions (35% electric). `,
        scenarios: [
          {
            id: uuidv4(),
            expanded: true,
            name: "Region of Attica",
            type: "polygon",
            layer: "eiffel:copert_roads_regional_base_scenario",
            style: "copert_roads_regional_base_scenario",
            polygonName: "atticaGeoJson",
            content: {
              Area: "Attica",
              CO2: {
                value: "13737076.01",
                diff: "-16.2565201961706",
              },
              CH4: {
                value: "1198.22",
                diff: "-19.436341117062",
              },
              N2O: {
                value: "326.361",
                diff: "-7.03001058514524",
              },
              CO2EQ: {
                value: "13864287",
                diff: "-16.2053312771182",
              },
            },
          },
          {
            id: uuidv4(),
            expanded: true,
            name: "Municipality of Penteli",
            type: "polygon",
            layer: "eiffel:copert_roads_local_base_scenario",
            style: "copert_roads_local_base_scenario",
            polygonName: "penteliGeoJson",
            content: {
              Area: "Penteli",
              CO2: {
                value: "18081.7397",
                diff: "-18.8932337737969",
              },
              CH4: {
                value: "2.4845",
                diff: "-25.9485260293263",
              },
              N2O: {
                value: "0.39338",
                diff: "-8.97502281053836",
              },
              CO2EQ: {
                value: "18261.0786",
                diff: "-18.8627742211202",
              },
            },
          },
        ],
      },
      {
        id: uuidv4(),
        name: "GR BAU Scenario",
        dsc: `20% of diesel passenger car fleet = zero emissions by 2030 (10% electric/15% hybrid). 
            10% of unleaded passenger car fleet = zero emissions by 2030 (5% electric/10% hybrid).
            10% of vans = zero emissions by 2030 (5% electric/10% hybrid).
            10% of motorcycles = zero emissions by 2030 (10% electric).
            25% buses = zero emissions by 2030 (25% electric). `,
        scenarios: [
          {
            id: uuidv4(),
            expanded: true,
            name: "Region of Attica",
            type: "polygon",
            layer: "eiffel:copert_roads_regional_bau_scenario",
            style: "copert_roads_regional_bau_scenario",
            polygonName: "atticaGeoJson",
            content: {
              Area: "Attica",
              CO2: {
                value: "14599057.18",
                diff: "-11.0017408980205",
              },
              CH4: {
                value: "1286.12",
                diff: "-13.5261916767432",
              },
              N2O: {
                value: "341.758",
                diff: "-2.64381254874738",
              },
              CO2EQ: {
                value: "14733054",
                diff: "-10.9545708126406",
              },
            },
          },
          {
            id: uuidv4(),
            expanded: true,
            name: "Municipality of Penteli",
            type: "polygon",
            layer: "eiffel:copert_roads_local_bau_scenario",
            style: "copert_roads_local_bau_scenario",
            polygonName: "penteliGeoJson",
            content: {
              Area: "Penteli",
              CO2: {
                value: "19433.1896",
                diff: "-12.8312214457899",
              },
              CH4: {
                value: "2.74003",
                diff: "-18.3324920362715",
              },
              N2O: {
                value: "0.41741",
                diff: "-3.41324377454338",
              },
              CO2EQ: {
                value: "19626.0794",
                diff: "-12.7978327291647",
              },
            },
          },
        ],
      },
      {
        id: uuidv4(),
        name: "GR Public Transportation/Motorcycle/Vans Focused Scenario",
        dsc: `40% of diesel passenger car fleet = zero emissions by 2030 (20% electric/25% hybrid).
            10% of unleaded passenger car fleet = zero emissions by 2030 (5% electric/10% hybrid). 
            30% of vans = zero emissions by 2030 (15% electric/20% hybrid).
            30% of motorcycles = zero emissions by 2030 (30% electric).
            75% buses = zero emissions by 2030 (75% electric). `,
        scenarios: [
          {
            id: uuidv4(),
            expanded: true,
            name: "Region of Attica",
            type: "polygon",
            layer: "eiffel:copert_roads_regional_public_transportation",
            style: "copert_roads_regional_public_transportation",
            polygonName: "atticaGeoJson",
            content: {
              Area: "Attica",
              CO2: {
                value: "11782779.704",
                diff: "-28.1702326253985",
              },
              CH4: {
                value: "963.961",
                diff: "-35.1867751978979",
              },
              N2O: {
                value: "296.853",
                diff: "-15.4359918570503",
              },
              CO2EQ: {
                value: "11895341",
                diff: "-28.1054881327472",
              },
            },
          },
          {
            id: uuidv4(),
            expanded: true,
            name: "Municipality of Penteli",
            type: "polygon",
            layer: "eiffel:copert_roads_local_public_transportation",
            style: "copert_roads_local_public_transportation",
            polygonName: "penteliGeoJson",
            content: {
              Area: "Penteli",
              CO2: {
                value: "15010.1645",
                diff: "-32.6709748520291",
              },
              CH4: {
                value: "1.64235",
                diff: "-51.0490688682741",
              },
              N2O: {
                value: "0.34741",
                diff: "-19.6120800467836",
              },
              CO2EQ: {
                value: "15154.7508",
                diff: "-32.6647419832454",
              },
            },
          },
        ],
      },
    ],
  },
  {
    id: uuidv4(),
    name: "airQuality",
    dsc: "Urban air pollution is an important environmental stressor for the Region of Attica (Gr). Greenhouse Gas (GHG) mitigation scenarios have potential co-benefits for the air quality (AQ) of the city, at the regional (municipality) and local (intra-urban) level. To quantify these co-benefits, a city-scale atmospheric numerical model system is applied. Mean monthly model outputs of 3 basic pollutants (nitrogen dioxide NO2, ozone O3 and fine particulate matter PM2.5) represent the air pollution during summer- and wintertime of a conventional, present time year (baseline model outputs). Selected GHG mitigation scenarios applied in buildings and/or vehicles are translated to the respective anthropogenic emission datasets, and feed the model system towards projected AQ fields (scenario model outputs). The comparison (and differences) between the baseline and each scenario identifies the co-benefits of the selected mitigation options on urban air pollution. More information on the model configuration and setup can be found in D5.4 of Eiffel project, publicly available through the official website of Eiffel project.",
    // scenario: "Use electricity as the only energy carrier for heating in all types of buildings (100% electrification, according to EC guidelines for 2050)/ saving of CO2-eq equals 98.55%",
    subScenarios: [
      {
        id: uuidv4(),
        // layer: "eiffel:air_quality_baseline_pm25_july",
        name: "Baseline",
        scenarios: [
          {
            id: uuidv4(),
            name: "Regional",
            dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are aggregated per municipality of the Region of Attica and are weighted according to residential population density (source: GHSL POP 2015). Clicking on each municipality, you can see the number of its residents and the mean monthly concentration value (population weighted), spatially averaged for the municipal area. Pins represent the sites of local in situ measurements (sources: National Regulatory Network and Panace-RI). Select one or more to inspect the mean daily concentrations of observations and predictions locally, along with the latest AQ limits set by WHO (2021).",
            subScenarios: [
              {
                id: uuidv4(),
                name: "Summer",
                dsc: "The mean monthly model outputs for June 2019 are considered representative of the warm period, according to the mean monthly values of the pollutants from observations, and their correlation to the predictions.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_regional_summer_no2",
                    style: "baseline_january_no2",
                    name: "NO₂",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_regional_summer_o3",
                    style: "baseline_january_o3",
                    name: "O₃",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_regional_summer_pm25",
                    style: "baseline_january_pm25",
                    name: "PM₂.₅",
                    dsc: "Pending...",
                  },
                ],
              },
              {
                id: uuidv4(),
                name: "Winter",
                dsc: "The mean monthly model outputs for January 2019 are considered representative of the cold period, according to the mean monthly values of the pollutants from observations, and their correlation to the predictions.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_regional_winter_no2",
                    style: "baseline_january_no2",
                    name: "NO₂",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_regional_winter_o3",
                    style: "baseline_january_o3",
                    name: "O₃",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_regional_winter_pm25",
                    style: "baseline_january_pm25",
                    name: "PM₂.₅",
                    dsc: "Pending...",
                  },
                ],
              },
            ],
          },
          {
            id: uuidv4(),
            name: "Local",
            dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are presented in a horizontal spatial analysis of 100 m for the pilot municipality of Penteli.",
            subScenarios: [
              {
                id: uuidv4(),
                name: "Summer",
                dsc: "Pending...",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_local_summer_no2",
                    name: "NO₂",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_local_summer_o3",
                    name: "O₃",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_local_summer_pm25",
                    name: "PM₂.₅",
                  },
                ],
              },
              {
                id: uuidv4(),
                name: "Winter",
                dsc: "Pending...",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_local_winter_no2",
                    name: "NO₂",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_local_winter_o3",
                    name: "O₃",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:baseline_local_winter_pm25",
                    name: "PM₂.₅",
                  },
                ],
              },
            ],
          },
        ],
        dsc: "Urban air pollution is here studied using the city-scale atmospheric numerical model system EPISODE-CityChem. Critical inputs to the physical and chemical atmospheric processes affecting air pollutants, are meteorological/ climatic spatio-temporal data, the land cover/ land use data, population density, traffic and other anthropogenic sources of air pollution, all based on retrievals from Copernicus (e.g. CAMS European anthropogenic emissions, CAMS reanalysis ensemble, Urban Atlas). The model is applied for a recent year (2019), free of Covid-related activity restrictions, and with a wind field representative of 2016-2020.",
      },
      {
        id: uuidv4(),
        name: "Electrification of buildings",
        dsc: "This group of model scenarios differentiate from the baseline / winter case ONLY in emissions of air pollutants from residential (small) combustion activity. In particular, it uses electricity as the only energy carrier for heating in all types of buildings).",
        scenarios: [
          {
            id: uuidv4(),
            name: "Regional",
            dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are aggregated per municipality of the Region of Attica and are weighted according to residential population density (source: GHSL POP 2015). Clicking on each municipality, you can see the number of its residents, the mean monthly concentration value (population weighted) and the co-benefit of this scenario for air quality ((scenario-baseline)/baseline, in %), spatially averaged for the municipal area. Pins represent the sites of local in situ measurements (sources: National Regulatory Network and Panace-RI). Select one or more to inspect the mean daily concentrations of observations and predictions (baseline and scenario) locally, along with the latest AQ limits set by WHO (2021).",
            subScenarios: [
              {
                id: uuidv4(),
                name: "30%",
                dsc: "The scenario is implemented in 30% of the buildings in the Municipality.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "air_quality_electification_of_buildings_regional_winter_30_no2",
                    name: "NO₂",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "air_quality_electification_of_buildings_regional_winter_30_o3",
                    name: "O₃",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "air_quality_electification_of_buildings_regional_winter_30_pm25",
                    name: "PM₂.₅",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "100%",
                dsc: "The scenario is implemented in the entire building stock of the Municipality.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:scenario_1_regional_winter_no2",
                    name: "NO₂",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:scenario_1_regional_winter_o3",
                    name: "O₃",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:scenario_1_regional_winter_pm25",
                    name: "PM₂.₅",
                    dsc: "Pending...",
                  },
                ]
              }
            ]
          },
          {
            id: uuidv4(),
            name: "Local",
            dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are presented in a horizontal spatial analysis of 100 m for the pilot municipality of Penteli. Iso-lines (not shown, if values are negligible) represent the co-benefit of this scenario for air quality, expressed as its percentage difference from the baseline ((scenario-baseline)/baseline, in %).",
            subScenarios: [
              {
                id: uuidv4(),
                name: "30%",
                dsc: "The scenario is implemented in 30% of the buildings in the Municipality.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "air_quality_electification_of_buildings_local_winter_30_no2",
                    contour: "eiffel:air_quality_electification_of_buildings_local_winter_30_no2_contour",
                    name: "NO₂",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "air_quality_electification_of_buildings_local_winter_30_o3",
                    contour: "eiffel:air_quality_electification_of_buildings_local_winter_30_o3_contour",
                    name: "O₃",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "air_quality_electification_of_buildings_local_winter_30_pm25",
                    contour: "eiffel:air_quality_electification_of_buildings_local_winter_30_pm25_contour",
                    name: "PM₂.₅",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "100%",
                dsc: "The scenario is implemented in the entire building stock of the Municipality.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:scenario_1_local_winter_no2",
                    contour: "eiffel:scenario_1_100_local_winter_no2_contour",
                    name: "NO₂",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:scenario_1_local_winter_o3",
                    contour: "eiffel:scenario_1_100_local_winter_o3_contour",
                    name: "O₃",
                    dsc: "Pending...",
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    layer: "eiffel:scenario_1_local_winter_pm25",
                    contour: "eiffel:scenario_1_100_local_winter_pm25_contour",
                    name: "PM₂.₅",
                    dsc: "Pending...",
                  },
                ]
              }
            ]
          }
        ]
      },
      // {
      //   id: uuidv4(),
      //   name: "Electrification of buildings (30%)",
      //   dsc: "This model scenario differentiates from the baseline ONLY in emissions of air pollutants from residential (small) combustion activity. In particular, it uses electricity as the only energy carrier for heating in all types of the 30% of buildings.",
      //   scenarios: [
      //     {
      //       id: uuidv4(),
      //       name: "Regional",
      //       dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are aggregated per municipality of the Region of Attica and are weighted according to residential population density (source: GHSL POP 2015). Clicking on each municipality, you can see the number of its residents, the mean monthly concentration value (population weighted) and the co-benefit of this scenario for air quality ((scenario-baseline)/baseline, in %), spatially averaged for the municipal area. Pins represent the sites of local in situ measurements (sources: National Regulatory Network and Panace-RI). Select one or more to inspect the mean daily concentrations of observations and predictions (baseline and scenario) locally, along with the latest AQ limits set by WHO (2021).",
      //       subScenarios: [
      //         {
      //           id: uuidv4(),
      //           name: "Winter",
      //           dsc: "The mean monthly model outputs for January 2019 are considered representative of the cold period, according to the mean monthly values of the pollutants from observations, and their correlation to the predictions.",
      //           subScenarios: [
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "air_quality_electification_of_buildings_regional_winter_30_no2",
      //               name: "NO₂",
      //               dsc: "Pending..."
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "air_quality_electification_of_buildings_regional_winter_30_o3",
      //               name: "O₃",
      //               dsc: "Pending..."
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "air_quality_electification_of_buildings_regional_winter_30_pm25",
      //               name: "PM₂.₅",
      //               dsc: "Pending..."
      //             }
      //           ]
      //         }
      //       ]
      //     },
      //     {
      //       id: uuidv4(),
      //       name: "Local",
      //       dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are presented in a horizontal spatial analysis of 100 m for the pilot municipality of Penteli. Iso-lines (not shown, if values are negligible) represent the co-benefit of this scenario for air quality, expressed as its percentage difference from the baseline ((scenario-baseline)/baseline, in %).",
      //       subScenarios: [
      //         {
      //           id: uuidv4(),
      //           name: "Winter",
      //           dsc: "Pending...",
      //           subScenarios: [
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "air_quality_electification_of_buildings_local_winter_30_no2",
      //               name: "NO₂",
      //               dsc: "Pending..."
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "air_quality_electification_of_buildings_local_winter_30_o3",
      //               name: "O₃",
      //               dsc: "Pending..."
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "air_quality_electification_of_buildings_local_winter_30_pm25",
      //               name: "PM₂.₅",
      //               dsc: "Pending..."
      //             }
      //           ]
      //         }
      //       ]
      //     }
      //   ],
      // },
      // {
      //   id: uuidv4(),
      //   // layer: "eiffel:air_quality_baseline_no2_january",
      //   name: "Electrification of buildings (100%)",
      //   scenarios: [
      //     {
      //       id: uuidv4(),
      //       name: "Regional",
      //       dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are aggregated per municipality of the Region of Attica and are weighted according to residential population density (source: GHSL POP 2015). Clicking on each municipality, you can see the number of its residents, the mean monthly concentration value (population weighted) and the co-benefit of this scenario for air quality ((scenario-baseline)/baseline, in %), spatially averaged for the municipal area. Pins represent the sites of local in situ measurements (sources: National Regulatory Network and Panace-RI). Select one or more to inspect the mean daily concentrations of observations and predictions (baseline and scenario) locally, along with the latest AQ limits set by WHO (2021).",
      //       subScenarios: [
      //         // {
      //         //   id: 1,
      //         //   name: "Summer",
      //         //   dsc: "The mean monthly model outputs for June 2019 are considered representative of the warm period, according to the mean monthly values of the pollutants from observations, and their correlation to the predictions.",
      //         //   subScenarios: [
      //         //     {
      //         //       id: 1,
      //         //       expanded: true,
      //         //       layer: "",
      //         //       name: "NO₂ - Not Provided",
      //         //       dsc: "Pending..."
      //         //     },
      //         //     {
      //         //       id: 2,
      //         //       expanded: true,
      //         //       layer: "",
      //         //       name: "O3 - Not Provided",
      //         //       dsc: "Pending..."
      //         //     },
      //         //     {
      //         //       id: 3,
      //         //       expanded: true,
      //         //       layer: "",
      //         //       name: "PM2.5 - Not Provided",
      //         //       dsc: "Pending..."
      //         //     }
      //         //   ]
      //         // },
      //         {
      //           id: uuidv4(),
      //           name: "Winter",
      //           dsc: "The mean monthly model outputs for January 2019 are considered representative of the cold period, according to the mean monthly values of the pollutants from observations, and their correlation to the predictions.",
      //           subScenarios: [
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:scenario_1_regional_winter_no2",
      //               name: "NO₂",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:scenario_1_regional_winter_o3",
      //               name: "O₃",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:scenario_1_regional_winter_pm25",
      //               name: "PM₂.₅",
      //               dsc: "Pending...",
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       id: uuidv4(),
      //       name: "Local",
      //       dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are presented in a horizontal spatial analysis of 100 m for the pilot municipality of Penteli. Iso-lines (not shown, if values are negligible) represent the co-benefit of this scenario for air quality, expressed as its percentage difference from the baseline ((scenario-baseline)/baseline, in %).",
      //       subScenarios: [
      //         // {
      //         //   id: 1,
      //         //   name: "Summer",
      //         //   dsc: "Pending...",
      //         //   subScenarios: [
      //         //     {
      //         //       id: 1,
      //         //       expanded: true,
      //         //       layer: "....",
      //         //       name: "NO₂ - Not Provided",
      //         //       dsc: "Pending..."
      //         //     },
      //         //     {
      //         //       id: 2,
      //         //       expanded: true,
      //         //       layer: "...",
      //         //       name: "O3 - Not Provided",
      //         //       dsc: "Pending..."
      //         //     },
      //         //     {
      //         //       id: 3,
      //         //       expanded: true,
      //         //       layer: "...",
      //         //       name: "PM2.5 - Not Provided",
      //         //       dsc: "Pending..."
      //         //     }
      //         //   ]
      //         // },
      //         {
      //           id: uuidv4(),
      //           name: "Winter",
      //           dsc: "Pending...",
      //           subScenarios: [
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:scenario_1_local_winter_no2",
      //               contour: "eiffel:scenario_1_local_winter_contour_no2",
      //               name: "NO₂",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:scenario_1_local_winter_o3",
      //               contour: "eiffel:scenario_1_local_winter_contour_pm25",
      //               name: "O₃",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:scenario_1_local_winter_pm25",
      //               name: "PM₂.₅",
      //               dsc: "Pending...",
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      //   dsc: "This model scenario differentiates from the baseline ONLY in emissions of air pollutants from residential (small) combustion activity. In particular, it uses electricity as the only energy carrier for heating in all types of buildings (electrification 100%, according to EC guidelines for 2050), saving CO₂eq by 98.55%.",
      // },
      {
        id: uuidv4(),
        name: "Electrification of vehicles",
        dsc: "This group of model scenarios differentiates from the baseline / winter case ONLY in emissions of air pollutants from the road transport activity. In particular, it assumes the partial replacement of fuel-powered vehicles with an electric vehicle fleet, according to selected scenarios from the App for Road Transport Emissions (see above).",
        scenarios: [
          {
            id: uuidv4(),
            name: "Regional",
            dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are aggregated per municipality of the Region of Attica and are weighted according to residential population density (source: GHSL POP 2015). Clicking on each municipality, you can see the number of its residents, the mean monthly concentration value (population weighted) and the co-benefit of this scenario for air quality ((scenario-baseline)/baseline, in %), spatially averaged for the municipal area. Pins represent the sites of local in situ measurements (sources: National Regulatory Network and Panace-RI). Select one or more to inspect the mean daily concentrations of observations and predictions (baseline and scenario) locally, along with the latest AQ limits set by WHO (2021).",
            subScenarios: [
              {
                id: uuidv4(),
                name: "GR BAU",
                dsc: "20% of diesel, 10% of unleaded passenger car fleet, 10% of vans and motorcycles, 25% buses = zero emissions (some electric, some hybrid, see the previous application for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_electrif_of_vehicles_regional_gr_bau_no2",
                    style: "aq_electrif_of_vehicles_regional_gr_bau_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_electrif_of_vehicles_regional_gr_bau_o3",
                    style: "aq_electrif_of_vehicles_regional_gr_bau_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_electrif_of_vehicles_regional_gr_bau_pm25",
                    style: "aq_electrif_of_vehicles_regional_gr_bau_pm25",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "GR Focused",
                dsc: "40% of diesel, 10% of unleaded passenger car fleet, 30% of vans and motorcycles, 75% buses= zero emissions (some electric, some hybrid, see the previous application for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_electrif_of_vehicles_regional_gr_focused_no2",
                    style: "aq_electrif_of_vehicles_regional_gr_focused_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_electrif_of_vehicles_regional_gr_focused_o3",
                    style: "aq_electrif_of_vehicles_regional_gr_focused_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_electrif_of_vehicles_regional_gr_focused_pm25",
                    style: "aq_electrif_of_vehicles_regional_gr_focused_pm25",
                    dsc: "Pending..."
                  }
                ]
              }
            ]
          },
          {
            id: uuidv4(),
            name: "Local",
            dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are presented in a horizontal spatial analysis of 100 m for the pilot municipality of Penteli. Iso-lines (not shown, if values are negligible) represent the co-benefit of this scenario for air quality, expressed as its percentage difference from the baseline ((scenario-baseline)/baseline, in %).",
            subScenarios: [
              {
                id: uuidv4(),
                name: "GR BAU",
                dsc: "20% of diesel, 10% of unleaded passenger car fleet, 10% of vans and motorcycles, 25% buses = zero emissions (some electric, some hybrid, see the previous application for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    contour: "eiffel:aq_electrif_of_vehicles_local_gr_bau_no2_contour",
                    layer: "eiffel:aq_electrif_of_vehicles_local_gr_bau_no2",
                    style: "aq_electrif_of_vehicles_local_gr_bau_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    contour: "eiffel:aq_electrif_of_vehicles_local_gr_bau_o3_contour",
                    layer: "eiffel:aq_electrif_of_vehicles_local_gr_bau_o3",
                    style: "aq_electrif_of_vehicles_local_gr_bau_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    contour: "eiffel:aq_electrif_of_vehicles_local_gr_bau_pm25_contour",
                    layer: "eiffel:aq_electrif_of_vehicles_local_gr_bau_pm25",
                    style: "aq_electrif_of_vehicles_local_gr_bau_pm25",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "GR Focused",
                dsc: "40% of diesel, 10% of unleaded passenger car fleet, 30% of vans and motorcycles, 75% buses= zero emissions (some electric, some hybrid, see the previous application for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_electrif_of_vehicles_local_gr_focused_no2",
                    contour: "eiffel:aq_electrif_of_vehicles_local_gr_focused_no2_contour",
                    style: "aq_electrif_of_vehicles_local_gr_focused_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_electrif_of_vehicles_local_gr_focused_o3",
                    contour: "eiffel:aq_electrif_of_vehicles_local_gr_focused_o3_contour",
                    style: "aq_electrif_of_vehicles_local_gr_focused_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_electrif_of_vehicles_local_gr_focused_pm25",
                    contour: "eiffel:aq_electrif_of_vehicles_local_gr_focused_pm25_contour",
                    style: "aq_electrif_of_vehicles_local_gr_focused_pm25",
                    dsc: "Pending..."
                  }
                ]
              }
            ]
          }
        ]
      },
      // {
      //   id: uuidv4(),
      //   name: "Partial electrification of vehicle fleet",
      //   dsc: "This model scenario differentiates from the baseline ONLY in emissions of air pollutants from the road transport activity. In particular, it assumes the replacement of 40% diesel passenger cars, 20% of unleaded passenger cars, 20% vans and motorcycles, and 50% of bases with an electric vehicle fleet. This is regarded an optimistic scenario for Greece by 2030.",
      //   scenarios: [
      //     {
      //       id: uuidv4(),
      //       name: "Regional",
      //       dsc: "Pending...",
      //       subScenarios: [
      //         {
      //           id: uuidv4(),
      //           name: "Winter",
      //           dsc: "Pending...",
      //           subScenarios: [
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:vehicles_regional_winter_no2",
      //               name: "NO₂",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:vehicles_regional_winter_o3",
      //               name: "O₃",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:vehicles_regional_winter_pm25",
      //               name: "PM₂.₅",
      //               dsc: "Pending...",
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       id: uuidv4(),
      //       name: "Local",
      //       dsc: "Pending...",
      //       subScenarios: [
      //         {
      //           id: uuidv4(),
      //           name: "Winter",
      //           dsc: "Pending...",
      //           subScenarios: [
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:vehicles_local_winter_no2",
      //               name: "NO₂",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:vehicles_local_winter_o3",
      //               name: "O₃",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:vehicles_local_winter_pm25",
      //               name: "PM₂.₅",
      //               dsc: "Pending...",
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        id: uuidv4(),
        name: "Combined electrification interventions in the building and vehicle sectors",
        dsc: "This group of model scenarios differentiate from the baseline / winter case in emissions of air pollutants from the sectors of road transport and buildings energy efficiency. In particular, it assumes a combination of selected scenarios from the 2 separate applications, as described below.",
        scenarios: [
          {
            id: uuidv4(),
            name: "Regional",
            dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are aggregated per municipality of the Region of Attica and are weighted according to residential population density (source: GHSL POP 2015). Clicking on each municipality, you can see the number of its residents, the mean monthly concentration value (population weighted) and the co-benefit of this scenario for air quality ((scenario-baseline)/baseline, in %), spatially averaged for the municipal area. Pins represent the sites of local in situ measurements (sources: National Regulatory Network and Panace-RI). Select one or more to inspect the mean daily concentrations of observations and predictions (baseline and scenario) locally, along with the latest AQ limits set by WHO (2021).",
            subScenarios: [
              {
                id: uuidv4(),
                name: "30% + GR Focused",
                dsc: "Zero emission by the 30% of buildings, 40% of diesel, 10% of unleaded passenger car fleet, 30% of vans and motorcycles, 75% buses. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_focused_no2",
                    style: "aq_comb_electrif_invert_regional_30_gr_focused_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_focused_o3",
                    style: "aq_comb_electrif_invert_regional_30_gr_focused_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_focused_pm25",
                    style: "aq_comb_electrif_invert_regional_30_gr_focused_pm25",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "30% + GR BAU",
                dsc: "Zero emission by the 30% of buildings, 20% of diesel, 10% of unleaded passenger car fleet, 10% of vans and motorcycles, 25% buses.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_bau_no2",
                    style: "aq_comb_electrif_invert_regional_30_gr_bau_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_bau_o3",
                    style: "aq_comb_electrif_invert_regional_30_gr_bau_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_bau_pm25",
                    style: "aq_comb_electrif_invert_regional_30_gr_bau_pm25",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "60% + GR Optimistic",
                dsc: "Zero emission by the 60% of buildings, 40% of diesel, 20% of unleaded passenger car fleet, 20% of vans and motorcycles. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_no2",
                    style: "aq_comb_electrif_invert_regional_60_gr_optimistic_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_o3",
                    style: "aq_comb_electrif_invert_regional_60_gr_optimistic_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_pm25",
                    style: "aq_comb_electrif_invert_regional_60_gr_optimistic_pm25",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "60% + GR Base",
                dsc: "Zero emission by the 60% of buildings, 30% of diesel, 15% of unleaded passenger car fleet, 15% of vans and motorcycles, 35% buses. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_base_no2",
                    style: "aq_comb_electrif_invert_regional_60_gr_base_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_base_o3",
                    style: "aq_comb_electrif_invert_regional_60_gr_base_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_base_pm25",
                    style: "aq_comb_electrif_invert_regional_60_gr_base_pm25",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "100% + GR Optimistic",
                dsc: "Zero emission by the entire building stock, 40% of diesel, 20% of unleaded passenger car fleet, 20% of vans and motorcycles. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_no2",
                    style: "aq_comb_electrif_invert_regional_100_gr_optimistic_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_o3",
                    style: "aq_comb_electrif_invert_regional_100_gr_optimistic_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_pm25",
                    style: "aq_comb_electrif_invert_regional_100_gr_optimistic_pm25",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "100% + GR Focused",
                dsc: "Zero emission by the entire building stock, 40% of diesel, 10% of unleaded passenger car fleet, 30% of vans and motorcycles, 75% buses. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_focused_no2",
                    style: "aq_comb_electrif_invert_regional_100_gr_focused_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_focused_o3",
                    style: "aq_comb_electrif_invert_regional_100_gr_focused_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_focused_pm25",
                    style: "aq_comb_electrif_invert_regional_100_gr_focused_pm25",
                    dsc: "Pending..."
                  }
                ]
              }
            ],
          },
          {
            id: uuidv4(),
            name: "Local",
            dsc: "Model outputs for NO₂, O₃ and PM₂.₅ (surface concentrations in μg/m³) are presented in a horizontal spatial analysis of 100 m for the pilot municipality of Penteli. Iso-lines (not shown, if values are negligible) represent the co-benefit of this scenario for air quality, expressed as its percentage difference from the baseline ((scenario-baseline)/baseline, in %).",
            subScenarios: [
              {
                id: uuidv4(),
                name: "30% + GR Focused",
                dsc: "Zero emission by the 30% of buildings, 40% of diesel, 10% of unleaded passenger car fleet, 30% of vans and motorcycles, 75% buses. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_no2",
                    contour: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_no2_contour",
                    style: "aq_comb_electrif_invert_local_30_gr_focused_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_o3",
                    contour: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_o3_contour",
                    style: "aq_comb_electrif_invert_local_30_gr_focused_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_pm25",
                    contour: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_pm25_contour",
                    style: "aq_comb_electrif_invert_local_30_gr_focused_pm25",
                    dsc: "Pending..."
                  },
                ]
              },
              {
                id: uuidv4(),
                name: "30% + GR BAU",
                dsc: "Zero emission by the 30% of buildings, 20% of diesel, 10% of unleaded passenger car fleet, 10% of vans and motorcycles, 25% buses.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_no2",
                    contour: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_no2_contour",
                    style: "aq_comb_electrif_invert_local_30_gr_bau_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_o3",
                    contour: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_o3_contour",
                    style: "aq_comb_electrif_invert_local_30_gr_bau_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_pm25",
                    contour: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_pm25_contour",
                    style: "aq_comb_electrif_invert_local_30_gr_bau_o3",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "60% + GR Optimistic",
                dsc: "Zero emission by the 60% of buildings, 40% of diesel, 20% of unleaded passenger car fleet, 20% of vans and motorcycles. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_no2",
                    contour: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_no2_contour",
                    style: "aq_comb_electrif_invert_local_60_gr_optimistic_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_o3",
                    contour: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_o3_contour",
                    style: "aq_comb_electrif_invert_local_60_gr_optimistic_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_pm25",
                    contour: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_pm25_contour",
                    style: "aq_comb_electrif_invert_local_60_gr_optimistic_pm25",
                    dsc: "Pending..."
                  },
                ]
              },
              {
                id: uuidv4(),
                name: "60% + GR Base",
                dsc: "Zero emission by the 60% of buildings, 30% of diesel, 15% of unleaded passenger car fleet, 15% of vans and motorcycles, 35% buses. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_local_60_gr_base_no2",
                    contour: "eiffel:aq_comb_electrif_invert_local_60_gr_base_no2_contour",
                    style: "aq_comb_electrif_invert_local_60_gr_base_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_local_60_gr_base_o3",
                    contour: "eiffel:aq_comb_electrif_invert_local_60_gr_base_o3_contour",
                    style: "aq_comb_electrif_invert_local_60_gr_base_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_local_60_gr_base_pm25",
                    contour: "eiffel:aq_comb_electrif_invert_local_60_gr_base_pm25_contour",
                    style: "aq_comb_electrif_invert_local_60_gr_base_pm25",
                    dsc: "Pending..."
                  }
                ]
              },
              {
                id: uuidv4(),
                name: "100% + GR Optimistic",
                dsc: "Zero emission by the entire building stock, 40% of diesel, 20% of unleaded passenger car fleet, 20% of vans and motorcycles. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_no2",
                    contour: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_no2_contour",
                    style: "aq_comb_electrif_invert_local_100_gr_optimistic_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_o3",
                    contour: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_o3_contour",
                    style: "aq_comb_electrif_invert_local_100_gr_optimistic_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_pm25",
                    contour: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_pm25_contour",
                    style: "aq_comb_electrif_invert_local_100_gr_optimistic_pm25",
                    dsc: "Pending..."
                  },
                ]
              },
              {
                id: uuidv4(),
                name: "100% + GR Focused",
                dsc: "Zero emission by the entire building stock, 40% of diesel, 10% of unleaded passenger car fleet, 30% of vans and motorcycles, 75% buses. See the other 2 Applications for more information.",
                subScenarios: [
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "NO₂",
                    layer: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_no2",
                    contour: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_no2_contour",
                    style: "aq_comb_electrif_invert_local_100_gr_focused_no2",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "O₃",
                    layer: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_o3",
                    contour: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_o3_contour",
                    style: "aq_comb_electrif_invert_local_100_gr_focused_o3",
                    dsc: "Pending..."
                  },
                  {
                    id: uuidv4(),
                    expanded: true,
                    name: "PM₂.₅",
                    layer: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_pm25",
                    contour: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_pm25_contour",
                    style: "aq_comb_electrif_invert_local_100_gr_focused_pm25",
                    dsc: "Pending..."
                  },
                ]
              }
            ]
          }
        ]
      },
      // {
      //   id: uuidv4(),
      //   name: "Combined electrification interventions in the building and vehicle sectors",
      //   dsc: "This group of model scenarios differentiate from the baseline / winter case in emissions of air pollutants from the sectors of road transport and buildings energy efficiency. In particular, it assumes a combination of selected scenarios from the 2 separate applications, as described below.",
      //   scenarios: [
      //     {
      //       id: uuidv4(),
      //       name: "Regional",
      //       dsc: "Pending...",
      //       subScenarios: [
      //         {
      //           id: uuidv4(),
      //           name: "Winter",
      //           dsc: "Pending...",
      //           subScenarios: [
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:buildings_vehicles_regional_winter_no2",
      //               name: "NO₂",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:buildings_vehicles_regional_winter_o3",
      //               name: "O₃",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:buildings_vehicles_regional_winter_pm25",
      //               name: "PM₂.₅",
      //               dsc: "Pending...",
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       id: uuidv4(),
      //       name: "Local",
      //       dsc: "Pending...",
      //       subScenarios: [
      //         {
      //           id: uuidv4(),
      //           name: "Winter",
      //           dsc: "Pending...",
      //           subScenarios: [
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:buildings_vehicles_local_winter_no2",
      //               name: "NO₂",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:buildings_vehicles_local_winter_o3",
      //               name: "O₃",
      //               dsc: "Pending...",
      //             },
      //             {
      //               id: uuidv4(),
      //               expanded: true,
      //               layer: "eiffel:buildings_vehicles_local_winter_pm25",
      //               name: "PM₂.₅",
      //               dsc: "Pending...",
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
];

export default SCENARIOS;
