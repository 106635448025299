<template>
  <div class="text-center d-flex">
    <div class="text-center d-flex flex-column custom-gap">
    <v-tooltip 
      v-for="control in controls"
      :key="control.id"
      offset-y
      right
    >
      <template #activator="{ on, attrs }">
        <v-btn
          depressed
          class="mx-2 py-4 rounded custom-shadow custom-scale"
          dark
          x-small
          elevation-12
          color="scenariobutton"
          v-bind="attrs"
          v-on="on"
          @click="updateZoom(control.name)"
        >
          <v-icon dark small>
            {{ control.icon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t(`zoomControl.tooltip.${control.tooltipValue}`) }}</span>
    </v-tooltip>
  </div>
  </div>
</template>

<script>
export default {
    name: "BaseMapControl",
    data () {
        return {
          controls: [
            {
              id: 1,
              name: "plus",
              icon: "mdi-plus",
              tooltipValue: "zoomIn"
            },
            {
              id: 2,
              name: "minus",
              icon: "mdi-minus",
              tooltipValue: "zoomOut"
            }
          ]
        }
    },
    methods: {
      updateZoom(name) {
            this.$emit('update:zoom', name);
        }
    }
}
</script>

<style scoped>

</style>