export const MARKERPOINTS = [
    // {
    //     fullName: "P-Gyzi",
    //     name: "GYZ",
    //     lng: "23.744569",
    //     lat: "37.99344"
    // },
    // {
    //     fullName: "P-Pefki",
    //     name: "PEF",
    //     lng: "23.796773",
    //     lat: "38.062267"
    // },
    // {
    //     fullName: "P-Vouliagmeni",
    //     name: "VOU",
    //     lng: "23.783355",
    //     lat: "37.810879"
    // },
    // {
    //     fullName: "P-Melissia",
    //     name: "MEL",
    //     lng: "23.840033",
    //     lat: "38.056528"
    // },
    // {
    //     fullName: "P-Halandri",
    //     name: "HAL",
    //     lng: "23.810549",
    //     lat: "38.018725"
    // },
    // {
    //     fullName: "P-Zea",
    //     name: "ZEA",
    //     lng: "23.644434",
    //     lat: "37.935792"
    // },
    // {
    //     fullName: "P-Keratsini",
    //     name: "KER",
    //     lng: "23.618626",
    //     lat: "37.966448"
    // },
    // {
    //     fullName: "P-Haidari",
    //     name: "HAI",
    //     lng: "23.647569",
    //     lat: "38.013319"
    // },
    {
        fullName: "Athinas",
        name: "ATH",
        lng: "23.7268416666661",
        lat: "37.9782027778058"
    },
    {
        fullName: "Aristotelous",
        name: "ARI",
        lng: "23.7276277777784",
        lat: "37.9880277777554"
    },
    {
        fullName: "Geoponiki",
        name: "GEO",
        lng: "23.7067888888925",
        lat: "37.9836250000276"
    },
    {
        fullName: "Liosia",
        name: "LIO",
        lng: "23.6978416666617",
        lat: "38.0768138888991"
    },
    {
        fullName: "Lykovrisi",
        name: "LYK",
        lng: "23.7888083333298",
        lat: "38.0678749999557"
    },
    {
        fullName: "Marousi",
        name: "MAR",
        lng: "23.7873583333295",
        lat: "38.0308388889115"
    },
    {
        fullName: "NeaSmyrni",
        name: "SMY",
        lng: "23.7130083333368",
        lat: "37.9319944443997"
    },
    {
        fullName: "Patision",
        name: "PAT",
        lng: "23.7330472222166",
        lat: "37.9994583332933"
    },
    {
        fullName: "Piraeus",
        name: "PIR",
        lng: "23.6452249999996",
        lat: "37.9446527777432"
    },
    {
        fullName: "Peristeri",
        name: "PER",
        lng: "23.688355555555",
        lat: "38.0208083333348"
    },
    {
        fullName: "AgiaParaskevi",
        name: "AGP",
        lng: "23.8194166666673",
        lat: "37.9951083333679"
    },
    {
        fullName: "Elefsina",
        name: "ELE",
        lng: "23.5384472222247",
        lat: "38.0513499999649"
    },
    {
        fullName: "Thrakomakedones",
        name: "THR",
        lng: "23.7581833333292",
        lat: "38.1435222222078"
    },
    {
        fullName: "Koropi",
        name: "KOR",
        lng: "23.8790222222222",
        lat: "37.901305555542"
    },
    {
        fullName: "Thissio",
        name: "THI",
        lng: "23.7180549640425",
        lat: "37.9731204733766"
    },
];

export default MARKERPOINTS;


