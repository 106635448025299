import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeLayer: false,
  },
  getters: {
    isLayerActive (state) {
      return state.activeLayer;
    }
  },
  mutations: {
    changeActiveLayer (state, payload) {
      state.activeLayer = payload;
    }
  },
  actions: {
    setActiveLayer (context, payload) {
      context.commit('changeActiveLayer', payload);
    }
  },
  modules: {},
});
