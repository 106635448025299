export const TRANSLITERATE_WORDS = {
    'Α': 'A', 'α': 'a',
    'Β': 'B', 'β': 'b',
    'Γ': 'C', 'γ': 'g',
    'Δ': 'D', 'δ': 'd',
    'Ε': 'E', 'ε': 'e',
    'Ζ': 'F', 'ζ': 'z',
    'Η': 'G', 'η': 'i',
    'Θ': 'Th', 'θ': 'th',
    'Ι': 'G', 'ι': 'i',
    'Κ': 'K', 'κ': 'k',
    'Λ': 'L', 'λ': 'l',
    'Μ': 'M', 'μ': 'm',
    'Ν': 'N', 'ν': 'n',
    'Ξ': 'X', 'ξ': 'x',
    'Ο': 'O', 'ο': 'o',
    'Π': 'P', 'π': 'p',
    'Ρ': 'R', 'ρ': 'r',
    'Σ': 'S', 'σ': 's', 'ς': 's',
    'Τ': 'T', 'τ': 't',
    'Υ': 'Y', 'υ': 'y',
    'Φ': 'Ph', 'φ': 'ph',
    'Χ': 'Ch', 'χ': 'ch',
    'Ψ': 'Ps', 'ψ': 'ps',
    'Ω': 'O', 'ω': 'o'
};

export default TRANSLITERATE_WORDS;