<template>
    <div 
      v-if="legends.length > 0" 
      class="py-3 legend_wrapper"
    >
        <div class="d-flex justify-center align-center custom-gap">
            <div 
                v-for="legend in legends" 
                :key="legend.id"
                class="flex-row d-flex custom-gap align-center cursor-info"
            >
                <v-tooltip offset-x top>
                    <template #activator="{ on, attrs }">
                        <div
                          :class="legend.color"
                          :style="{ background: legend.color }"
                          class="legend-box rounded custom-shadow custom-scale"
                          v-bind="attrs"
                          v-on="on"
                        >
                        </div>
                        <span class="font-weight-normal">
                            {{ legend.value }}
                        </span>
                    </template>
                    <span>{{ legend.value }}</span>
                    <span
                        v-show="legend.dsc"
                        class="text-caption ml-1"
                    >
                        {{ legend.dsc }}
                    </span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseMapLegend",
    props: {
        legends: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {}
    },
    methods: {}
}
</script>

<style scoped>
.legend-box {
    padding: 10px;
}
.legend_wrapper {
    backdrop-filter: blur(3px);
    min-width: 900px;
    padding: 0 4.5rem;
}
</style>