<template>
  <v-dialog
    v-model="isOpen"
    class="popup_wrapper"
    content-class="custom-dialog"
    max-width="555"
  >
    <div>
      <v-sheet
        v-if="isLoading"
        class="content_wrapper"
      >
        <v-skeleton-loader
          class="pa-3"
          type="table-heading, list-item, image, list-item"
        ></v-skeleton-loader>
      </v-sheet>
      <v-container v-else class="pa-1 ma-0">
        <v-sheet rounded class="pa-3 custom-shadow content_wrapper">
          <div class="d-flex justify-space-between">
            <div class="justify-start flex-column">
              <v-card-title
                class="mb-2 text-h6 mapbutton--text d-flex align-center custom-gap"
              >
                <v-icon normal color="textDark" class="scenariobutton pa-1 rounded">
                  mdi mdi-layers-search
                </v-icon>
                <div class="textDark--text">
                  |
                </div>
                <span v-if="findNameFeature" class="text-uppercase custom-title">
                  {{ feature[findNameFeature] }}
                </span>
                <span v-else class="custom-title">
                  {{ $t(`metaDataDialog.banner`) }}
                </span> 
              </v-card-title>
              <v-card-subtitle>
                {{ $t(`metaDataDialog.title`) }}
              </v-card-subtitle>
            </div>
            <div class="justify-end">
              <v-btn
                depressed
                outlined
                style="border: none;"
                class="mt-4 white--text"
                @click="isOpen = !isOpen"
              >
                <v-icon normal class="scenariobutton--text">
                  mdi mdi-close-thick
                </v-icon>
              </v-btn>
            </div>
          </div>
          <span
            v-if="findNameOfSchool"
            class="px-4 py-2 text-end textDark--text"
          >
            {{ feature[findNameOfSchool] }}
          </span>
          <v-card-subtitle 
            v-if="findLabelFeature" 
            class="d-flex align-items custom-gap"
          >
            <span class="py-2">
              {{ $t(`metaDataDialog.energyPerformanceTitle`) }}
            </span>
            <div 
              class="rounded-r-xl font-weight-bold justify-start align-center d-flex px-2 textDark--text text-h6"
              :style="`
                width: 60%;
                background: ${determineBackground[feature[findLabelFeature]]?.value || '#FFFFFF'};
              `"
              >
                {{ transliterateGreekToEnglish(feature[findLabelFeature]) || 'N/A' }}
              </div>
          </v-card-subtitle>
          <v-slide-group
            v-if="Object.keys(feature).length > 0"
            multiple
            show-arrows
            center-active
            class="my-3"
          >
            <div 
              v-for="(key, index) in excludeFeaturesToShow"
              :key="index"
              class="px-auto mx-auto"
            >
              <v-card
                outlined
                class="card_wrapper textDark--text pa-1 ma-1 flex-column justify-end text-center d-flex custom-shadow"
                width="240"
                height="150"
              >
                <v-card-title class="font-weight-light text-subtitle-1 navbutton--text ma-auto d-inline-block text-break" style="height: 50%">
                  {{ extractContent(obtainKeys[key]).stringWihoutParentheses }}
                </v-card-title>
                <v-divider class="divider-container ma-auto"></v-divider>
                <v-card-text class="font-weight-bold py-3 text-h6">
                  {{ formatNumberWithCommasAndDecimals(feature[key], key) }}
                  <span class="font-weight-light text-caption">
                    {{ extractContent(obtainKeys[key]).contentInsideParentheses }}
                  </span>
                </v-card-text>
              </v-card>
            </div>
          </v-slide-group>
          <v-divider class="my-3 mt-3"></v-divider>
          <div
            v-if="Object.keys(feature).length > 0"
            class="d-flex align-center pa-3"
          >
            <v-icon left small color="scenariobutton">
              mdi mdi-clock-time-one
            </v-icon>
            <span class="text-caption">
              {{ formatFeatureTime }}
            </span>
          </div>
        </v-sheet>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
// Importing Energy Production indicators (keys)
import ENERGY_PROD_KEYS from '../helpers/keyMapEnergyProduction.js';

// Importing Metadata Keys (formated)
import METADATA_KEYS from '../helpers/keyMapMetadataNames.js';

// Importing Transliterate keys
import TRANSLITERATE_WORDS from '../helpers/keyMapTransliterateGreekToEnglish.js';

export default {
  name: "BaseFeatureDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    feature: {
      type: [Array, Object],
      required: false,
      default: () => {
        return [];
      },
    },
    featuresToShow: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    featureTimeStamp: {
      type: String,
      required: false,
      default: "",
    },
    selectedLayerName: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      isOpen: false,
      // Used to determine backgrounds for energy production.
      determineBackground: ENERGY_PROD_KEYS,
      // Used to transform key to actual names that are shown in UI.
      obtainKeys: METADATA_KEYS,
      // Used to transliterate keys from greek version to english.
      transliterateWords: TRANSLITERATE_WORDS
    };
  },

  computed: {
    // Find the Label of the feature
    findLabelFeature() {
      return this.featuresToShow.find((feature) => feature == "Label" || feature === "ENERGY_LABELS");
    },
    // Find the name of the feature:
    findNameFeature() {
      return this.featuresToShow.find(feature => feature == "NAME" || feature == "Municipality" || feature == "int_name");
    },
    // Find the name of the school (works only in photovoltaic energy adequacy of schools scenario)
    findNameOfSchool() {
      return this.featuresToShow.find((feature) => feature == "Name");
    },
    // Exclude the name of the card feature modal:
    excludeFeaturesToShow() {
      return this.featuresToShow.filter((feature) => feature != "Name" && feature != "Municipality" && feature != "int_name" && feature != "NAME" && feature != 'Label' && feature != 'ENERGY_LABELS');
    },
    formatFeatureTime() {
      let date = new Date(this.featureTimeStamp);

      let options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };

      return date.toLocaleDateString("en-US", options);
    },
  },

  methods: {
    formatNumberWithCommasAndDecimals(input, string) {
      let decimals = string === "population" ? 0 : 3;

      if (typeof input !== "number") return "-"; // Not provided

      let isInteger = input % 1 === 0;
      if (isInteger) {
        decimals = 0;
      }

      // Used toLocaleString to add thousands separators
      let formattedNumber = input.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });

      if (this.selectedLayerName.includes("photovoltaic")) {
        return this.cleanStringValue(formattedNumber);
      } else {
        return formattedNumber;
      }
    },

    transliterateGreekToEnglish (text) {
      if (!text) return;
      return text.split("").map(char => this.transliterateWords[char] || char).join("");
    },

    cleanStringValue(input) {
        let sanitizedNumber = input.replace(/[^0-9.]/g, '');
        let parsedNumber = parseFloat(sanitizedNumber);

        return isNaN(parsedNumber) ? 0 : Math.round(parsedNumber);
    },

    extractContent(currentString) {
      const regex = /\(([^)]+)\)/; // Match text inside parentheses
      const match = regex.exec(currentString);

      if (match) {
        const stringWihoutParentheses = currentString.replace(regex, '').trim();
        const contentInsideParentheses = match[1];

        return { stringWihoutParentheses, contentInsideParentheses };
      } else {
        return { stringWihoutParentheses: currentString, contentInsideParentheses: '' };
      }
    }
  },

  watch: {
    dialog(_newVal) {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.popup_wrapper {
  z-index: 9999;
  scrollbar-width: none; /* Hide the scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide the scrollbar for IE and Edge */
}
.popup_wrapper::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for Chrome and Safari */
}
.content_wrapper {
  background: rgba(255,255,255,0.8);
}
.card_wrapper {
  background: rgba(255,255,255,0.4);
}
.custom-dialog {
  position: absolute !important;
  top: 100px; /* Adjust the top position as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
}
.divider-container {
    width: 50px;
    animation: dividerAnimation 2s ease infinite alternate;
}
.custom-title {
  font-weight: 900 !important;
  text-shadow: 1px 0px 1px #BD7C37, 0px 1px 0px rgba(0,0,0,0.15);
}

/* Animated divider color */
@keyframes dividerAnimation {
  0% {
    border-bottom: 2px solid #98A753;
  }
  50% {
    border-bottom: 2px solid #BD7C37;
  }
  100% {
    border-bottom: 2px solid #5A85A9;
  }
}
</style>
