export const KEYMAPFEATURES = {
  
    // Buildings -> Baseline -> Regional
    'eiffel:building_regional_baseline_emissions': ["NAME", "_ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kwh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_baseline_energy_final': ["NAME", "_ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kwh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_baseline_performance_energy': ["NAME", "_ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kwh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    // 'eiffel:building_regional_baseline_ghg_savings': ["NAME"],

    // Buldings -> BaseLine -> Local
    'eiffel:building_local_baseline_emissions': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2"],
    'eiffel:building_local_baseline_energy_final': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2"],
    'eiffel:building_local_baseline_performance_energy': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2"],

    // Buildings -> Scenario -> Regional 30
    'eiffel:building_regional_scenario_elecrtif_30_ghg_emissions': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_scenario_elecrtif_30_ghg_final_energy': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_scenario_elecrtif_30_ghg_energy': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_scenario_elecrtif_30_ghg_savings': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS", "CO2eq_SAVINGS"],

    // Buildings -> Scenario -> Regional 60
    'eiffel:building_regional_scenario_elecrtif_60_ghg_emissions': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_scenario_elecrtif_60_ghg_final_energy': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_scenario_elecrtif_60_ghg_energy': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_scenario_elecrtif_60_ghg_savings': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS", "CO2eq_SAVINGS"],

    // Buildings -> Scenario -> Regional 100
    'eiffel:building_regional_scenario_elecrtif_100_ghg_emissions': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_scenario_elecrtif_100_ghg_final_energy': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_scenario_elecrtif_100_ghg_energy': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_scenario_elecrtif_100_ghg_savings': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS", "CO2eq_SAVINGS"],

    // Buildings -> Scenario -> Local 30
    'eiffel:building_local_scenario_elecrtif_30_ghg_savings': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings"],
    'eiffel:building_local_scenario_elecrtif_30_ghg_emissions': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings_%"],
    'eiffel:building_local_scenario_elecrtif_30_final_energy': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings_%"],
    'eiffel:building_local_scenario_elecrtif_30_energy': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings_%"],

    // Buildings -> Scenario -> Local 60
    'eiffel:building_local_scenario_elecrtif_60_ghg_savings': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings"],
    'eiffel:building_local_scenario_elecrtif_60_ghg_emissions': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings_%"],
    'eiffel:building_local_scenario_elecrtif_60_final_energy': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings_%"],
    'eiffel:building_local_scenario_elecrtif_60_energy': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings_%"],

    // Buildings -> Scenario -> Local 100
    'eiffel:building_local_scenario_elecrtif_100_ghg_savings': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Saving"],
    'eiffel:building_local_scenario_elecrtif_100_ghg_emissions': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings_%"],
    'eiffel:building_local_scenario_elecrtif_100_final_energy': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings_%"],
    'eiffel:building_local_scenario_elecrtif_100_energy': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings_%"],

    // Buildings -> Hybrid -> Regional
    'eiffel:building_regional_hybrid_ghg_emissions': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_hybrid_ghg_final_energy': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_hybrid_ghg_energy': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS"],
    'eiffel:building_regional_hybrid_ghg_savings': ["NAME", "ENERGY_CONSUMPTION_GWh", "ENERGY_CONSUMPTION_kWh_m2", "CO2eq_EMISSIONS_kt", "CO2eq_EMISSIONS_kgr_m2", "ENERGY_LABELS", "CO2eq_SAVINGS"],

    // Buildings -> Hybrid -> Local
    'eiffel:building_local_hybrid_ghg_emissions': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings"],
    'eiffel:building_local_hybrid_ghg_final_energy': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings"],
    'eiffel:building_local_hybrid_ghg_energy': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings"],
    'eiffel:building_local_hybrid_ghg_savings': ["Label", "CO2_t", "CO2_kgm-2", "Energy_MWh", "Energy_kWhm-2", "CO2_Savings"],

    // Solar -> BaseLine -> Regional
    'eiffel:photovoltaic_stepwise_approach_regional_baseline_adequacy': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_baseline_savings': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_baseline_energy_production': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],

    // Solar -> BaseLine -> Local
    'eiffel:photovoltaic_stepwise_approach_local_baseline_adequacy': ["Energy_Production", "CO2_Equivalent", "Adequacy_perc"],
    'eiffel:photovoltaic_stepwise_approach_local_baseline_savings': ["Energy_Production", "CO2_Equivalent", "Adequacy(perc)"],
    'eiffel:photovoltaic_stepwise_approach_local_baseline_energy_production': ["Energy_Production", "CO2_Equivalent", "Adequacy(perc)"],

    // Solar -> Stepwise Approach -> Regional -> Scenario
    'eiffel:photovoltaic_stepwise_approach_regional_10_savings': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_10_energy_production': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_10_adequacy': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_40_savings': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_40_energy_production': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_40_adequacy': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_70_savings': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_70_energy_production': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],
    'eiffel:photovoltaic_stepwise_approach_regional_70_adequacy': ["NAME", "Energy_Production_GWh", "GHG_Emissions_Savings_kt", "Adequacy"],

    // Solar -> Scenario -> Local -> Scenario
    'eiffel:photovoltaic_stepwise_approach_local_10_savings': ["CO2_Equivalent", "Energy_Production", "Adequacy(perc)"],
    'eiffel:photovoltaic_stepwise_approach_local_10_energy_production': ["CO2_Equivalent", "Energy_Production", "Adequacy(perc)"],
    'eiffel:photovoltaic_stepwise_approach_local_10_adequacy': ["CO2_Equivalent", "Energy_Production", "Adequacy_perc"],
    'eiffel:photovoltaic_stepwise_approach_local_40_savings': ["CO2_Equivalent", "Energy_Production", "Adequacy(perc)"],
    'eiffel:photovoltaic_stepwise_approach_local_40_energy_production': ["CO2_Equivalent", "Energy_Production", "Adequacy(perc)"],
    'eiffel:photovoltaic_stepwise_approach_local_40_adequacy': ["CO2_Equivalent", "Energy_Production", "Adequacy_perc"],
    'eiffel:photovoltaic_stepwise_approach_local_70_savings': ["CO2_Equivalent", "Energy_Production", "Adequacy(perc)"],
    'eiffel:photovoltaic_stepwise_approach_local_70_energy_production': ["CO2_Equivalent", "Energy_Production", "Adequacy(perc)"],
    'eiffel:photovoltaic_stepwise_approach_local_70_adequacy': ["CO2_Equivalent", "Energy_Production", "Adequacy_perc"],

    // Solar -> Scenario Schools
    'eiffel:photovoltaic_schools': ["Annual_PV_Energy_Production_MWh", "Municipality", "Name", "Percentage_Energy_Adequacy"],

    // AQ -> BaseLine -> Rregional -> Summer
    'eiffel:baseline_regional_summer_o3': ["NAME", "population", "population_weighted_pollutant"],
    'eiffel:baseline_regional_summer_no2': ["NAME","population", "population_weighted_pollutant"],
    'eiffel:baseline_regional_summer_pm25': ["NAME","population", "population_weighted_pollutant"],

    // AQ -> BaseLine => Regional -> Winter
    'eiffel:baseline_regional_winter_o3': ["NAME", "population", "population_weighted_pollutant"],
    'eiffel:baseline_regional_winter_no2': ["NAME", "population", "population_weighted_pollutant"],
    'eiffel:baseline_regional_winter_pm25': ["NAME", "population", "population_weighted_pollutant"],

    // AQ -> BaseLine -> Local -> Winter
    'eiffel:baseline_local_winter_no2': ["GRAY_INDEX"],
    'eiffel:baseline_local_winter_o3': ["GRAY_INDEX"],
    'eiffel:baseline_local_winter_pm25': ["GRAY_INDEX"],

    // AQ -> BaseLine -> Local -> Summer
    'eiffel:baseline_local_summer_no2': ["GRAY_INDEX"],
    'eiffel:baseline_local_summer_o3': ["GRAY_INDEX"],
    'eiffel:baseline_local_summer_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario 1 -> Local -> Winter
    'eiffel:scenario_1_local_winter_no2': ["GRAY_INDEX"],
    'eiffel:scenario_1_local_winter_o3': ["GRAY_INDEX"],
    'eiffel:scenario_1_local_winter_pm25': ["GRAY_INDEX"],

    // AQ -> Scenariio Electification of Buildings (30%) -> Regional -> Winter
    'air_quality_electification_of_buildings_regional_winter_30_no2': ["NAME", "population", "population_weighted_pollutant", "Percentage Difference"],
    'air_quality_electification_of_buildings_regional_winter_30_o3': ["NAME", "population", "population_weighted_pollutant", "Percentage Difference"],
    'air_quality_electification_of_buildings_regional_winter_30_pm25': ["NAME", "population", "population_weighted_pollutant", "Percentage Difference"],

    // AQ -> Scenario Electrification of Buildings (30%) -> Local -> Winter
    'air_quality_electification_of_buildings_local_winter_30_no2': ["GRAY_INDEX"],
    'air_quality_electification_of_buildings_local_winter_30_o3': ["GRAY_INDEX"],
    'air_quality_electification_of_buildings_local_winter_30_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario 1 -> Regional -> Winter
    'eiffel:scenario_1_regional_winter_no2': ["NAME", "population", "population_weighted_pollutant", "Percentage Difference (%)"],
    'eiffel:scenario_1_regional_winter_o3': ["NAME", "population", "population_weighted_pollutant", "Percentage Difference (%)"],
    'eiffel:scenario_1_regional_winter_pm25': ["NAME", "population", "population_weighted_pollutant", "Percentage Difference (%)"],


    // AQ -> Scenario 2 -> Regional -> Winter
    'eiffel:vehicles_regional_winter_no2': ["NAME", "population_weighted_pollutant", "Population", "Percentage Diffeence"],
    'eiffel:vehicles_regional_winter_o3': ["NAME", "population_weighted_pollutant", "Population", "Percentage Difference"],
    'eiffel:vehicles_regional_winter_pm25': ["NAME", "population_weighted_pollutant", "Population", "Percentage Difference"],

    // AQ -> Scenario 2 -> Local -> Winter
    'eiffel:vehicles_local_winter_no2': ["GRAY_INDEX"],
    'eiffel:vehicles_local_winter_o3': ["GRAY_INDEX"],
    'eiffel:vehicles_local_winter_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario Electrification of vehicles Regional -> GR Focused
    'eiffel:aq_electrif_of_vehicles_regional_gr_focused_no2': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_electrif_of_vehicles_regional_gr_focused_o3': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_electrif_of_vehicles_regional_gr_focused_pm25': ["NAME", "population", "population_weighted_pollutant", "Difference"],

    // AQ -> Scenario Electrification of vehicles Local -> GR Focused
    'eiffel:aq_electrif_of_vehicles_local_gr_focused_no2': ["GRAY_INDEX"],
    'eiffel:aq_electrif_of_vehicles_local_gr_focused_o3': ["GRAY_INDEX"],
    'eiffel:aq_electrif_of_vehicles_local_gr_focused_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario Electrification of vehicles Regional -> GR BAU
    'eiffel:aq_electrif_of_vehicles_regional_gr_bau_no2': ["NAME", "population", "population_weighted_pollutant", "Percentage_Difference"],
    'eiffel:aq_electrif_of_vehicles_regional_gr_bau_o3': ["NAME", "population", "population_weighted_pollutant", "Percentage_Difference"],
    'eiffel:aq_electrif_of_vehicles_regional_gr_bau_pm25': ["NAME", "population", "population_weighted_pollutant", "Percentage_Difference"],

    // AQ -> Scenario Electrification of vehicles Local -> GR BAU
    'eiffel:aq_electrif_of_vehicles_local_gr_bau_no2': ["GRAY_INDEX"],
    'eiffel:aq_electrif_of_vehicles_local_gr_bau_o3': ["GRAY_INDEX"],
    'eiffel:aq_electrif_of_vehicles_local_gr_bau_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario Combined electrification interventions -> Regional -> 30% GR Focused
    'eiffel:aq_comb_electrif_invert_regional_30_gr_focused_no2': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_comb_electrif_invert_regional_30_gr_focused_o3': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_comb_electrif_invert_regional_30_gr_focused_pm25': ["NAME", "population", "population_weighted_pollutant", "Difference"],

    // AQ -> Scenario Combined electrification interventions -> Local -> 30% GR Focused
    'eiffel:aq_comb_electrif_invert_local_30_gr_focused_no2': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_30_gr_focused_o3': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_30_gr_focused_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario Combined electrification interventions -> Regional -> 30% GR Bau
    'eiffel:aq_comb_electrif_invert_regional_30_gr_bau_no2': ["NAME", "population", "population_weighted_pollutant", "Pecentage_Difference"],
    'eiffel:aq_comb_electrif_invert_regional_30_gr_bau_o3': ["NAME", "population", "population_weighted_pollutant", "Pecentage_Difference"],
    'eiffel:aq_comb_electrif_invert_regional_30_gr_bau_pm25': ["NAME", "population", "population_weighted_pollutant", "Recentage_Difference"],

    // AQ -> Scenario Combined electrification interventions -> Local -> 30% GR Bau
    'eiffel:aq_comb_electrif_invert_local_30_gr_bau_no2': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_30_gr_bau_o3': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_30_gr_bau_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario Combined electrification interventions -> Regional -> 60% GR Optimistic
    'eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_no2': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_o3': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_pm25': ["NAME", "population", "population_weighted_pollutant", "Difference"],

    // AQ -> Scenario Combined electrification interventions -> Local -> 60% GR Optimistic
    'eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_no2': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_o3': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario Combined electrification interventions -> Regional -> 60% GR Base
    'eiffel:aq_comb_electrif_invert_regional_60_gr_base_no2': ["NAME", "population", "population_weighted_pollutant", "Pecentage_Difference"],
    'eiffel:aq_comb_electrif_invert_regional_60_gr_base_o3': ["NAME", "population", "population_weighted_pollutant", "Pecentage_Difference"],
    'eiffel:aq_comb_electrif_invert_regional_60_gr_base_pm25': ["NAME", "population", "population_weighted_pollutant", "Pecentage_Difference"],

    // AQ -> Scenario Combined electrification interventions -> Local -> 60% GR Base
    'eiffel:aq_comb_electrif_invert_local_60_gr_base_no2': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_60_gr_base_o3': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_60_gr_base_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario Combined electrification interventions -> Regional -> 100% GR Optimistic
    'eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_no2': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_o3': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_pm25': ["NAME", "population", "population_weighted_pollutant", "Difference"],
  
    // AQ -> Scenario Combined electrification interventions -> Local -> 100% GR Optimistic
    'eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_no2': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_o3': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario Combined electrification interventions -> Regional -> 100% GR Focused
    'eiffel:aq_comb_electrif_invert_regional_100_gr_focused_no2': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_comb_electrif_invert_regional_100_gr_focused_o3': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    'eiffel:aq_comb_electrif_invert_regional_100_gr_focused_pm25': ["NAME", "population", "population_weighted_pollutant", "Difference"],
    
    // AQ -> Scenario Combined electrification interventions -> Local -> 100% GR Focused
    'eiffel:aq_comb_electrif_invert_local_100_gr_focused_no2': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_100_gr_focused_o3': ["GRAY_INDEX"],
    'eiffel:aq_comb_electrif_invert_local_100_gr_focused_pm25': ["GRAY_INDEX"],

    // AQ -> Scenario 3 -> Regional -> Winter
    'eiffel:buildings_vehicles_regional_winter_no2': ["NAME", "population_weighted_pollutant", "Population", 'Percentage Difference'],
    'eiffel:buildings_vehicles_regional_winter_o3': ["NAME", "population_weighted_pollutant", "Population", "Percentage Difference"],
    'eiffel:buildings_vehicles_regional_winter_pm25': ["NAME", "population_weighted_pollutant", "Population", "Percentage Difference"],

    // AQ -> Scenario 3 -> Local -> Winter
    'eiffel:buildings_vehicles_local_winter_no2': ["GRAY_INDEX"],
    'eiffel:buildings_vehicles_local_winter_o3': ["GRAY_INDEX"],
    'eiffel:buildings_vehicles_local_winter_pm25': ["GRAY_INDEX"],

    // Copert -> Baseline
    'eiffel:copert_roads_regional_baseline': ["int_name", "CO2eq_kt"],
    'eiffel:copert_roads_local_baseline': ["int_name", "CO2eq_kt"],

    // Copert -> GR optimistic scenario
    'eiffel:copert_roads_regional_optimistic_scenario': ["int_name", "CO2eq_kt"],
    'eiffel:copert_roads_local_optimistic_scenario': ["int_name", "CO2eq_kt"],
    
    // Copert -> GR base scenario
    'eiffel:copert_roads_regional_base_scenario': ["int_name", "CO2eq_kt"],
    'eiffel:copert_roads_local_base_scenario': ["int_name", "CO2eq_kt"],

    // Copert -> GR bau scenario
    'eiffel:copert_roads_regional_bau_scenario': ["int_name", "CO2eq_kt"],
    'eiffel:copert_roads_local_bau_scenario': ["int_name", "CO2eq_kt"],

    // Copert -> GR public transportation
    'eiffel:copert_roads_regional_public_transportation': ["int_name", "CO2eq_kt"],
    'eiffel:copert_roads_local_public_transportation': ["int_name", "CO2eq_kt"],
  };


  export default KEYMAPFEATURES;