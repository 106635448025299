export const LEGENDS = [
    // Start of Solar -> Local Scenario
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_10_savings",
        style: "photovoltaic_stepwise_approach_local_10_savings",
        legend: [
            {
                id: 1,
                value: "0",
                color: "grey",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 5",
                color: "#EAF7E5",
                dsc: ""
            },
            {
                id: 3,
                value: "5 - 10",
                color: "#D5EFCF",
                dsc: ""
            },
            {
                id: 4,
                value: "10 - 25",
                color: "#BCE4B5",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 50",
                color: "#9ED898",
                dsc: ""
            },
            {
                id: 6,
                value: "50 - 75",
                color: "#7BC87C",
                dsc: ""
            },
            {
                id: 7,
                value: "75 - 100",
                color: "#54B567",
                dsc: ""
            },
            {
                id: 8,
                value: "100 - 150",
                color: "#359F54",
                dsc: ""
            },
            {
                id: 9,
                value: "150 - 200",
                color: "#359F54",
                dsc: ""
            },
            {
                id: 10,
                value: "200 - 250",
                color: "#006D2C",
                dsc: ""
            },
            {
                id: 11,
                value: "250 - 350",
                color: "#00441B",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_10_energy_production",
        style: "photovoltaic_stepwise_approach_local_10_energy_production",
        legend: [
            {
                id: 1,
                value: "0",
                color: "grey",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#FFF5F0",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 25",
                color: "#FFE3D7",
                dsc: ""
            },
            {
                id: 4,
                value: "25 - 50",
                color: "#FDC6AF",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 100",
                color: "#FCA487",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 150",
                color: "#FC8161",
                dsc: ""
            },
            {
                id: 7,
                value: "150 - 250",
                color: "#F85D42",
                dsc: ""
            },
            {
                id: 8,
                value: "250 - 350",
                color: "#EB362A",
                dsc: ""
            },
            {
                id: 9,
                value: "350 - 500",
                color: "#CC181D",
                dsc: ""
            },
            {
                id: 10,
                value: "500 - 700",
                color: "#A90F15",
                dsc: ""
            },
            {
                id: 11,
                value: ">700",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_10_adequacy",
        style: "photovoltaic_stepwise_approach_local_10_adequacy",
        legend: [
            {
                id: 1,
                value: "0",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#F0F9E8",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#DAF1D6",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 30",
                color: "#C5E8C5",
                dsc: ""
            },
            {
                id: 5,
                value: "30 - 40",
                color: "#ADDFBE",
                dsc: ""
            },
            {
                id: 6,
                value: "40 - 50",
                color: "#94D6C1",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 60",
                color: "#7BCCC4",
                dsc: ""
            },
            {
                id: 8,
                value: "60 - 70",
                color: "#65BBC6",
                dsc: ""
            },
            {
                id: 9,
                value: "70 - 80",
                color: "#4EAAC9",
                dsc: ""
            },
            {
                id: 10,
                value: "80 - 90",
                color: "#3796C4",
                dsc: ""
            },
            {
                id: 11,
                value: "90 - 100",
                color: "#207FB8",
                dsc: ""
            },
            {
                id: 12,
                value: ">100",
                color: "#0868AC",
                dsc: ""
            }
        ]
    },

    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_40_savings",
        style: "photovoltaic_stepwise_approach_local_40_savings",
        legend: [
            {
                id: 1,
                value: "0",
                color: "grey",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 5",
                color: "#EAF7E5",
                dsc: ""
            },
            {
                id: 3,
                value: "5 - 10",
                color: "#D5EFCF",
                dsc: ""
            },
            {
                id: 4,
                value: "10 - 25",
                color: "#BCE4B5",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 50",
                color: "#9ED898",
                dsc: ""
            },
            {
                id: 6,
                value: "50 - 75",
                color: "#7BC87C",
                dsc: ""
            },
            {
                id: 7,
                value: "75 - 100",
                color: "#54B567",
                dsc: ""
            },
            {
                id: 8,
                value: "100 - 150",
                color: "#359F54",
                dsc: ""
            },
            {
                id: 9,
                value: "150 - 200",
                color: "#359F54",
                dsc: ""
            },
            {
                id: 10,
                value: "200 - 250",
                color: "#006D2C",
                dsc: ""
            },
            {
                id: 11,
                value: "250 - 350",
                color: "#00441B",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_40_energy_production",
        style: "photovoltaic_stepwise_approach_local_40_energy_production",
        legend: [
            {
                id: 1,
                value: "0",
                color: "grey",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#FFF5F0",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 25",
                color: "#FFE3D7",
                dsc: ""
            },
            {
                id: 4,
                value: "25 - 50",
                color: "#FDC6AF",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 100",
                color: "#FCA487",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 150",
                color: "#FC8161",
                dsc: ""
            },
            {
                id: 7,
                value: "150 - 250",
                color: "#F85D42",
                dsc: ""
            },
            {
                id: 8,
                value: "250 - 350",
                color: "#EB362A",
                dsc: ""
            },
            {
                id: 9,
                value: "350 - 500",
                color: "#CC181D",
                dsc: ""
            },
            {
                id: 10,
                value: "500 - 700",
                color: "#A90F15",
                dsc: ""
            },
            {
                id: 11,
                value: ">700",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_40_adequacy",
        style: "photovoltaic_stepwise_approach_local_40_adequacy",
        legend: [
            {
                id: 1,
                value: "0",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#F0F9E8",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#DAF1D6",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 30",
                color: "#C5E8C5",
                dsc: ""
            },
            {
                id: 5,
                value: "30 - 40",
                color: "#ADDFBE",
                dsc: ""
            },
            {
                id: 6,
                value: "40 - 50",
                color: "#94D6C1",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 60",
                color: "#7BCCC4",
                dsc: ""
            },
            {
                id: 8,
                value: "60 - 70",
                color: "#65BBC6",
                dsc: ""
            },
            {
                id: 9,
                value: "70 - 80",
                color: "#4EAAC9",
                dsc: ""
            },
            {
                id: 10,
                value: "80 - 90",
                color: "#3796C4",
                dsc: ""
            },
            {
                id: 11,
                value: "90 - 100",
                color: "#207FB8",
                dsc: ""
            },
            {
                id: 12,
                value: ">100",
                color: "#0868AC",
                dsc: ""
            }
        ]
    },

    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_70_savings",
        style: "photovoltaic_stepwise_approach_local_70_savings",
        legend: [
            {
                id: 1,
                value: "0",
                color: "grey",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 5",
                color: "#EAF7E5",
                dsc: ""
            },
            {
                id: 3,
                value: "5 - 10",
                color: "#D5EFCF",
                dsc: ""
            },
            {
                id: 4,
                value: "10 - 25",
                color: "#BCE4B5",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 50",
                color: "#9ED898",
                dsc: ""
            },
            {
                id: 6,
                value: "50 - 75",
                color: "#7BC87C",
                dsc: ""
            },
            {
                id: 7,
                value: "75 - 100",
                color: "#54B567",
                dsc: ""
            },
            {
                id: 8,
                value: "100 - 150",
                color: "#359F54",
                dsc: ""
            },
            {
                id: 9,
                value: "150 - 200",
                color: "#359F54",
                dsc: ""
            },
            {
                id: 10,
                value: "200 - 250",
                color: "#006D2C",
                dsc: ""
            },
            {
                id: 11,
                value: "250 - 350",
                color: "#00441B",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_70_energy_production",
        style: "photovoltaic_stepwise_approach_local_70_energy_production",
        legend: [
            {
                id: 1,
                value: "0",
                color: "grey",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#FFF5F0",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 25",
                color: "#FFE3D7",
                dsc: ""
            },
            {
                id: 4,
                value: "25 - 50",
                color: "#FDC6AF",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 100",
                color: "#FCA487",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 150",
                color: "#FC8161",
                dsc: ""
            },
            {
                id: 7,
                value: "150 - 250",
                color: "#F85D42",
                dsc: ""
            },
            {
                id: 8,
                value: "250 - 350",
                color: "#EB362A",
                dsc: ""
            },
            {
                id: 9,
                value: "350 - 500",
                color: "#CC181D",
                dsc: ""
            },
            {
                id: 10,
                value: "500 - 700",
                color: "#A90F15",
                dsc: ""
            },
            {
                id: 11,
                value: ">700",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_70_adequacy",
        style: "photovoltaic_stepwise_approach_local_70_adequacy",
        legend: [
            {
                id: 1,
                value: "0",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#F0F9E8",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#DAF1D6",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 30",
                color: "#C5E8C5",
                dsc: ""
            },
            {
                id: 5,
                value: "30 - 40",
                color: "#ADDFBE",
                dsc: ""
            },
            {
                id: 6,
                value: "40 - 50",
                color: "#94D6C1",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 60",
                color: "#7BCCC4",
                dsc: ""
            },
            {
                id: 8,
                value: "60 - 70",
                color: "#65BBC6",
                dsc: ""
            },
            {
                id: 9,
                value: "70 - 80",
                color: "#4EAAC9",
                dsc: ""
            },
            {
                id: 10,
                value: "80 - 90",
                color: "#3796C4",
                dsc: ""
            },
            {
                id: 11,
                value: "90 - 100",
                color: "#207FB8",
                dsc: ""
            },
            {
                id: 12,
                value: ">100",
                color: "#0868AC",
                dsc: ""
            }
        ]
    },
    // End of Solar Local Scenario

    // Start of Solar Regional Scenario
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_10_savings",
        style: "photovoltaic_stepwise_approach_regional_10_savings",
        legend: [
            {
                id: 2,
                value: "0 - 5",
                color: "#F7FCF5",
                dsc: ""
            },
            {
                id: 3,
                value: "5 - 10",
                color: "#E8F6E3",
                dsc: ""
            },
            {
                id: 4,
                value: "10 - 20",
                color: "#D0EDCA",
                dsc: ""
            },
            {
                id: 5,
                value: "20 - 30",
                color: "#B2E0AB",
                dsc: ""
            },
            {
                id: 6,
                value: "30 - 50",
                color: "#8ED18C",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 75",
                color: "#66BD6F",
                dsc: ""
            },
            {
                id: 8,
                value: "75 - 100",
                color: "#3DA75A",
                dsc: ""
            },
            {
                id: 9,
                value: "100 - 150",
                color: "#238C45",
                dsc: ""
            },
            {
                id: 10,
                value: "150 - 200",
                color: "#03702E",
                dsc: ""
            },
            {
                id: 11,
                value: "200 - 250",
                color: "#00441B",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_10_energy_production",
        style: "photovoltaic_stepwise_approach_regional_10_energy_production",
        legend: [
            {
                id: 2,
                value: "0 - 10",
                color: "#FFF5F0",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#FFE3D7",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 35",
                color: "#FDC6AF",
                dsc: ""
            },
            {
                id: 5,
                value: "35 - 50",
                color: "#FCA487",
                dsc: ""
            },
            {
                id: 6,
                value: "50 - 100",
                color: "#FC8161",
                dsc: ""
            },
            {
                id: 7,
                value: "100 - 150",
                color: "#F85D42",
                dsc: ""
            },
            {
                id: 8,
                value: "150 - 200",
                color: "#EB362A",
                dsc: ""
            },
            {
                id: 9,
                value: "200 - 300",
                color: "#CC181D",
                dsc: ""
            },
            {
                id: 10,
                value: "300 - 500",
                color: "#A90F15",
                dsc: ""
            },
            {
                id: 11,
                value: "500 - 700",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_10_adequacy",
        style: "photovoltaic_stepwise_approach_regional_10_adequacy",
        legend: [
            {
                id: 1,
                value: "0",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#F0F9E8",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#DAF1D6",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 30",
                color: "#C5E8C5",
                dsc: ""
            },
            {
                id: 5,
                value: "30 - 40",
                color: "#ADDFBE",
                dsc: ""
            },
            {
                id: 6,
                value: "40 - 50",
                color: "#94D6C1",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 60",
                color: "#7BCCC4",
                dsc: ""
            },
            {
                id: 8,
                value: "60 - 70",
                color: "#65BBC6",
                dsc: ""
            },
            {
                id: 9,
                value: "70 - 80",
                color: "#4EAAC9",
                dsc: ""
            },
            {
                id: 10,
                value: "80 - 90",
                color: "#3796C4",
                dsc: ""
            },
            {
                id: 11,
                value: "90 - 100",
                color: "#207FB8",
                dsc: ""
            },
            {
                id: 12,
                value: ">100",
                color: "#0868AC",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_40_savings",
        style: "photovoltaic_stepwise_approach_regional_40_savings",
        legend: [
            {
                id: 2,
                value: "0 - 5",
                color: "#F7FCF5",
                dsc: ""
            },
            {
                id: 3,
                value: "5 - 10",
                color: "#E8F6E3",
                dsc: ""
            },
            {
                id: 4,
                value: "10 - 20",
                color: "#D0EDCA",
                dsc: ""
            },
            {
                id: 5,
                value: "20 - 30",
                color: "#B2E0AB",
                dsc: ""
            },
            {
                id: 6,
                value: "30 - 50",
                color: "#8ED18C",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 75",
                color: "#66BD6F",
                dsc: ""
            },
            {
                id: 8,
                value: "75 - 100",
                color: "#3DA75A",
                dsc: ""
            },
            {
                id: 9,
                value: "100 - 150",
                color: "#238C45",
                dsc: ""
            },
            {
                id: 10,
                value: "150 - 200",
                color: "#03702E",
                dsc: ""
            },
            {
                id: 11,
                value: "200 - 250",
                color: "#00441B",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_40_energy_production",
        style: "photovoltaic_stepwise_approach_regional_40_energy_production",
        legend: [
            {
                id: 2,
                value: "0 - 10",
                color: "#FFF5F0",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#FFE3D7",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 35",
                color: "#FDC6AF",
                dsc: ""
            },
            {
                id: 5,
                value: "35 - 50",
                color: "#FCA487",
                dsc: ""
            },
            {
                id: 6,
                value: "50 - 100",
                color: "#FC8161",
                dsc: ""
            },
            {
                id: 7,
                value: "100 - 150",
                color: "#F85D42",
                dsc: ""
            },
            {
                id: 8,
                value: "150 - 200",
                color: "#EB362A",
                dsc: ""
            },
            {
                id: 9,
                value: "200 - 300",
                color: "#CC181D",
                dsc: ""
            },
            {
                id: 10,
                value: "300 - 500",
                color: "#A90F15",
                dsc: ""
            },
            {
                id: 11,
                value: "500 - 700",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_40_adequacy",
        style: "photovoltaic_stepwise_approach_regional_40_adequacy",
        legend: [
            {
                id: 1,
                value: "0",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#F0F9E8",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#DAF1D6",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 30",
                color: "#C5E8C5",
                dsc: ""
            },
            {
                id: 5,
                value: "30 - 40",
                color: "#ADDFBE",
                dsc: ""
            },
            {
                id: 6,
                value: "40 - 50",
                color: "#94D6C1",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 60",
                color: "#7BCCC4",
                dsc: ""
            },
            {
                id: 8,
                value: "60 - 70",
                color: "#65BBC6",
                dsc: ""
            },
            {
                id: 9,
                value: "70 - 80",
                color: "#4EAAC9",
                dsc: ""
            },
            {
                id: 10,
                value: "80 - 90",
                color: "#3796C4",
                dsc: ""
            },
            {
                id: 11,
                value: "90 - 100",
                color: "#207FB8",
                dsc: ""
            },
            {
                id: 12,
                value: ">100",
                color: "#0868AC",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_70_savings",
        style: "photovoltaic_stepwise_approach_regional_70_savings",
        legend: [
            {
                id: 2,
                value: "0 - 5",
                color: "#F7FCF5",
                dsc: ""
            },
            {
                id: 3,
                value: "5 - 10",
                color: "#E8F6E3",
                dsc: ""
            },
            {
                id: 4,
                value: "10 - 20",
                color: "#D0EDCA",
                dsc: ""
            },
            {
                id: 5,
                value: "20 - 30",
                color: "#B2E0AB",
                dsc: ""
            },
            {
                id: 6,
                value: "30 - 50",
                color: "#8ED18C",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 75",
                color: "#66BD6F",
                dsc: ""
            },
            {
                id: 8,
                value: "75 - 100",
                color: "#3DA75A",
                dsc: ""
            },
            {
                id: 9,
                value: "100 - 150",
                color: "#238C45",
                dsc: ""
            },
            {
                id: 10,
                value: "150 - 200",
                color: "#03702E",
                dsc: ""
            },
            {
                id: 11,
                value: "200 - 250",
                color: "#00441B",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_70_energy_production",
        style: "photovoltaic_stepwise_approach_regional_70_energy_production",
        legend: [
            {
                id: 2,
                value: "0 - 10",
                color: "#FFF5F0",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#FFE3D7",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 35",
                color: "#FDC6AF",
                dsc: ""
            },
            {
                id: 5,
                value: "35 - 50",
                color: "#FCA487",
                dsc: ""
            },
            {
                id: 6,
                value: "50 - 100",
                color: "#FC8161",
                dsc: ""
            },
            {
                id: 7,
                value: "100 - 150",
                color: "#F85D42",
                dsc: ""
            },
            {
                id: 8,
                value: "150 - 200",
                color: "#EB362A",
                dsc: ""
            },
            {
                id: 9,
                value: "200 - 300",
                color: "#CC181D",
                dsc: ""
            },
            {
                id: 10,
                value: "300 - 500",
                color: "#A90F15",
                dsc: ""
            },
            {
                id: 11,
                value: "500 - 700",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_70_adequacy",
        style: "photovoltaic_stepwise_approach_regional_70_adequacy",
        legend: [
            {
                id: 1,
                value: "0",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#F0F9E8",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#DAF1D6",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 30",
                color: "#C5E8C5",
                dsc: ""
            },
            {
                id: 5,
                value: "30 - 40",
                color: "#ADDFBE",
                dsc: ""
            },
            {
                id: 6,
                value: "40 - 50",
                color: "#94D6C1",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 60",
                color: "#7BCCC4",
                dsc: ""
            },
            {
                id: 8,
                value: "60 - 70",
                color: "#65BBC6",
                dsc: ""
            },
            {
                id: 9,
                value: "70 - 80",
                color: "#4EAAC9",
                dsc: ""
            },
            {
                id: 10,
                value: "80 - 90",
                color: "#3796C4",
                dsc: ""
            },
            {
                id: 11,
                value: "90 - 100",
                color: "#207FB8",
                dsc: ""
            },
            {
                id: 12,
                value: ">100",
                color: "#0868AC",
                dsc: ""
            }
        ]
    },

    // End of Solar -> Regional -> Scenario

    // Start of Solar -> Baseline -> Regional
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_baseline_savings",
        style: "photovoltaic_stepwise_approach_regional_baseline_savings",
        legend: [
            {
                id: 1,
                value: "0 - 5",
                color: "#F7FCF5",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#E8F6E3",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#D0EDCA",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 30",
                color: "#B2E0AB",
                dsc: ""
            },
            {
                id: 5,
                value: "30 - 50",
                color: "#8ED18C",
                dsc: ""
            },
            {
                id: 6,
                value: "50 - 75",
                color: "#66BD6F",
                dsc: ""
            },
            {
                id: 7,
                value: "75 - 100",
                color: "#3DA75A",
                dsc: ""
            },
            {
                id: 8,
                value: "100 - 150",
                color: "#238C45",
                dsc: ""
            },
            {
                id: 9,
                value: "150 - 200",
                color: "#03702E",
                dsc: ""
            },
            {
                id: 10,
                value: "200 - 250",
                color: "#00441B",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_baseline_energy_production",
        style: "photovoltaic_stepwise_approach_regional_baseline_energy_production",
        legend: [
            {
                id: 1,
                value: "0 - 10",
                color: "#FFF5F0",
                dsc: ""
            },
            {
                id: 2,
                value: "10 -20",
                color: "#FFE3D7",
                dsc: ""
            },
            {
                id: 3,
                value: "20 - 35",
                color: "#FDC6AF",
                dsc: ""
            },
            {
                id: 4,
                value: "35 - 50",
                color: "#FCA487",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 100",
                color: "#FC8161",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 150",
                color: "#F85D42",
                dsc: ""
            },
            {
                id: 7,
                value: "150 - 200",
                color: "#EB362A",
                dsc: ""
            },
            {
                id: 8,
                value: "200 - 300",
                color: "#CC181D",
                dsc: ""
            },
            {
                id: 9,
                value: "300 - 500",
                color: "#A90F15",
                dsc: ""
            },
            {
                id: 10,
                value: "500 - 700",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_regional_baseline_adequacy",
        style: "photovoltaic_stepwise_approach_regional_baseline_adequacy",
        legend: [
            {
                id: 1,
                value: "0",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#F0F9E8",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#DAF1D6",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 30",
                color: "#C5E8C5",
                dsc: ""
            },
            {
                id: 5,
                value: "30 - 40",
                color: "#ADDFBE",
                dsc: ""
            },
            {
                id: 6,
                value: "40 - 50",
                color: "#94D6C1",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 60",
                color: "#7BCCC4",
                dsc: ""
            },
            {
                id: 8,
                value: "60 - 70",
                color: "#65BBC6",
                dsc: ""
            },
            {
                id: 9,
                value: "70 - 80",
                color: "#4EAAC9",
                dsc: ""
            },
            {
                id: 10,
                value: "80 - 90",
                color: "#3796C4",
                dsc: ""
            },
            {
                id: 11,
                value: "90 - 100",
                color: "#207FB8",
                dsc: ""
            },
            {
                id: 12,
                value: ">100",
                color: "#0868AC",
                dsc: ""
            }
        ]
    },
    // End of Solar -> BaseLine -> Regional

    // Start of Solar -> Baseline -> Local
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_baseline_savings",
        style: "photovoltaic_stepwise_approach_local_baseline_savings",
        legend: [
            {
                id: 1,
                value: "0 - 5",
                color: "#EAF7E5",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#D5EFCF",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 25",
                color: "#BCE4B5",
                dsc: ""
            },
            {
                id: 4,
                value: "25 - 50",
                color: "#9ED898",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 75",
                color: "#7BC87C",
                dsc: ""
            },
            {
                id: 6,
                value: "75 - 100",
                color: "#54B567",
                dsc: ""
            },
            {
                id: 7,
                value: "100 - 150",
                color: "#359F54",
                dsc: ""
            },
            {
                id: 8,
                value: "150 - 200",
                color: "#1D8641",
                dsc: ""
            },
            {
                id: 9,
                value: "200 - 250",
                color: "#006D2C",
                dsc: ""
            },
            {
                id: 10,
                value: "250 - 350",
                color: "#00441B",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_baseline_energy_production",
        style: "photovoltaic_stepwise_approach_local_baseline_energy_production",
        legend: [
            {
                id: 1,
                value: "0",
                color: "grey",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#FFF5F0",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 25",
                color: "#FFE3D7",
                dsc: ""
            },
            {
                id: 4,
                value: "25 - 50",
                color: "#FDC6AF",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 100",
                color: "#FCA487",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 150",
                color: "#FC8161",
                dsc: ""
            },
            {
                id: 7,
                value: "150 - 250",
                color: "#F85D42",
                dsc: ""
            },
            {
                id: 8,
                value: "250 - 350",
                color: "#EB362A",
                dsc: ""
            },
            {
                id: 9,
                value: "350 - 500",
                color: "#CC181D",
                dsc: ""
            },
            {
                id: 10,
                value: "500 - 700",
                color: "#A90F15",
                dsc: ""
            },
            {
                id: 11,
                value: ">700",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:photovoltaic_stepwise_approach_local_baseline_adequacy",
        style: "photovoltaic_stepwise_approach_local_baseline_adequacy",
        legend: [
            {
                id: 1,
                value: "0",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "0 - 10",
                color: "#F0F9E8",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 20",
                color: "#DAF1D6",
                dsc: ""
            },
            {
                id: 4,
                value: "20 - 30",
                color: "#C5E8C5",
                dsc: ""
            },
            {
                id: 5,
                value: "30 - 40",
                color: "#ADDFBE",
                dsc: ""
            },
            {
                id: 6,
                value: "40 - 50",
                color: "#94D6C1",
                dsc: ""
            },
            {
                id: 7,
                value: "50 - 60",
                color: "#7BCCC4",
                dsc: ""
            },
            {
                id: 8,
                value: "60 - 70",
                color: "#65BBC6",
                dsc: ""
            },
            {
                id: 9,
                value: "70 - 80",
                color: "#4EAAC9",
                dsc: ""
            },
            {
                id: 10,
                value: "80 - 90",
                color: "#3796C4",
                dsc: ""
            },
            {
                id: 11,
                value: "90 - 100",
                color: "#207FB8",
                dsc: ""
            },
            {
                id: 12,
                value: ">100",
                color: "#0868AC",
                dsc: ""
            }
        ]
    },
    // End of Solar -> Baseline -> Local

    // Start of AQ -> BaseLine -> Regional -> Summer & Winter
    {
        layer: "eiffel:baseline_regional_summer_o3",
        style: "baseline_january_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_regional_winter_o3",
        style: "baseline_january_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_regional_winter_no2",
        style: "baseline_january_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_regional_summer_no2",
        style: "baseline_january_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_regional_winter_pm25",
        style: "baseline_january_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_regional_summer_pm25",
        style: "baseline_january_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    // End of AQ -> BaseLine -> Regional -> Summer & Winter

    // Start of AQ -> BaseLine -> Local -> Summer & Winter
    {
        layer: "eiffel:baseline_local_summer_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_local_summer_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_local_summer_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_local_winter_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_local_winter_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:baseline_local_winter_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    // End of AQ -> BaseLine -> Local -> Summer & Winter

    // Start of AQ -> Electrification of vehicles -> Regional -> GR BAU
    {
        layer: "eiffel:aq_electrif_of_vehicles_regional_gr_bau_no2",
        style: "aq_electrif_of_vehicles_regional_gr_bau_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_electrif_of_vehicles_regional_gr_bau_o3",
        style: "aq_electrif_of_vehicles_regional_gr_bau_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_electrif_of_vehicles_regional_gr_bau_pm25",
        style: "aq_electrif_of_vehicles_regional_gr_bau_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Electrification of Vehicles -> Regional -> GR BAU

    // Start of AQ -> Electrification of vehicles -> Local -> GR BAU
    {
        layer: "eiffel:aq_electrif_of_vehicles_local_gr_bau_no2",
        style: "aq_electrif_of_vehicles_local_gr_bau_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_electrif_of_vehicles_local_gr_bau_o3",
        style: "aq_electrif_of_vehicles_local_gr_bau_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_electrif_of_vehicles_local_gr_bau_pm25",
        style: "aq_electrif_of_vehicles_local_gr_bau_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#9540390",
                dsc: ""
            },
        ],
    },
    // End of AQ -> Electrification of vehicles -> Local -> GR BAU

    // Start of AQ -> Electrification of vehicles -> Regional -> GR Focused
    {
        layer: "eiffel:aq_electrif_of_vehicles_regional_gr_focused_no2",
        style: "aq_electrif_of_vehicles_regional_gr_focused_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_electrif_of_vehicles_regional_gr_focused_o3",
        style: "aq_electrif_of_vehicles_regional_gr_focused_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_electrif_of_vehicles_regional_gr_focused_pm25",
        style: "aq_electrif_of_vehicles_regional_gr_focused_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Electrification of vehicles -> Regional -> GR Focused

    // Start of AQ -> Electrification of vehicles -> Local -> GR Focused
    {
        layer: "eiffel:aq_electrif_of_vehicles_local_gr_focused_no2",
        style: "aq_electrif_of_vehicles_local_gr_focused_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_electrif_of_vehicles_local_gr_focused_o3",
        style: "aq_electrif_of_vehicles_local_gr_focused_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_electrif_of_vehicles_local_gr_focused_pm25",
        style: "aq_electrif_of_vehicles_local_gr_focused_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#9540390",
                dsc: ""
            },
        ],
    },
    // End of AQ -> Electrification of vehicles -> Local -> GR Focused

    // Start of AQ -> Combined electrification interventions -> Regional -> 30 GR Focused
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_focused_no2",
        style: "aq_comb_electrif_invert_regional_30_gr_focused_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_focused_o3",
        style: "aq_comb_electrif_invert_regional_30_gr_focused_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_focused_pm25",
        style: "aq_comb_electrif_invert_regional_30_gr_focused_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Combined electrification interventions -> Regional -> 30 GR Focused

    // Start of AQ -> Combined electrification interventions -> Local -> 30 GR Focused
    {
        layer: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_no2",
        style: "aq_comb_electrif_invert_local_30_gr_focused_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_o3",
        style: "aq_comb_electrif_invert_local_30_gr_focused_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_pm25",
        style: "aq_comb_electrif_invert_local_30_gr_focused_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#9540390",
                dsc: ""
            },
        ],
    },
    // End of AQ -> Combined electrification interventions -> Local -> 30 GR Focused

    // Start of AQ -> Combined electrification interventions -> Regional -> 30 GR Bau
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_bau_no2",
        style: "aq_comb_electrif_invert_regional_30_gr_bau_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_bau_o3",
        style: "aq_comb_electrif_invert_regional_30_gr_bau_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_30_gr_bau_pm25",
        style: "aq_comb_electrif_invert_regional_30_gr_bau_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Combined electrification interventions -> Regional -> 30 GR Bau

    // Start of AQ -> Combined electrification interventions -> Local -> 30 GR Bau
    {
        layer: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_no2",
        style: "aq_comb_electrif_invert_local_30_gr_bau_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_o3",
        style: "aq_comb_electrif_invert_local_30_gr_bau_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_pm25",
        style: "aq_comb_electrif_invert_local_30_gr_bau_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#9540390",
                dsc: ""
            },
        ],
    },
    // End of AQ -> Combined electrification interventions -> Local -> 30 GR Bau

    // Start of AQ -> Combined electrification interventions -> Regional -> 60 GR Optimistic
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_no2",
        style: "aq_comb_electrif_invert_regional_60_gr_optimistic_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_o3",
        style: "aq_comb_electrif_invert_regional_60_gr_optimistic_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_pm25",
        style: "aq_comb_electrif_invert_regional_60_gr_optimistic_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Combined electrification interventions -> Regional -> 60 GR Optimistic

    // Start of AQ -> Combined electrification interventions -> Local -> 60 GR Optimistic
    {
        layer: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_no2",
        style: "aq_comb_electrif_invert_local_60_gr_optimistic_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_o3",
        style: "aq_comb_electrif_invert_local_60_gr_optimistic_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_pm25",
        style: "aq_comb_electrif_invert_local_60_gr_optimistic_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#9540390",
                dsc: ""
            },
        ],
    },
    // End of AQ -> Combined electrification interventions -> Local -> 60 GR Optimistic

    // Start of AQ -> Combined electrification interventions -> Regional -> 60 GR Base
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_base_no2",
        style: "aq_comb_electrif_invert_regional_60_gr_base_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_base_o3",
        style: "aq_comb_electrif_invert_regional_60_gr_base_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_60_gr_base_pm25",
        style: "aq_comb_electrif_invert_regional_60_gr_base_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Combined electrification interventions -> Regional -> 60 GR Base

    // Start of AQ -> Combined electrification interventions -> Local -> 60 GR Base
    {
        layer: "eiffel:aq_comb_electrif_invert_local_60_gr_base_no2",
        style: "aq_comb_electrif_invert_local_60_gr_base_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_60_gr_base_o3",
        style: "aq_comb_electrif_invert_local_60_gr_base_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_60_gr_base_pm25",
        style: "aq_comb_electrif_invert_local_60_gr_base_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#9540390",
                dsc: ""
            },
        ],
    },
    // End of AQ -> Combined electrification interventions -> Local -> 60 GR Base
    
    // Start of AQ -> Combined electrification interventions -> Regional -> 100 GR Optimistic
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_no2",
        style: "aq_comb_electrif_invert_regional_100_gr_optimistic_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_o3",
        style: "aq_comb_electrif_invert_regional_100_gr_optimistic_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_pm25",
        style: "aq_comb_electrif_invert_regional_100_gr_optimistic_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Combined electrification interventions -> Regional -> 100 GR Optimistic

    // Start of AQ -> Combined electrification interventions -> Local -> 100 GR Optimistic
    {
        layer: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_no2",
        style: "aq_comb_electrif_invert_local_100_gr_optimistic_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_o3",
        style: "aq_comb_electrif_invert_local_100_gr_optimistic_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_pm25",
        style: "aq_comb_electrif_invert_local_100_gr_optimistic_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Combined electrification interventions -> Local -> 100 GR Optimistic
    
    // Start of AQ -> Combined electrification interventions -> Regional -> 100 GR Focused
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_focused_no2",
        style: "aq_comb_electrif_invert_regional_100_gr_focused_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_focused_o3",
        style: "aq_comb_electrif_invert_regional_100_gr_focused_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_regional_100_gr_focused_pm25",
        style: "aq_comb_electrif_invert_regional_100_gr_focused_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Combined electrification interventions -> Regional -> 100 GR Focused

    // Start of AQ -> Combined electrification interventions -> Local -> 100 GR Focused
    {
        layer: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_no2",
        style: "aq_comb_electrif_invert_local_100_gr_focused_no2",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_o3",
        style: "aq_comb_electrif_invert_local_100_gr_focused_o3",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_pm25",
        style: "aq_comb_electrif_invert_local_100_gr_focused_pm25",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Combined electrification interventions -> Local -> 100 GR Focused
    
    // Start of AQ -> Scenario Electification of Buldings (30%) -> Regional -> Winter
    {
        layer: "air_quality_electification_of_buildings_regional_winter_30_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "air_quality_electification_of_buildings_regional_winter_30_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "air_quality_electification_of_buildings_regional_winter_30_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Scenario Electification of Buildings (30%) -> Regional -> Winter

    // Start of AQ -> Scenario Electification of Buildings (30%) -> Local -> Winter
    {
        layer: "air_quality_electification_of_buildings_local_winter_30_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "air_quality_electification_of_buildings_local_winter_30_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "air_quality_electification_of_buildings_local_winter_30_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    // End of AQ -> Scenario Electification of Buildings (30%) -> Local -> Winter

    // Start of AQ -> Scenario One -> Regional -> Winter
    {
        layer: "eiffel:scenario_1_regional_winter_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:scenario_1_regional_winter_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:scenario_1_regional_winter_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Scenario One -> Regional -> Winter

    // Start of AQ -> Scenario Two -> Regional -> Winter
    {
        layer: "eiffel:vehicles_regional_winter_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:vehicles_regional_winter_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:vehicles_regional_winter_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ]
    },
    // End of AQ -> Scenario Two -> Regional -> Winter

    // Start of AQ -> Scenario One -> Local -> Winter
    {
        layer: "eiffel:scenario_1_local_winter_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:scenario_1_local_winter_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            },
        ],
    },
    {
        layer: "eiffel:scenario_1_local_winter_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#9540390",
                dsc: ""
            },
        ],
    },
    // End of AQ -> Scenario One -> Local -> Winter

    // Start of AQ -> Scenario Two -> Local -> Winter
    {
        layer: "eiffel:vehicles_local_winter_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:vehicles_local_winter_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:vehicles_local_winter_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    // End of AQ -> Scenario Two -> Local -> Winter

    // Start of AQ -> Scenario Three -> Regional -> Winter
    {
        layer: "eiffel:buildings_vehicles_regional_winter_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:buildings_vehicles_regional_winter_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:buildings_vehicles_regional_winter_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    // End of AQ -> Scenario Three -> Regional -> Winter

    // Start of AQ -> Scenario Three -> Local -> Winter
    {
        layer: "eiffel:buildings_vehicles_local_winter_no2",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:buildings_vehicles_local_winter_o3",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=40",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "40 - 45",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "45 - 50",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 85",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">85",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:buildings_vehicles_local_winter_pm25",
        style: "",
        legend: [
            {
                id: 1,
                value: "<=5",
                color: "#5D99C3",
                dsc: ""
            },
            {
                id: 2,
                value: "5 - 10",
                color: "#84BB4A",
                dsc: ""
            },
            {
                id: 3,
                value: "10 - 15",
                color: "#E3CC3E",
                dsc: ""
            },
            {
                id: 4,
                value: "15 - 25",
                color: "#D5702C",
                dsc: ""
            },
            {
                id: 5,
                value: "25 - 35",
                color: "#E50000",
                dsc: ""
            },
            {
                id: 6,
                value: "35 - 50",
                color: "#925BBC",
                dsc: ""
            },
            {
                id: 7,
                value: ">50",
                color: "#954039",
                dsc: ""
            }
        ]
    },
    // End of AQ -> Scenario Three -> Local -> Winter

    // Start of Buildings -> Baseline -> Regional
    {
        layer: "eiffel:building_regional_baseline_emissions",
        style: "building_regional_baseline_emissions",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 30",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#979797",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#878787",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#767676",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#666666",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#565656",
                dsc: ""
            },
            {
                id: 9,
                value: ">90",
                color: "#464646",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_baseline_energy_final",
        style: "building_regional_baseline_energy_final",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 40",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 60",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 4,
                value: "60 - 80",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 5,
                value: "80 - 100",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 120",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 7,
                value: "120 - 140",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 8,
                value: "140 - 160",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 9,
                value: ">160",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_baseline_performance_energy",
        style: "building_regional_baseline_performance_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    // End of Buildings -> Baseline -> Regional

    // Start of Buldings -> BaseLine -> Local
    {
        layer: "eiffel:building_local_baseline_emissions",
        style: "buildings_local_baseline_emissions",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<30",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#979797",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#878787",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#767676",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#666666",
                dsc: ""
            },
            {
                id: 9,
                value: "90 - 100",
                color: "#565656",
                dsc: ""
            },
            {
                id: 10,
                value: ">100",
                color: "#464646",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_baseline_energy_final",
        style: "buildings_local_baseline_energy_final",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<60",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 3,
                value: "60 - 80",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 4,
                value: "80 - 100",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 5,
                value: "100 - 120",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 6,
                value: "120 - 140",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 7,
                value: "140 - 160",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 8,
                value: "160 - 180",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 9,
                value: "180 - 200",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 10,
                value: ">200",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_baseline_performance_energy",
        style: "buildings_local_baseline_performance_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    // End of Buldings -> BaseLine -> Local

    // Start of Buildings -> Scenario -> Regional
    {
        layer: "eiffel:building_regional_scenario_elecrtif_30_ghg_emissions",
        style: "building_regional_scenario_elecrtif_30_ghg_emissions",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 30",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#979797",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#878787",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#767676",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#666666",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#565656",
                dsc: ""
            },
            {
                id: 9,
                value: ">90",
                color: "#464646",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_30_ghg_final_energy",
        style: "building_regional_scenario_elecrtif_30_ghg_final_energy",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 40",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 60",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 4,
                value: "60 - 80",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 5,
                value: "80 - 100",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 120",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 7,
                value: "120 - 140",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 8,
                value: "140 - 160",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 9,
                value: ">160",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_30_ghg_energy",
        style: "building_regional_scenario_elecrtif_30_ghg_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_30_ghg_savings",
        style: "building_regional_scenario_elecrtif_30_ghg_savings",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<40",
                color: "#B6E0A3",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 50",
                color: "#91C88B",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#6DAF72",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#499759",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 80",
                color: "#247E40",
                dsc: ""
            },
            {
                id: 7,
                value: ">80",
                color: "#006627",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_60_ghg_emissions",
        style: "building_regional_scenario_elecrtif_60_ghg_emissions",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 30",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#979797",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#878787",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#767676",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#666666",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#565656",
                dsc: ""
            },
            {
                id: 9,
                value: ">90",
                color: "#464646",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_60_ghg_final_energy",
        style: "building_regional_scenario_elecrtif_60_ghg_final_energy",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 40",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 60",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 4,
                value: "60 - 80",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 5,
                value: "80 - 100",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 120",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 7,
                value: "120 - 140",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 8,
                value: "140 - 160",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 9,
                value: ">160",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_60_ghg_energy",
        style: "building_regional_scenario_elecrtif_60_ghg_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_60_ghg_savings",
        style: "building_regional_scenario_elecrtif_60_ghg_savings",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<40",
                color: "#B6E0A3",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 50",
                color: "#91C88B",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#6DAF72",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#499759",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 80",
                color: "#247E40",
                dsc: ""
            },
            {
                id: 7,
                value: ">80",
                color: "#006627",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_100_ghg_emissions",
        style: "building_regional_scenario_elecrtif_100_ghg_emissions",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 30",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#979797",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#878787",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#767676",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#666666",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#565656",
                dsc: ""
            },
            {
                id: 9,
                value: ">90",
                color: "#464646",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_100_ghg_final_energy",
        style: "building_regional_scenario_elecrtif_100_ghg_final_energy",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 40",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 60",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 4,
                value: "60 - 80",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 5,
                value: "80 - 100",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 120",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 7,
                value: "120 - 140",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 8,
                value: "140 - 160",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 9,
                value: ">160",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_100_ghg_energy",
        style: "building_regional_scenario_elecrtif_100_ghg_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_scenario_elecrtif_100_ghg_savings",
        style: "building_regional_scenario_elecrtif_100_ghg_savings",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<40",
                color: "#B6E0A3",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 50",
                color: "#91C88B",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#6DAF72",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#499759",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 80",
                color: "#247E40",
                dsc: ""
            },
            {
                id: 7,
                value: ">80",
                color: "#006627",
                dsc: ""
            }
        ]
    },
    // end of Buildings -> Scenario -> Regional

    // Start of Buldings -> Scenario -> Local
    {
        layer: "eiffel:building_local_scenario_elecrtif_30_ghg_emissions",
        style: "buildings_local_scenario_elecrtif_30_ghg_emissions",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<30",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#979797",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#878787",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#767676",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#666666",
                dsc: ""
            },
            {
                id: 9,
                value: "90 - 100",
                color: "#565656",
                dsc: ""
            },
            {
                id: 10,
                value: ">100",
                color: "#464646",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_30_ghg_savings",
        style: "buildings_local_scenario_elecrtif_30_ghg_savings",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<40",
                color: "#B6E0A3",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 50",
                color: "#91C88B",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#6DAF72",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#499759",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 80",
                color: "#247E40",
                dsc: ""
            },
            {
                id: 7,
                value: ">80",
                color: "#006627",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_30_final_energy",
        style: "buildings_local_scenario_elecrtif_30_final_energy",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<60",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 3,
                value: "60 - 80",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 4,
                value: "80 - 100",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 5,
                value: "100 - 120",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 6,
                value: "120 - 140",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 7,
                value: "140 - 160",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 8,
                value: "160 - 180",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 9,
                value: "180 - 200",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 9,
                value: ">200",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_30_energy",
        style: "buildings_local_scenario_elecrtif_30_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_60_ghg_emissions",
        style: "buildings_local_scenario_elecrtif_60_ghg_emissions",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<30",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#979797",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#878787",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#767676",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#666666",
                dsc: ""
            },
            {
                id: 9,
                value: "90 - 100",
                color: "#565656",
                dsc: ""
            },
            {
                id: 10,
                value: ">100",
                color: "#464646",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_60_final_energy",
        style: "buildings_local_scenario_elecrtif_60_final_energy",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<60",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 3,
                value: "60 - 80",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 4,
                value: "80 - 100",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 5,
                value: "100 - 120",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 6,
                value: "120 - 140",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 7,
                value: "140 - 160",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 8,
                value: "160 - 180",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 9,
                value: "180 - 200",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 10,
                value: ">200",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_60_energy",
        style: "buildings_local_scenario_elecrtif_60_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_60_ghg_savings",
        style: "buildings_local_scenario_elecrtif_60_ghg_savings",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<40",
                color: "#B6E0A3",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 50",
                color: "#91C88B",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#6DAF72",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#499759",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 80",
                color: "#247E40",
                dsc: ""
            },
            {
                id: 7,
                value: ">80",
                color: "#006627",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_100_ghg_emissions",
        style: "buildings_local_scenario_elecrtif_100_ghg_emissions",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<30",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#979797",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#878787",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#767676",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#666666",
                dsc: ""
            },
            {
                id: 9,
                value: "90 - 100",
                color: "#565656",
                dsc: ""
            },
            {
                id: 10,
                value: ">100",
                color: "#464646",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_100_final_energy",
        style: "buildings_local_scenario_elecrtif_100_final_energy",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<60",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 3,
                value: "60 - 80",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 4,
                value: "80 - 100",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 5,
                value: "100 - 120",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 6,
                value: "120 - 140",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 7,
                value: "140 - 160",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 8,
                value: "160 - 180",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 9,
                value: "180 - 200",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 10,
                value: ">200",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_100_energy",
        style: "buildings_local_scenario_elecrtif_100_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_scenario_elecrtif_100_ghg_savings",
        style: "buildings_local_scenario_elecrtif_100_ghg_savings",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<40",
                color: "#B6E0A3",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 50",
                color: "#91C88B",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#6DAF72",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#499759",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 80",
                color: "#247E40",
                dsc: ""
            },
            {
                id: 7,
                value: ">80",
                color: "#006627",
                dsc: ""
            }
        ]
    },
    // End of Buldings -> Scenario -> Local

    // Start of Buildings -> Hybrid -> Regional
    {
        layer: "eiffel:building_regional_hybrid_ghg_emissions",
        style: "building_regional_hybrid_ghg_emissions",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 30",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#979797",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#878787",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#767676",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#666666",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#565656",
                dsc: ""
            },
            {
                id: 9,
                value: ">90",
                color: "#464646",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:building_regional_hybrid_ghg_final_energy",
        style: "building_regional_hybrid_ghg_final_energy",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 40",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 60",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 4,
                value: "60 - 80",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 5,
                value: "80 - 100",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 120",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 7,
                value: "120 - 140",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 8,
                value: "140 - 160",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 9,
                value: ">160",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_hybrid_ghg_energy",
        style: "building_regional_hybrid_ghg_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_regional_hybrid_ghg_savings",
        style: "building_regional_hybrid_ghg_savings",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<40",
                color: "#B6E0A3",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 50",
                color: "#91C88B",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#6DAF72",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#499759",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 80",
                color: "#247E40",
                dsc: ""
            },
            {
                id: 7,
                value: ">80",
                color: "#006627",
                dsc: ""
            }
        ]
    },
    // End of Buildings -> Hybrid -> Regional

    // Start of Buildings -> Hybrid -> Local
    {
        layer: "eiffel:building_local_hybrid_ghg_emissions",
        style: "building_local_hybrid_ghg_emissions",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#C7C7C7",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 30",
                color: "#B7B7B7",
                dsc: ""
            },
            {
                id: 3,
                value: "30 - 40",
                color: "#A7A7A7",
                dsc: ""
            },
            {
                id: 4,
                value: "40 - 50",
                color: "#979797",
                dsc: ""
            },
            {
                id: 5,
                value: "50 - 60",
                color: "#878787",
                dsc: ""
            },
            {
                id: 6,
                value: "60 - 70",
                color: "#767676",
                dsc: ""
            },
            {
                id: 7,
                value: "70 - 80",
                color: "#666666",
                dsc: ""
            },
            {
                id: 8,
                value: "80 - 90",
                color: "#565656",
                dsc: ""
            },
            {
                id: 9,
                value: ">90",
                color: "#464646",
                dsc: ""
            },
        ]
    },
    {
        layer: "eiffel:building_local_hybrid_ghg_final_energy",
        style: "building_local_hybrid_ghg_final_energy",
        legend: [
            {
                id: 1,
                value: "<20",
                color: "#FF8C69",
                dsc: ""
            },
            {
                id: 2,
                value: "20 - 40",
                color: "#FB7658",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 60",
                color: "#F66048",
                dsc: ""
            },
            {
                id: 4,
                value: "60 - 80",
                color: "#F24A37",
                dsc: ""
            },
            {
                id: 5,
                value: "80 - 100",
                color: "#E5372A",
                dsc: ""
            },
            {
                id: 6,
                value: "100 - 120",
                color: "#C62923",
                dsc: ""
            },
            {
                id: 7,
                value: "120 - 140",
                color: "#A61B1B",
                dsc: ""
            },
            {
                id: 8,
                value: "140 - 160",
                color: "#870E14",
                dsc: ""
            },
            {
                id: 9,
                value: ">160",
                color: "#67000D",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_hybrid_ghg_energy",
        style: "building_local_hybrid_ghg_energy",
        legend: [
            {
                id: 1,
                value: "A+",
                color: "#00FF05",
                dsc: ""
            },
            {
                id: 2,
                value: "A",
                color: "#7BFC02",
                dsc: ""
            },
            {
                id: 3,
                value: "B+",
                color: "#ADFF30",
                dsc: ""
            },
            {
                id: 4,
                value: "B",
                color: "#FFFE03",
                dsc: ""
            },
            {
                id: 5,
                value: "C",
                color: "#FEA500",
                dsc: ""
            },
            {
                id: 6,
                value: "D",
                color: "#FB7F9B",
                dsc: ""
            },
            {
                id: 7,
                value: "E",
                color: "#FE0000",
                dsc: ""
            },
            {
                id: 8,
                value: "F",
                color: "#CE5C5C",
                dsc: ""
            },
            {
                id: 9,
                value: "G",
                color: "#A72A28",
                dsc: ""
            },
            {
                id: 10,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            }
        ]
    },
    {
        layer: "eiffel:building_local_hybrid_ghg_savings",
        style: "building_local_hybrid_ghg_savings",
        legend: [
            {
                id: 1,
                value: "N/A",
                color: "#FFFFFF",
                dsc: ""
            },
            {
                id: 2,
                value: "<40",
                color: "#B6E0A3",
                dsc: ""
            },
            {
                id: 3,
                value: "40 - 50",
                color: "#91C88B",
                dsc: ""
            },
            {
                id: 4,
                value: "50 - 60",
                color: "#6DAF72",
                dsc: ""
            },
            {
                id: 5,
                value: "60 - 70",
                color: "#499759",
                dsc: ""
            },
            {
                id: 6,
                value: "70 - 80",
                color: "#247E40",
                dsc: ""
            },
            {
                id: 7,
                value: ">80",
                color: "#006627",
                dsc: ""
            }
        ]
    },
    // End of Buildings -> Hybrid -> Local

    // Start of Percentange Energy Adequacy of Schools
    {
        layer: "eiffel:photovoltaic_schools",
        style: "photovoltaic_schools",
        legend: [
            {
                id: 1,
                value: "0 - 10",
                color: "#F0F9E8",
                dsc: ""
            },
            {
                id: 2,
                value: "10 - 20",
                color: "#DAF1D6",
                dsc: ""
            },
            {
                id: 3,
                value: "20 - 30",
                color: "#C5E8C5",
                dsc: ""
            },
            {
                id: 4,
                value: "30 - 40",
                color: "#ADDFBE",
                dsc: ""
            },
            {
                id: 5,
                value: "40 - 50",
                color: "#94D6C1",
                dsc: ""
            },
            {
                id: 6,
                value: "50 - 60",
                color: "#7BCCC4",
                dsc: ""
            },
            {
                id: 7,
                value: "60 - 70",
                color: "#65BBC6",
                dsc: ""
            },
            {
                id: 8,
                value: "70 - 80",
                color: "#4EAAC9",
                dsc: ""
            },
            {
                id: 9,
                value: "80 - 90",
                color: "#3796C4",
                dsc: ""
            },
            {
                id: 10,
                value: "90 - 100",
                color: "#207FB8",
                dsc: ""
            },
            {
                id: 11,
                value: ">100",
                color: "#0868AC",
                dsc: ""
            },
        ]
    },
    // End of Percentange Energy Adequacy of Schools

    // Start of Copert Roads Local
    {
        layer: "eiffel:copert_roads_local_baseline",
        style: "copert_roads_local_baseline",
        legend: [
            {
                id: 1,
                value: "1 - 2",
                color: "#FDAE61",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "2 - 3",
                color: "#DA2722",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "3 - 4",
                color: "#C02156",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "4 - 5",
                color: "#AA2998",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "5 - 6",
                color: "#951E92",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "6 - 7",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    },
    {
        layer: "eiffel:copert_roads_local_optimistic_scenario",
        style: "copert_roads_local_optimistic_scenario",
        legend: [
            {
                id: 1,
                value: "1 - 2",
                color: "#FDAE61",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "2 - 3",
                color: "#DA2722",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "3 - 4",
                color: "#C02156",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "4 - 5",
                color: "#AA2998",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "5 - 6",
                color: "#951E92",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "6 - 7",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    },
    {
        layer: "eiffel:copert_roads_local_base_scenario",
        style: "copert_roads_local_base_scenario",
        legend: [
            {
                id: 1,
                value: "1 - 2",
                color: "#FDAE61",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "2 - 3",
                color: "#DA2722",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "3 - 4",
                color: "#C02156",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "4 - 5",
                color: "#AA2998",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "5 - 6",
                color: "#951E92",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "6 - 7",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    },
    {
        layer: "eiffel:copert_roads_local_bau_scenario",
        style: "copert_roads_local_bau_scenario",
        legend: [
            {
                id: 1,
                value: "1 - 2",
                color: "#FDAE61",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "2 - 3",
                color: "#DA2722",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "3 - 4",
                color: "#C02156",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "4 - 5",
                color: "#AA2998",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "5 - 6",
                color: "#951E92",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "6 - 7",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    },
    {
        layer: "eiffel:copert_roads_local_public_transportation",
        style: "copert_roads_local_public_transportation",
        legend: [
            {
                id: 1,
                value: "1 - 2",
                color: "#FDAE61",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "2 - 3",
                color: "#DA2722",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "3 - 4",
                color: "#C02156",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "4 - 5",
                color: "#AA2998",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "5 - 6",
                color: "#951E92",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "6 - 7",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    },
    // End of Copert Roads Local
    
    // Start of Copert Roads Regional
    {
        layer: "eiffel:copert_roads_regional_baseline",
        style: "copert_roads_regional_baseline",
        legend: [
            {
                id: 1,
                value: "100 - 200",
                color: "#FAAC60",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "200 - 300",
                color: "#ED7044",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "300 - 400",
                color: "#DE3328",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "400 - 500",
                color: "#D11B2D",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "500 - 600",
                color: "#C61F4B",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "600 - 700",
                color: "#BC2368",
                dsc: "tCO2eq/year"
            },
            {
                id: 7,
                value: "700 - 800",
                color: "#A92781",
                dsc: "tCO2eq/year"
            },
            {
                id: 8,
                value: "800 - 900",
                color: "#A42BA1",
                dsc: "tCO2eq/year"
            },
            {
                id: 9,
                value: "900 - 1000",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 10,
                value: "1000 - 1100",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 11,
                value: "1100 - 1200",
                color: "#8A1686",
                dsc: "tCO2eq/year"
            },
            {
                id: 12,
                value: ">1200",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    },
    {
        layer: "eiffel:copert_roads_regional_optimistic_scenario",
        style: "copert_roads_regional_optimistic_scenario",
        legend: [
            {
                id: 1,
                value: "100 - 200",
                color: "#FAAC60",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "200 - 300",
                color: "#ED7044",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "300 - 400",
                color: "#DE3328",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "400 - 500",
                color: "#D11B2D",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "500 - 600",
                color: "#C61F4B",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "600 - 700",
                color: "#BC2368",
                dsc: "tCO2eq/year"
            },
            {
                id: 7,
                value: "700 - 800",
                color: "#A92781",
                dsc: "tCO2eq/year"
            },
            {
                id: 8,
                value: "800 - 900",
                color: "#A42BA1",
                dsc: "tCO2eq/year"
            },
            {
                id: 9,
                value: "900 - 1000",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 10,
                value: "1000 - 1100",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 11,
                value: "1100 - 1200",
                color: "#8A1686",
                dsc: "tCO2eq/year"
            },
            {
                id: 12,
                value: ">1200",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    },
    {
        layer: "eiffel:copert_roads_regional_base_scenario",
        style: "copert_roads_regional_base_scenario",
        legend: [
            {
                id: 1,
                value: "100 - 200",
                color: "#FAAC60",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "200 - 300",
                color: "#ED7044",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "300 - 400",
                color: "#DE3328",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "400 - 500",
                color: "#D11B2D",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "500 - 600",
                color: "#C61F4B",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "600 - 700",
                color: "#BC2368",
                dsc: "tCO2eq/year"
            },
            {
                id: 7,
                value: "700 - 800",
                color: "#A92781",
                dsc: "tCO2eq/year"
            },
            {
                id: 8,
                value: "800 - 900",
                color: "#A42BA1",
                dsc: "tCO2eq/year"
            },
            {
                id: 9,
                value: "900 - 1000",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 10,
                value: "1000 - 1100",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 11,
                value: "1100 - 1200",
                color: "#8A1686",
                dsc: "tCO2eq/year"
            },
            {
                id: 12,
                value: ">1200",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    },
    {
        layer: "eiffel:copert_roads_regional_bau_scenario",
        style: "copert_roads_regional_bau_scenario",
        legend: [
            {
                id: 1,
                value: "100 - 200",
                color: "#FAAC60",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "200 - 300",
                color: "#ED7044",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "300 - 400",
                color: "#DE3328",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "400 - 500",
                color: "#D11B2D",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "500 - 600",
                color: "#C61F4B",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "600 - 700",
                color: "#BC2368",
                dsc: "tCO2eq/year"
            },
            {
                id: 7,
                value: "700 - 800",
                color: "#A92781",
                dsc: "tCO2eq/year"
            },
            {
                id: 8,
                value: "800 - 900",
                color: "#A42BA1",
                dsc: "tCO2eq/year"
            },
            {
                id: 9,
                value: "900 - 1000",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 10,
                value: "1000 - 1100",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 11,
                value: "1100 - 1200",
                color: "#8A1686",
                dsc: "tCO2eq/year"
            },
            {
                id: 12,
                value: ">1200",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    },
    {
        layer: "eiffel:copert_roads_regional_public_transportation",
        style: "copert_roads_regional_public_transportation",
        legend: [
            {
                id: 1,
                value: "100 - 200",
                color: "#FAAC60",
                dsc: "tCO2eq/year"
            },
            {
                id: 2,
                value: "200 - 300",
                color: "#ED7044",
                dsc: "tCO2eq/year"
            },
            {
                id: 3,
                value: "300 - 400",
                color: "#DE3328",
                dsc: "tCO2eq/year"
            },
            {
                id: 4,
                value: "400 - 500",
                color: "#D11B2D",
                dsc: "tCO2eq/year"
            },
            {
                id: 5,
                value: "500 - 600",
                color: "#C61F4B",
                dsc: "tCO2eq/year"
            },
            {
                id: 6,
                value: "600 - 700",
                color: "#BC2368",
                dsc: "tCO2eq/year"
            },
            {
                id: 7,
                value: "700 - 800",
                color: "#A92781",
                dsc: "tCO2eq/year"
            },
            {
                id: 8,
                value: "800 - 900",
                color: "#A42BA1",
                dsc: "tCO2eq/year"
            },
            {
                id: 9,
                value: "900 - 1000",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 10,
                value: "1000 - 1100",
                color: "#9D249A",
                dsc: "tCO2eq/year"
            },
            {
                id: 11,
                value: "1100 - 1200",
                color: "#8A1686",
                dsc: "tCO2eq/year"
            },
            {
                id: 12,
                value: ">1200",
                color: "#810F7C",
                dsc: "tCO2eq/year"
            }
        ]
    }
    // End of Copert Roads Regional
];

export default LEGENDS;