<template>
  <base-map-component
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    :max-bounds="mapBounds"
    @map-ready="onMapReady($event)"
    @find-coords="retrieveClickedPosition($event)"
  >
    <template #marker>
      <l-marker
        v-if="Object.keys(userLocation).length > 0"
        :icon="icon"
        :lat-lng="[userLocation.lat, userLocation.lng]"
      >
      </l-marker>
      <l-marker-cluster :options="markerClusterOptions">
        <l-marker
          v-for="(point, index) in points"
          :key="index"
          :icon="icon"
          :lat-lng="[point.lat, point.lng]"
          style="z-index: 999"
          @click="toggleMarkerInfo(point.name)"
        >
        </l-marker>
      </l-marker-cluster>
    </template>
    <template #legend>
      <l-control
        position="bottomleft"
        style="margin: 0 !important"
        class="overflow-auto"
        :style="`width: ${mapElement.maxWidth}px`"
      >
        <base-map-legend
          v-if="isSelectedLayerObjEmpty"
          :legends="legends.legend"
          style="z-index: 9999 !important;"
        >
        </base-map-legend>
      </l-control>
      <l-control
        position="bottomleft"
      >
        <base-map-banner
          v-if="isGeoJsonObjNull"
          :selected-polygon="splitselectedPolygon(3)"
        >
        </base-map-banner>
      </l-control>
      <l-control
        position="bottomright"
      >
        <base-map-banner
          v-if="isGeoJsonObjNull"
          :selected-polygon="splitselectedPolygon(4)"
          class="mb-10"
        >
        </base-map-banner>
      </l-control>
    </template>
    <template #tooltip>
      <l-control
        v-if="isSelectedLayerObjEmpty" 
        position="topleft"
      >
        <base-tooltip
          class="overflow-auto" 
          :style="`width: ${mapElement.maxWidth}px`"
        >
        </base-tooltip>
      </l-control>
    </template>

    <template #controlZoom>
      <l-control 
        position="topleft" 
        :style="determineStyle"
      >
        <base-map-banner
          v-if="isGeoJsonObjNull"
          :selected-polygon="splitselectedPolygon(1)"
          class="mb-10"
        >
        </base-map-banner>
        <base-map-control
          @update:zoom="updateZoom($event)"
        >
        </base-map-control>
        <get-user-location
          class="mt-2"
          :user-current-position="userLocation"
          @user-position="setUserPosition($event)"
          @remove-position="removeUserPosition($event)"
        >
        </get-user-location>
        <base-map-toggle-wms
          v-if="isGeoJsonObjNull"
          class="mt-2"
          :selected-layer="selectedLayer"
          @close-layer="closeLayer()"
        >
        </base-map-toggle-wms>
        <base-open-menu
          class="mt-2"
          :drawer="drawer"
          @toggle-menu="toggleMenu($event)"
        >
        </base-open-menu>
      </l-control>
    </template>

    <template #controlPolygon>
      <l-geo-json
        v-if="isGeoJsonObjNull"
        :geojson="geojson"
        :options="polygonStyle"
        style="z-index: 10 !important;"
        @click="onPolygonClick($event)"
      >
      </l-geo-json>
    </template>

    <template #controlWMS>
      <l-control 
        position="topright"
      >
        <base-map-banner
          v-if="isGeoJsonObjNull"
          :selected-polygon="splitselectedPolygon(2)"
        >
        </base-map-banner>
        <base-map-toggle-wms
          v-if="isSelectedLayerObjEmpty && !isGeoJsonObjNull"
          :selected-layer="selectedLayer"
          @close-layer="closeLayer()"
        >
        </base-map-toggle-wms>
      </l-control>
    </template>

    <template #wms>
      <l-wms-tile-layer
        v-if="isSelectedLayerObjEmpty"
        :key="`${selectedLayer.id + selectedStyles}`"
        ref="wmsLayer"
        :base-url="selectedLayer.url"
        :layers="selectedLayer.layers"
        :visible="selectedLayer.visible"
        :opacity="selectedLayer.opacity"
        :name="selectedLayer.name"
        version="1.3.0"
        :styles="selectedStyles"
        :transparent="selectedLayer.transparent"
        :options="{ tiled: true }"
        :format="selectedLayer.format"
        style="z-index: 999 !important;"
        layer-type="base"
      >
      </l-wms-tile-layer>
      <l-wms-tile-layer
        v-if="selectedLayer.contour"
        :key="`${selectedLayer.id + selectedLayer.name}`"
        :base-url="selectedLayer.url"
        :layers="selectedLayer.contour"
        :visible="selectedLayer.visible"
        :opacity="selectedLayer.opacity"
        :name="selectedLayer.name"
        version="1.3.0"
        :transparent="selectedLayer.transparent"
        :options="{ tiled: true }"
        :format="selectedLayer.format"
        layer-type="base"
      >
      </l-wms-tile-layer>
    </template>
  </base-map-component>
</template>

<script>
  import { LMarker, LControl, LWMSTileLayer, LGeoJson } from 'vue2-leaflet';
  import { icon } from 'leaflet'; 
  import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

  // Importing BaseMapComponent
  import BaseMapComponent from '../../components/BaseMapComponent.vue';
  // Importing BaseMapControl
  import BaseMapControl from '../../components/BaseMapControl.vue';
  // Importing Toggle WMS Control
  import BaseMapToggleWms from '../../components/BaseMapToggleWms.vue';
  // Import Toggle WMS Color
  import BaseMapColorMenu from '../../components/BaseMapColorMenu.vue';
  // Import userLocation
  import GetUserLocation from "../../components/UserLocation.vue";
  // import map Legend
  import BaseMapLegend from "../../components/BaseMapLegend.vue"; 
  // Import Base tooltip
  import BaseTooltip from "../../components//BaseTooltip.vue"
  // Import menu toggle
  import BaseOpenMenu from "../../components/BaseOpenMenu.vue";
  // Import base map banner
  import BaseMapBanner from "../../components/BaseMapBanner.vue";

  // Import marker cluster custom styles
  import "../../assets/MarkerCluster.css";
  import "../../assets/MarkerCluster.Default.css";
  

    export default {
      name: 'MapContainer',
      props: {
        layers: {
          type: [Object, Array],
          required: false,
          default: () => {
            return [];
          },
        },
        selectedLayer: {
          type: [Object, Array],
          required: false,
          default: () => {
            return [];
          }
        },
        drawer: {
          type: Boolean,
          required: true,
        },
        points: {
          type: [Object, Array],
          required: false,
          default: () => {
            return [];
          }
        },
        selectedStyles: {
          type: String,
          required: true,
        },
        legends: {
          type: [Array, Object],
          required: false,
          default: () => {
            return [];
          }
        },
        selectedPolygon: {
          type: [Array, Object],
          required: false,
          default: () => {
            return [];
          }
        },
        geojson: {
          type: [Object, Array],
          required: false,
          default: () => {
            return null;
          }
        }
      },
      components: {
        BaseMapComponent,
        BaseMapColorMenu,
        GetUserLocation,
        BaseTooltip,
        BaseMapLegend,
        BaseMapBanner,
        LGeoJson,
        LMarker,
        LControl,
        BaseOpenMenu,
        BaseMapControl,
        BaseMapToggleWms,
        'l-wms-tile-layer': LWMSTileLayer,
        'l-marker-cluster': Vue2LeafletMarkerCluster,
      },
      data () {
        return {
          polygonStyle: {
            fillColor: '#2d3436', // Fill color of the polygon
            fillOpacity: 0.2,   // Opacity of the fill
            color: '#98A753', // BD7C37 Stroke (border) color
            weight: 2,
          },
          mapObject: null,
          markerDialog: false,
          featureDialog: false,
          isLoading: false,
          snackBarStatus: false,
          snackText: "",
          userLocation: {},
          feature: [],
          keysToShow: [],
          featureTimeStamp: "",
          isMobile: false,
          // crs: new L.Proj.CRS("EPSG:2100", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"), // Adjust the CRS definition
          markerInfo: [],
          zoom: 11,
          markerClusterOptions: {
            animateAddingMarkers: true,
            singleMarkerMode: false,
            showCoverageOnHover: false,
          },
          mapOptions: {
            zoomControl: false,
            minZoom: 10,
            maxZoom: 18
          },
          mapBounds: [
            [37.8, 23.5], // Southwest coordinates of Athens (extended)
            [38.3, 24.2], // Northeast coordinates of Athens (extended)
          ],
          mapElement: {
            maxWidth: 1472,
            show: false,
            map: null
          },
          center: [ 38.053291, 23.856769 ],
          icon: icon({
            iconUrl: require('../../assets/eiffel_marker_3d.svg'),
            iconSize: [ 32, 37 ]
          }),
        }
      },

      computed: {
        determineStyle() {
          return Object.keys(this.selectedLayer).length != 0 ? 'margin-top: -2.7rem;' : '';
        },
        isSelectedLayerObjEmpty() {
          return Object.keys(this.selectedLayer) != 0;
        },
        isGeoJsonObjNull() {
          return this.geojson != null;
        }
      },

      methods: {
          splitselectedPolygon(index) {

            const indexMapper = {
              1: "CO2",
              2: "CH4",
              3: "N2O",
              4: "CO2EQ"
            };

            let key = Object.keys(this.selectedPolygon.content)[index];
            let value = Object.values(this.selectedPolygon.content)[index];
          
            let filteredRoads = this.selectedPolygon.roads.scenarios.filter(scenario => {
              return this.selectedLayer.layers.includes(scenario.name);
            });

           
            const determineRoads = filteredRoads.at(0).roads.map(road => {
              return {
                id: road.id,
                name: road.name,
                pollutant: road.pollutants[indexMapper[index]]
              }
            });

            return { key, value, roads: determineRoads };
          },

          onPolygonClick(_event) {
            this.$emit("toggle-polygon", "polygon");
          },

          flyToPosition(coords, zoomLevel) {
            this.mapObject.flyTo([coords[0], coords[1]], zoomLevel);
          },
          
          updateZoom(eventName) {
            if (eventName === "plus") {
                this.zoom = Math.min(this.zoom + 1, this.mapOptions.maxZoom);
            } else if (eventName === "minus") {
                this.zoom = Math.max(this.zoom - 1, this.mapOptions.minZoom);
            }
          },

          onMapReady(event) {
            this.mapObject = event;
            // this.determineDeviceUsed(7, 11);
            this.mapElement.maxWidth = event.getSize().x;
            event.on("resize", (e) => {
              // this.determineDeviceUsed(7, 11);
              this.mapElement.maxWidth = e.target.getSize().x;
            });
            event.on("zoomend", (e) => {
              this.zoom = e.target.getZoom();
            });

            this.mapElement.value = event;
            this.mapElement.show = true;

            if (this.$vuetify.breakpoint.lgAndUp) this.flyToPosition(this.center, 10);
          },

          closeLayer() {
            this.$emit("close-layer");
          },

          setUserPosition(userLocation) {
            // Adjust map bounds
            this.mapBounds = [
              [34.7049, 19.1477], // Southwest coordinates of Greece (extended)
              [41.7989, 29.7536], // Northeast coordinates of Greece (extended)
            ],

            this.userLocation = {
              lat: userLocation[0],
              lng: userLocation[1]
            };
            this.flyToPosition(userLocation, 15);
          },

          removeUserPosition() {
            this.userLocation = {};
            this.flyToPosition(this.center, 10);

            const timeout = setTimeout(() => {
            // Adjust map bounds to it's default state of Athens
              this.mapBounds = [
                [37.9, 23.6], // Southwest coordinates of Athens (extended)
                [38.2, 24.1], // Northeast coordinates of Athens (extended)
              ]
              clearTimeout(timeout);
            }, 4000);
          },

          async retrieveClickedPosition(event) {
            this.$emit("retrieved-clicked-position", event);
          },

          async toggleMarkerInfo(markerName) {
            this.$emit("toggle-marker-info", markerName);
          },

          toggleMenu(event) {
            this.$emit("toggle-menu", event);
          },
      },
      watch: {
        selectedLayer (newVal) {
          if (newVal) {
            if (Object.keys(newVal).length != 0) {
              let determineLayerZoom = newVal.zoomLevel ? newVal.zoomLevel : 14;
              this.flyToPosition(this.center, determineLayerZoom);
            } else {
              this.flyToPosition(this.center, 10);
            }
          }
        },
        selectedPolygon (newVal) {
          if (newVal && newVal.polygonName == "penteliGeoJson") {
            this.flyToPosition(this.center, 12);
          }
        },
        selectedStyles (newVal) {
          if (newVal) {
            let determineLayerZoom = this.selectedLayer.zoomLevel ? this.selectedLayer.zoomLevel : 14;
            this.flyToPosition(this.center, determineLayerZoom);
          }
        }
      },
    }
</script>

<style scoped>

</style>
