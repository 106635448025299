<template>
  <v-dialog 
    v-model="isOpen" 
    class="popup_wrapper" 
    max-width="1000"
  >
    <div>
      <v-sheet 
        v-if="isLoading" 
        style="height: 100%" 
        class="content_wrapper"
      >
        <v-skeleton-loader
          class="pa-3"
          type="table-heading, list-item-two-line, list-item-two-line, list-item, image, list-item"
        ></v-skeleton-loader>
      </v-sheet>
      <v-container v-else class="pa-1 ma-0">
        <v-sheet rounded class="pa-3 custom-shadow content_wrapper">
          <div class="d-flex justify-space-between">
            <div class="justify-start flex-column">
              <v-card-title
                class="mb-2 d-flex custom-gap align-center text-h5 mapbutton--text"
              >
                <v-icon
                  normal
                  color="textDark"
                  class="scenariobutton pa-1 rounded"
                >
                  mdi mdi-layers-search
                </v-icon>
                <div class="textDark--text">
                    |
                </div>
                <span class="custom-title">
                  {{ $t(`chartDialog.banner`) }}
                </span>
              </v-card-title>
              <v-card-subtitle>
                {{ $t(`chartDialog.title`) }}
              </v-card-subtitle>
              <v-card-subtitle
                v-if="selectedLayer.includes('o3')"
                class="d-flex flex-column hidden-sm-and-down"
              >
                <div class="d-flex align-center">
                  <v-icon small left color="navbutton">
                    mdi mdi-information-slab-circle-outline
                  </v-icon>
                  <span class="text-caption">
                    {{ $t(`chartDialog.zoomChart`) }}
                  </span>
                </div>
                <div class="d-flex align-center">
                  <v-icon small left color="navbutton">
                    mdi mdi-information-slab-circle-outline
                  </v-icon>
                  <span class="text-caption">
                    {{ $t(`chartDialog.moveChart`) }}
                  </span>
                </div>
              </v-card-subtitle>
            </div>
            <div class="justify-end">
              <v-btn
                depressed
                outlined
                style="border: none"
                class="mt-4 white--text"
                @click="isOpen = !isOpen"
              >
                <v-icon normal class="scenariobutton--text">
                  mdi mdi-close-thick
                </v-icon>
              </v-btn>
            </div>
          </div>
          <!-- Chart  -->
          <div v-if="markerInfo.length != 0">
            <chart
              ref="chart"
              :options="chartOptions"
              class="graph custom-shadow"
            >
            </chart>
          </div>
        </v-sheet>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
// Impoerting Hight Charts
import { Chart } from "highcharts-vue";

export default {
  name: "BaseMarkerDialog",
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    markerInfo: {
      type: [Array, Object],
      required: false,
      default: () => {
        return [];
      },
    },
    selectedLayer: {
      type: String,
      required: false,
      default: "",
    },
    markerName: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
  },
  components: {
    Chart,
  },
  data() {
    return {
      determineBaseLine: null,
      isOpen: false,
      chartOptions: {},
      obtainSuperScripts: {
        PM25: "PM₂.₅",
        NO2: "NO₂",
        O3: "O₃"
      },
      // WHO Threshholds for AQ (only)
      pollutantThresholds: {
          pm25: 15,
          no2: 25,
          o3: 100
      }
    };
  },
  computed: {
    determinePopupName() {
      return this.markerName.find(marker => marker.name == this.markerInfo.name);
    },

    findSeason() {
      // Parse the date string in DD/MM/YYYY format
      const dateString = this.markerInfo.data.at(0).date;
      const [day, month, year] = dateString.split(" ").at(0).split("/").map(Number);
      const date = new Date(year, month - 1, day);
      
      // Create Date objects for the start and end of summer and winter
      const summerStart = new Date(year, 5, 21);
      const summerEnd = new Date(year, 8, 22);
      const winterStart = new Date(year, 11, 21);
      const winterEnd = new Date(year + 1, 2, 20);
      
      // Check if the date falls within the summer range
      if (date >= summerStart && date <= summerEnd) {
          return "Summer";
      }

      // Check if the date falls within the winter range
      if (date >= winterStart || date <= winterEnd) {
          return "Winter";
      }

      return "";
    },

    findPolutant() {
        let match = this.selectedLayer.match(/([^_]+)$/);
        return match ? match[0].toUpperCase() : "";
    },

    determinePredictionName() {
      return this.selectedLayer.includes("baseline") ? "Baseline Predictions" : "Scenario Predictions";
    }
  },
  methods: {
    addBaselinePredictions() {

      const determineChartPredictions = this.selectedLayer.includes("o3") 
      ?
        this.processDataForTimeSeriesChart(Object.keys(this.markerInfo.data[0])[3]) 
      :
        this.processDataForBaseChart(Object.keys(this.markerInfo.data[0])[3])

      this.chartOptions.series.splice(2, 0, {
        name: "Baseline Predictions",
        color: "#808080",
        data: determineChartPredictions
      });
    },

    addWhoThreshold(data) {
      const observationData = data(Object.keys(this.markerInfo.data[0])[1]);
      const predictionData = data(Object.keys(this.markerInfo.data[0])[2]);

      // Extract y-values and ensure they are numbers
      const observationYValues = observationData.map(item => {
        const value = item[1];
        return isNaN(value) ? null : value;
      });
      const predictionYValues = predictionData.map(item => {
        const value = item[1];
        return isNaN(value) ? null : value;
      });

      // Filter out any null values from the data
      const validObservationYValues = observationYValues.filter(v => v !== null);
      const validPredictionYValues = predictionYValues.filter(v => v !== null);

      // Determine the min and max y-axis values based on your data and WHO threshold
      const allData = [ ...validObservationYValues, ...validPredictionYValues ];

      return { observationData, predictionData, allData };
    },

    chartData() {
      // Processing data
      const { observationData, predictionData, allData } = this.addWhoThreshold(this.processDataForBaseChart);
      
      this.chartOptions = {
        zooming: {
          resetButton: {
            position: {
              verticalAlign: "top",
              x: 10,
              y: -30,
            },
          },
        },
        chart: {
          type: "areaspline",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        },
        xAxis: {
          type: "datetime",
          categories: this.markerInfo.data.map((item) => item.date),
        },
        yAxis: [
          {
            title: {
              text: `<span>Mean Daily Concentrations <span class="font-weight-bold">(μg m-3)</span></span>`,
            },
            plotLines: [
              {
                value: this.determineBaseLine, // The value where the baseline should appear
                color: "#2d3436", // Color of the baseline
                width: 2, // Line width of the baseline
                zIndex: 5, // Place the baseline above other elements
              },
            ],
            softMin: Math.min(...allData, this.determineBaseLine),
            softMax: Math.max(...allData, this.determineBaseLine),
          },
        ],
        series: [
          {
            name: "Observations",
            color: "#BD7C37",
            // data: this.markerInfo.data.map(item => parseFloat(item[this.markerInfo.name + "o"])),
            data: observationData,
            // yAxis: 0, // Use the first yAxis
            type: "areaspline",
          },
          {
            name: this.determinePredictionName,
            color: "#5A85A9",
            // data: this.markerInfo.data.map(item => parseFloat(item[this.markerInfo.name + "m"])),
            data: predictionData,
            // yAxis: 1, // Use the first yAxis
            type: "areaspline",
          },
          {
            name: "WHO Threshold",
            color: "#2d3436"
          },
        ],
        accessibility: {
          enabled: false,
        },
        plotOptions: {
          areaspline: {
            lineWidth: 1,
            fillOpacity: 0.5,
            color: "#5a85a9",
          },
          series: {
            // stacking: 'normal',
            animation: {
              duration: 2000,
            },
            opacity: 0.9,
          },
        },
        title: {
          text: `<h5 class="graph__title font-weight-light"><span class="font-weight-bold" style="color: #98A753;">${this.determinePopupName.fullName.toUpperCase()}</span> | <strong class="font-weight-bold text-h6">${this.obtainSuperScripts[this.findPolutant]}</strong> (${this.findSeason})</h5>`,
          useHTML: true,
          align: "center",
        },
        credits: {
          enabled: false,
        },
      };
    },

    chartDataTimeSeries() {
      // Processing data
      const { observationData, predictionData, allData } = this.addWhoThreshold(this.processDataForTimeSeriesChart);
      
      this.chartOptions = {
        zooming: {
          resetButton: {
            position: {
              verticalAlign: "top",
              x: 10,
              y: -30,
            },
          },
        },
        chart: {
          type: "areaspline", // You can choose the appropriate chart type
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          zoomType: "x", // Enable zooming along the X-axis
          panning: true, // Enable panning
          panKey: "shift", // Hold down Shift key to pa
        },
        title: {
          text: `<h5 class="graph__title font-weight-light"><span class="font-weight-bold" style="color: #98A753;">${this.determinePopupName.fullName.toUpperCase()}</span> | <strong class="font-weight-bold text-h6">${this.obtainSuperScripts[this.findPolutant]}</strong> (${this.findSeason})</h5>`,
          useHTML: true,
          align: "center",
        },
        accessibility: {
          enabled: false,
        },
        xAxis: {
          type: "datetime",
          title: {
            text: `<span class="font-weight-bold">Season <span class="font-weight-light">(${this.findSeason})</span></span>`,
          }
        },
        yAxis: {
          title: {
            text: `<span>Moving Average 8-hour Concentrations <br /> <span class="font-weight-bold">(μg m-3)</span></span>`,
          },
          plotLines: [
            {
              value: this.determineBaseLine, // The value where the baseline should appear
              color: "#2d3436", // Color of the baseline
              width: 2, // Line width of the baseline
              zIndex: 5, // Place the baseline above other elements
            },
          ],
          softMin: Math.min(...allData, this.determineBaseLine),
          softMax: Math.max(...allData, this.determineBaseLine),
        },
        series: [
          {
            name: "Observations",
            color: "#5A85A9",
            data: observationData
          },
          {
            name: this.determinePredictionName,
            color: "#BD7C37",
            data: predictionData
          },
          {
            name: "WHO Threshold",
            color: "#2d3436",
          },
        ],
        plotOptions: {
          areaspline: {
            lineWidth: 1,
            fillOpacity: 0.5,
            color: "#5a85a9",
          },
          series: {
            // stacking: 'normal',
            animation: {
              duration: 2000,
            },
            opacity: 0.9,
          },
        },
        credits: {
          enabled: false,
        },
      };
    },
    
    processDataForTimeSeriesChart(valueKey) {
      // Exclude values -99
      // const filteredData = this.markerInfo.data.filter(item => parseFloat(item[valueKey]) !== -99);
      const formattedData = this.markerInfo.data.map(item => {
          const newItem = { ...item };
          if (parseFloat(newItem[valueKey]) === -99) {
              newItem[valueKey] = 0;
          }
          return newItem;
      });


      // Process your data array to match Highcharts format
      const chartData = formattedData.map(item => {
        // Split the date string to get the day, month, year, and time
        const [ dateString, timeString ] = item.date.split(" ");
        const [ day, month, year ] = dateString.split("/");
        const [ hour, minute ] = timeString.split(":");
        
        const dataDate = new Date(year, month - 1, day, hour, minute);
        
        return {
          x: dataDate.getTime(),
          y: parseFloat(item[valueKey]),
        };
      });
      
      return chartData;
    },
    // Format Series for base Chart
    processDataForBaseChart(valueKey) {
      // Exclude values -99
      // const filteredData = this.markerInfo.data.filter(item => parseFloat(item[valueKey]) != -99);
    
      const formattedData = this.markerInfo.data.map(item => {
          const newItem = { ...item };
          if (parseFloat(newItem[valueKey]) === -99) {
              newItem[valueKey] = 0;
          }
          return newItem;
      });

      return formattedData.map((item) => parseFloat(item[valueKey]));
    },
    
    findPollutantBaseline() {
      for (let keyword in this.pollutantThresholds) {
            if (this.selectedLayer.includes(keyword)) {
              this.determineBaseLine = this.pollutantThresholds[keyword];
              break;
            }
      }
    },
  },
  watch: {
    dialog(_newVal) {
      // Determine baseline (wHO Threshold).
      this.findPollutantBaseline();
      // Toggle popup dialog
      this.isOpen = !this.isOpen;
    },

    markerInfo: {
      handler(newVal) {
        if (newVal) {
          this.selectedLayer.includes("o3") ? this.chartDataTimeSeries() : this.chartData();

          if (!this.selectedLayer.includes("baseline")) this.addBaselinePredictions();
        }
      },
    }
  },
};
</script>

<style scoped>
  .graph {
    height: 450px;
    width: auto;
    border-radius: 8px;
    padding: 0.5rem 0;
    margin: 0.2em 0.2em;
  }
  .popup_wrapper {
    z-index: 9999;
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for IE and Edge */
  }
  .popup_wrapper::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for Chrome and Safari */
  }
  .content_wrapper {
    background: rgba(255, 255, 255, 0.8);
  }
  .custom-title {
    font-weight: 900 !important;
    text-shadow: 1px 0px 1px #BD7C37, 0px 1px 0px rgba(0, 0, 0, 0.15);
  }
</style>
