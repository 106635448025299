<template>
    <v-col
        cols="12"
        class="d-flex px-4 align-center justify-center nav-bar-background-wrapper"
      >
       <!-- Desktop Navigation -->
          <div class="hidden-sm-and-down">
            <div class="d-flex align-center custom-gap">
              <v-img
                alt="eiffel-logo"
                class="shrink mr-5 cursor-pointer"
                contain
                :src="require('../../assets/Eiffel_logo_color.png')"
                transition="scale-transition"
                width="115"
              >
              </v-img>
              <div class="navigation-border custom-shadow mr-5"></div>
              <v-toolbar-title 
                class="text-h5 black--text hidden-sm-and-down custom-title-animation d-flex align-start"
              >
                <span class="letter-spacing">
                  {{ $t(`general.headerTitle`) }}
                </span>
               </v-toolbar-title>
            </div>
          </div>
        <!-- Mobile Navigation -->
          <div class="hidden-md-and-up">
            <div class="d-flex align-center">
              <v-img
                alt="eiffel-logo"
                class="shrink mr-5 cursor-pointer"
                contain
                :src="require('../../assets/Eiffel_logo_color.png')"
                transition="scale-transition"
                width="110"
              >
              </v-img>
              <div class="navigation-border-mobile"></div>
              <v-toolbar-title spacing class="text-h5 black--text hidden-sm-and-down">
                {{ $t(`general.headerTitle`) }}
              </v-toolbar-title>
            </div>
          </div>
          <v-spacer></v-spacer>
            <!-- <v-btn
              depressed 
              elevation-2
              small
              color="navbutton"
              class="white--text pa-5 rounded"
              disabled
              @click="handleLogin()"
            >
              <v-icon left medium>
                mdi-login
              </v-icon>
              <span class="text-caption">{{ $t(`general.login`) }}</span>
            </v-btn> -->
      </v-col>
</template>

<script>
export default {
    name: 'NavBar',
    data() {
        return {}
    },
    computed: {
        calcCurrentYear() {
            return new Date().getFullYear()
        }
    },
    methods: {
        handleLogin() {
            this.$router.push({ name: 'login' })
        }
    }
}
</script>

<style scoped>
    .custom-title-animation span{
        overflow: hidden; /* Ensures the content is not revealed until the animation */
        white-space: nowrap; /* Keeps the content on a single line */
        margin: 0 auto; /* Gives that scrolling effect as the typing happens */
        animation: typing 1.5s steps(40, end)
    }

    @keyframes typing {
        from { width: 0 }
        to { width: 100% }
    }

</style>