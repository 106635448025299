export const ENERGY_PROD_KEYS = {

  'Α+': {
    value: "#00FF05",
    width: 25
  },
  'A+': {
    value: "#00FF05",
    width: 25
  },
  'Α': {
    value: "#7BFC02",
    width: 30
  },
  'A': {
    value: "#7BFC02",
    width: 30
  },
  'Β+': {
    value: "#ADFF30",
    width: 35
  },
  'Β': {
    value: "#FFFE03",
    width: 40
  },
  'B+': {
    value: "#ADFF30",
    width: 40
  },
  'B': {
    value: "#FFFE03",
    width: 40
  },
  'Γ': {
    value: "#FEA500",
    width: 50
  },
  'C': {
    value: "#FEA500",
    width: 50
  },
  'D': {
    value: "#FB7F9B",
    width: 60
  },
  'Δ': {
    value: "#FB7F9B",
    width: 60
  },
  'Z': {
    value: "#FB7F9B",
    width: 60
  },
  'Ε': {
    value: "#FE0000",
    width: 70
  },
  'E': {
    value: "#FE0000",
    width: 70
  },
  'Ζ': {
    value: "#CE5C5C",
    width: 70
  },
  'F': {
    value: "#CE5C5C",
    width: 80
  },
  'Η': {
    value: "#a72a28",
    width: 80
  },
  'H': {
    value: "#a72a28",
    width: 90
  },
  'G': {
    value: "#a72a28",
    width: 90
  },
  'N/A': {
    value: "#FFFFFF",
    width: 20
  },
  'Ν/Α': {
    value: "#FFFFFF",
    width: 20
  },
  '': {
    value: '#FFFFFF',
    width: 20,
  }
  
}


export default ENERGY_PROD_KEYS;