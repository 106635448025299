<template>
  <v-app>
    <v-row 
      no-gutters 
      class="navigation-custom-height"
    >
      <navbar></navbar>
    </v-row>
    <v-row 
      no-gutters 
      class="main-content-custom-height"
    >
      <v-col cols="12">
        <transition name="fade">
          <router-view></router-view>
        </transition>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Navbar from './components/navigation/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar
  },
  
  data() {
    return {}
  },
  computed: {
    calcCurrentYear() {
      return new Date().getFullYear();
    }
  },
  created() {
    console.info(`Copyright © ${this.calcCurrentYear} | All Rights Reserved | Developed by Draxis SA.`)
  }
}
</script>

<style scoped>
.navigation-custom-height {
  height: 7vh !important;
}
.main-content-custom-height {
  height: 93vh !important;
}
/* Router transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.8s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Mobile map responsive */
@media screen and (max-width: 960px) {
  .main-content-custom-height {
    height: auto !important;
  }
}
</style>
