<template>
  <span v-if="text != 'Pending...'" class="mb-5 d-block custom-font">
    {{ showFullText ? toggleFullText : toggleShortText }}
    <a class="text-caption navbutton--text" @click="toggleText()">
      {{ showFullText ? "Show less.." : "Show more" }}
    </a>
  </span>
  <span v-else class="mb-5 d-block">Pending...</span>
</template>

<script>
export default {
  name: "BaseDescriptionToggler",
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      showFullText: false,
    };
  },
  computed: {
    formattedText() {
      // Regex to match an opening parenthesis followed by a number and percentage symbol
      const regex = /\((\d+%)/g;
      return this.text.replace(regex, "\n($1");
    },
    toggleShortText() {
      // Regex to cut a string from its first dot and excludes parentheses with dot inside them (.)
      const regex = /^[^.]*\([^)]*\)|^[^.]*\./;
      const match = this.formattedText.match(regex);
      return match ? `${match[0]}...` : this.formattedText;
    },
    toggleFullText() {
      return this.formattedText;
    },
  },
  methods: {
    toggleText() {
      this.showFullText = !this.showFullText;
    },
  },
};
</script>

<style scoped></style>
