<template>
    <div class="d-flex align-center cursor-info text-center white--text tooltip_wrapper">
        <div class="d-flex align-center navbutton--text mx-auto tooltip_box rounded-lg">
            <v-icon color="mapbutton" left>
                mdi mdi-information-slab-box
            </v-icon>
            <span class="font-weight-bold text-subtitle-1 hidden-sm-and-down">
                {{ $t("tooltip.titleDesktop") }}
            </span>
            <span class="font-weight-bold text-subtitle-1 hidden-md-and-up">
                {{ $t("tooltip.titleMobile") }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BaseTooltip",
        data() {
            return {}
        }
    }
</script>

<style scoped>
.tooltip_wrapper {
    background: transparent;
}
.tooltip_box {
    backdrop-filter: blur(5px);
    padding: 0.5rem 0.3rem;
}
</style>