<template>
  <div class="right-menu-background-wrapper">
    <div class="d-flex flex-column align-end menu-background-container">
      <v-card-title
          class="px-1 py-2 justify-end textDark--text custom-gap"
        >
          <span
            v-if="findNameFeature"
            class="text-body-1 text-uppercase custom-title"
          >
            {{ trimAreaName(feature[findNameFeature]) }}
          </span>
          <span v-else class="text-body-1 custom-title">
            {{ $t(`rightMetadataBanner.title`) }}
          </span>
          <!-- <div class="textDark--text">
            |
          </div>
          <v-icon normal color="textDark">
            mdi mdi-map-marker-multiple
          </v-icon> -->
        </v-card-title>
        <div class="divider-container mt-3" style="width: 100%;"></div>
        <span
          v-if="findNameOfSchool"
          class="px-1 py-2 text-end textDark--text"
        >
          {{ feature[findNameOfSchool] }}
        </span>
        <v-card-title 
          v-if="findLabelFeature"
          class="py-2 align-start justify-end d-flex custom-gap"  
        >
          <h5>{{ $t(`rightMetadataBanner.energyPerformanceTitle`) }}</h5>
          <h1
            class="custom-font custom-title glow"
            :style="`color: ${determineBackground[feature[findLabelFeature]]?.value || '#FFFFFF'}`"
          >
            {{ transliterateGreekToEnglish(feature[findLabelFeature]) || 'N/A' }}
          </h1>
        </v-card-title>
      <v-spacer></v-spacer>
      <div>
        <div
          v-for="(key, index) in excludeFeaturesToShow"
          :key="index"
          class="d-flex top-border flex-column custom-gap py-1 px-2"
        >
          <v-card-title class="px-0 py-2 text-subtitle-1 textDark--text text-right justify-end d-inline-block text-break">
              {{ extractContent(obtainKeys[key]).stringWihoutParentheses }}
            </v-card-title>
            <div class="d-flex align-center justify-end custom-gap">
              <v-icon x-small color="navbutton"> 
                mdi mdi-circle 
              </v-icon>
              <span
                class="font-weight-bold pa-0 custom-title mapbutton--text text-h6 font-italic"
              >
                {{ formatNumberWithCommasAndDecimals(feature[key], key) }}
                <span class="font-weight-light textDark--text text-subtitle-2">
                  {{ extractContent(obtainKeys[key]).contentInsideParentheses }}
                </span>
              </span>
            </div>
            <div class="divider-container"></div>
        </div>
      </div>
      <v-spacer></v-spacer>
        <div class="align-self-end rounded pa-2">
          <v-tooltip
            offset-x
            left
          >
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                class="custom-shadow py-4 custom-scale"
                dark
                x-small
                elevation-12
                color="textDark"
                v-bind="attrs"
                v-on="on"
                @click="closeMenu()"
              >
                <v-icon dark small>
                  mdi mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t(`rightMetadataBanner.tooltip.title`) }}</span>
          </v-tooltip>
        </div>
    </div>
  </div>
</template>

<script>
// Importing Energy Production indicators (keys)
import ENERGY_PROD_KEYS from '../helpers/keyMapEnergyProduction.js';

// Importing Metadata Keys (formated)
import METADATA_KEYS from '../helpers/keyMapMetadataNames.js';

// Importing Transliterate keys
import TRANSLITERATE_WORDS from '../helpers/keyMapTransliterateGreekToEnglish.js';

export default {
  name: "BaseFeatureMapBanner",
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    feature: {
      type: [Array, Object],
      required: false,
      default: () => {
        return [];
      },
    },
    featuresToShow: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    featureTimeStamp: {
      type: String,
      required: false,
      default: "",
    },
    selectedLayerName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      // Used to determine backgrounds for energy production.
      determineBackground: ENERGY_PROD_KEYS,
      // Used to transform key to actual names that are shown in UI.
      obtainKeys: METADATA_KEYS,
      // Used to transliterate keys from greek version to english.
      transliterateWords: TRANSLITERATE_WORDS
    };
  },
  computed: {
    // Find the Label of the feature
    findLabelFeature() {
      return this.featuresToShow.find((feature) => feature == "Label" || feature == "ENERGY_LABELS");
    },
    // Find the name of the feature
    findNameFeature() {
      return this.featuresToShow.find((feature) => feature == "NAME" || feature == "Municipality" || feature == "int_name");
    },
    // Find the name of the school (works only in photovoltaic energy adequacy of schools scenario)
    findNameOfSchool() {
      return this.featuresToShow.find((feature) => feature == "Name");
    },
    // Exclude the name of the card feature modal:
    excludeFeaturesToShow() {
      return this.featuresToShow.filter((feature) => feature != "Name" && feature != "Municipality" && feature != "NAME" && feature != 'Label' && feature != "int_name" && feature != 'ENERGY_LABELS');
    }
  },
  methods: {
    closeMenu() {
      this.$emit("close-feature-drawer", false);
    },

    transliterateGreekToEnglish (text) {
      if (!text) return;
      return text.split("").map(char => this.transliterateWords[char] || char).join("");
    },

    trimAreaName(name) {
      // Acceptsa a string and keeps only the first part of it before the - or return the string as it is.
      let match = name.match(/^[^–]*/);
      return match ? match[0].trim() : name;
    },

    formatNumberWithCommasAndDecimals(input, string) {
      // let decimals = 3;
      let decimals = string === "population" ? 0 : 3;

      if (typeof input !== "number") return "-"; // Not provided

      let isInteger = input % 1 === 0;
      if (isInteger) {
        decimals = 0;
      }

      // Used toLocaleString to add thousands separators
      let formattedNumber = input.toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });

      
      if (this.selectedLayerName.includes("photovoltaic")) {
        return this.cleanStringValue(formattedNumber);
      } else {
        return formattedNumber;
      }
    },

    cleanStringValue(input) {
        let sanitizedNumber = input.replace(/[^0-9.]/g, '');
        let parsedNumber = parseFloat(sanitizedNumber);

        return isNaN(parsedNumber) ? 0 : Math.round(parsedNumber);
    },

    extractContent(currentString) {
      const regex = /\(([^)]+)\)/; // Match text inside parentheses
      const match = regex.exec(currentString);

      if (match) {
        const stringWihoutParentheses = currentString.replace(regex, "").trim();
        const contentInsideParentheses = match[1];

        return { stringWihoutParentheses, contentInsideParentheses };
      } else {
        return {
          stringWihoutParentheses: currentString,
          contentInsideParentheses: "",
        };
      }
    },

  },
};
</script>

<style scoped>

.card-wrapper {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  border: 2px solid white;
}
.card-inner-wrapper {
  background: rgba(255, 255, 255, 0.5);
}
.custom-title {
  font-weight: 900 !important;
  word-break: break-word;
  text-shadow: 1px 0px 1px #98a753, 0px 1px 0px rgba(0, 0, 0, 0.30);
}
.divider-container {
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(0.25turn, transparent, #98a753, #2d3436);
  border-image-slice: 1;
}
.top-border:nth-of-type(1) {
  border-top: 1px solid transparent;
  border-image: linear-gradient(0.25turn, transparent, #98a753, #2d3436);
  border-image-slice: 1;
}

.glow {
  animation: glow 1.5s ease infinite alternate;
}

/* Text Animation */
@keyframes glow {
  from {
    text-shadow: 0 0 3px #2d3436cf, 0 0 5px #F9FBE9;
  }
  to {
    text-shadow: 0 0 5px #F9FBE9, 0 0 5px #98A753cf;
  }
}
</style>
