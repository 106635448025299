<template>
    <div class="text-center d-flex">
        <v-tooltip
          v-if="!positionRetrieved" 
          offset-y 
          right
        >
            <template #activator="{ on, attrs }">
                <v-btn
                    depressed
                    class="mx-2 py-4 rounded custom-shadow custom-scale"
                    dark
                    x-small
                    elevation-12
                    color="scenariobutton"
                    v-bind="attrs"
                    v-on="on"
                    @click="getUserLocation()"
                >
                    <v-icon dark small>
                        mdi mdi-navigation-variant
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ $t("locationControl.tooltip.location") }}</span>
        </v-tooltip>
        <v-tooltip
          v-else
          offset-y
          right
        >
            <template #activator="{ on, attrs }">
                <v-btn
                    depressed
                    class="mx-2 py-4 rounded custom-shadow custom-scale"
                    dark
                    x-small
                    elevation-12
                    color="textBlack"
                    v-bind="attrs"
                    v-on="on"
                    @click="removeUserPosition()"
                >
                    <v-icon dark small>
                        mdi mdi-account-off
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ $t("locationControl.tooltip.removeLocation") }}</span>
        </v-tooltip>
    </div>
</template>


<script>
    export default {
        name: 'GetUserLocation',
        props: {
            userCurrentPosition: {
                type: Object,
                required: false,
                default: () => {
                    return {};
                }
            }
        },
        data() {
            return {
                userPosition: null
            }
        },
        computed: {
            positionRetrieved() {
                return Object.keys(this.userCurrentPosition).length != 0 ? true : false;
            }
        },
        methods: {
            getUserLocation() {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        this.userPosition = [position.coords.latitude, position.coords.longitude];
                        this.$emit("user-position", this.userPosition);
                    }, (err) => {
                        console.error("error:", err.message);
                    });
                } else {
                    console.error("Geolocation is not available.")
                }
            },
            removeUserPosition() {
                this.$emit("remove-position");
            }
        }
    }
</script>