<template>
  <v-expansion-panels
    hover
    flat
    multiple
    active-class="custom-active-bg customBlack--text rounded"
  >
    <base-accordion
      v-for="scenario in scenarios"
      :key="scenario.id"
      :item="scenario"
      :selected="scenario.id == selectedItem"
      class="py-4 px-0 custom-border"
      @select-item="selectItem(scenario.id)"
      @toggle-item="toggleLayer($event)"
    >
      <template #accordion-name>
        <div class="d-flex align-center">
          <v-icon large color="navbutton">
            mdi mdi-circle-small
          </v-icon>
          <span class="d-block font-weight-light"> {{ $t("scenarios.title") }} <span class="font-weight-bold custom-font">{{ $t(`scenarios.name.${scenario.name}`) }}</span></span>
        </div>
      </template>
      <template #accordion>
        <base-accordion
          v-for="nestedScenarioOne in scenario.subScenarios"
          :key="nestedScenarioOne.id"
          :item="nestedScenarioOne"
          :selected="nestedScenarioOne.id == selectedItem"
          @select-item="selectItem(nestedScenarioOne.id)"
          @toggle-item="toggleLayer($event)"
        >
          <template #accordion>
            <base-accordion
              v-for="nestedScenarioTwo in nestedScenarioOne.scenarios"
              :key="nestedScenarioTwo.id"
              :item="nestedScenarioTwo"
              :selected="nestedScenarioTwo.id == selectedItem"
              @select-item="selectItem(nestedScenarioTwo.id)"
              @toggle-item="toggleLayer($event)"
            >
              <template #accordion>
                <base-accordion
                  v-for="nestedScenarioThree in nestedScenarioTwo.subScenarios"
                  :key="nestedScenarioThree.id"
                  :item="nestedScenarioThree"
                  :has-scenario-data="nestedScenarioThree.subScenarios ? nestedScenarioThree?.subScenarios.length == 0 : false"
                  :selected="nestedScenarioThree.id == selectedItem"
                  @select-item="selectItem(nestedScenarioThree.id)"
                  @toggle-item="toggleLayer($event)"
                >
                  <template #accordion>
                    <base-accordion
                      v-for="nestedScenarioFour in nestedScenarioThree.subScenarios"
                      :key="nestedScenarioFour.id"
                      :item="nestedScenarioFour"
                      :selected="nestedScenarioFour.id == selectedItem"
                      @select-item="selectItem(nestedScenarioFour.id)"
                      @toggle-item="toggleLayer($event)"
                    >
                    </base-accordion>
                  </template>
                </base-accordion>
              </template>
            </base-accordion>
          </template>
        </base-accordion>
      </template>
    </base-accordion>
  </v-expansion-panels>
</template>

<script>

import BaseAccordion from './base/BaseAccordion.vue';

// Importing mapGetters & Actions
import { mapGetters, mapActions } from 'vuex'

export default {

    name: "ScenarioComponent",
    props: {
      layers: {
        type: [Object, Array],
        required: false,
        default: function () {
          return [];
        }
      },
      scenarios: {
        type: [Object, Array],
        required: false,
        default: function () {
          return [];
        }
      }
    },
    components: {
      BaseAccordion
    },
    data () {
      return {
        selectedItem: null
      }
    },
    computed: {
      ...mapGetters({
        isLayerActive: 'isLayerActive'
      }),
    },
    methods: {
      ...mapActions({
        setActiveLayer: 'setActiveLayer'
      }),
      toggleLayer(layer) {
        this.$emit("toggle-layer", layer);
      },
      
      selectItem(id) {
        this.setActiveLayer(true);
        this.selectedItem = id;
      },
    },
    watch: {
      isLayerActive: {
        handler (newVal) {
          if (!newVal) {
            this.selectedItem = null;
          }
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
.custom-active-bg {
  background-color: rgba(168, 165, 165, 0.15) !important;
  border: none !important;
}
.custom-border {
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(0.25turn, #2d3436, #98A753, transparent);
    border-image-slice: 1;
}
span {
  font-size: 1rem;
}

@media screen and (max-width: 860px) {
  span {
    font-size: 0.9rem;
  }
}

</style>