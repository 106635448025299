<template>  
    <v-snackbar
        v-model="snackStatus"
        :timeout="1500"
        color="navbutton"
    >
        <div class="w-full text-center">
            <span class="font-weight-bold">
                {{ snackText }}
            </span>
        </div>
    </v-snackbar>
</template>

<script>
export default {
    name: 'BaseSnackBar',
    props: {
        snackBar: {
            type: Boolean,
            required: true,
        },
        snackText: {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            snackStatus: false,
        }
    },
    watch: {
        snackBar (newVal) {
            if (newVal) this.snackStatus = newVal;
        }
    }
}
</script>

<style scoped>

</style>