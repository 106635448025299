<template>
  <div class="custom-shadow menu-background-wrapper">
    <div class="d-flex flex-column pa-4 menu-background-container">
      <div class="d-flex align-center px-3 mt-10 cursor-pointer">
        <v-icon 
          normal
          left
          color="navbutton"
          class="title-icon-animation"
        >
            mdi mdi-apps
        </v-icon>
        <h2 class="hidden-sm-and-down text-subtitle-1 font-weight-light">
          {{ $t("home.selectScenario") }}
        </h2>
        <h2 class="hidden-md-and-up text-subtitle-2 font-weight-light">
          {{ $t("home.selectScenario") }}
        </h2>
      </div>
      <slot name="scenario"></slot>
      <div class="mt-auto d-flex flex-column flex-wrap custom-gap align-center text-center justify-center">
        <div> 
          Copyright
          <span class="textDark--text font-weight-bold">
            &copy;
          </span> 
          {{ calcCurrentYear }} — 
          <strong class="mapbutton--text">
            EIFFEL
          </strong>
            — All Rights Reserved
        </div>
        <div class="d-flex custom-gap align-center justify-center">
          <span class="font-weight-light">Developed by</span>
          <a href="https://draxis.gr" target="_blank">
          <v-img
            contain
            width="90"
            height="25"
            src="../../assets/draxis_logo.png"
            alt="draxis_company_logo"
          >
          </v-img>
        </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'MenuContainer',
        data() {
            return {}
        },
        computed: {
          calcCurrentYear() {
            return new Date().getFullYear();
          }
        },
        methods: {}
    }
</script>

<style scoped>
.title-icon-animation {
  animation: scale 1.5s ease-in-out infinite alternate;
}

@keyframes scale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

</style>
