export const METADATA_KEYS = {

    Energy_MWh: "Annual Final Energy Use (MWh)",
    'Energy_kWhm-2': "Annual Final Energy use per heated floor area (kWh/m²)",
    CO2_t: "Annual GHG Emissions (CO2eq, t)",
    'CO2_kgm-2': "Annual GHG Emissions per heated floor area (CO2eq, kg/m²)",
    'CO2_Savings_%': "Annual GHG Emissions Savings (%)",
    'CO2_Saving': "Annual GHG Emissions Savings (%)",
    'CO2_Savings': "Annual GHG Emissions Savings (%)",
    Energy_Production_GWh: "Annual Energy Production (GWh)",
    Energy_Production_10: "Energy Production (MWh)",
    Energy_Production_40: "Energy Production (MWh)",
    Energy_Production_70: "Energy Production (MWh)",
    CO2_Equivalent_10: "CO2 Equivalent (t)",
    CO2_Equivalent_40: "CO2 Equivalent (t)",
    CO2_Equivalent_70: "CO2 Equivalent (t)",
    GHG_Emissions_Savings_kt: "GHG Emissions Savings (Kt)",
    CO2eq_SAVINGS: "CO2eq Savings (%)",
    NAME: "NAME",
    Name: "NAME",
    // name: "Road Name",
    int_name: "Road Name",
    CO2eq_kt: "Annual Road GHG Emissions (CO2eq, kt)",
    // pollutant_x_population: "Pollutant Population",
    // Percentage_Difference: "Difference from the baseline (%)",
    Difference: "Difference from the baseline (%)",
    CO2_Equivalent: "GHG Emissions Savings (t)",
    Energy_Production: "Annual Energy Production (MWh)",
    Label: "Energy Performance (EnPi)",
    CO2: "GHG Emissions (Kt)",
    Energy: "Final Energy Consumption (MWh)",
    Energy_m2: "Final Energy Consumption (kWh/m²)",
    CO2_m2: "Emissions Per Heated Areas (t/m²)",
    X_SXX_Dif: "Final Energy Consumption/GHG (%)",
    population: "Residential Population (Pop)",
    Population: "Residential Population (Pop)",
    Adequacy: "Percentage Energy Adequacy (%)",
    Municipality: "Municipality",
    Annual_PV_Energy_Production_MWh: "Annual Energy Production (MWh)",
    "Percentage_Energy_Adequacy": "Percentage Energy Adequacy (%)",
    "Adequacy(perc)": "Percentage Energy Adequacy (%)",
    "Adequacy_perc": "Percentage Energy Adequacy (%)",
    'Percentage Diffeence': "Difference from the baseline (%)",
    'Percentage Difference': "Difference from the baseline (%)",
    population_weighted_pollutant: "Population Weighted Conc. (μg m⁻³)",
    Difference_CO2: "Final energy consumption/GHG Difference (%)",
    "Percentage Difference (%)": "Difference from the baseline (%)",
    "Percentage_Difference": "Difference from the baseline (%)",
    "Pecentage_Difference": "Difference from the baseline (%)",
    "Recentage_Difference": "Difference from the baseline (%)",
    GRAY_INDEX: "Pollutant Concentration (μg m⁻³)",
    ENERGY_CONSUMPTION_GWh: "Annual Final Energy Use (GWh)",
    ENERGY_CONSUMPTION_kWh_m2: "Annual Final Energy use per heated floor (MWh/m²)",
    CO2eq_EMISSIONS_kt: "Annual GHG Emissions (CO2eq, kt)",
    CO2eq_EMISSIONS_kgr_m2: "Annual GHG Emissions per heated floor area (CO2eq, Kgr/m²)",
    ENERGY_LABELS: "Energy Performance",
    _ENERGY_CONSUMPTION_GWh: "Annual Final Energy Use (GWh)",
    ENERGY_CONSUMPTION_kwh_m2: "Annual Final Energy use per heated floor area (MWh/m²)",
};

export default METADATA_KEYS;