<template>
    <div class="text-center d-flex">
        <div class="hidden-sm-and-down">
            <v-tooltip
            v-if="!drawer"
            offset-y
            right
        >
            <template #activator="{ on, attrs }">
                <v-btn
                    depressed
                    class="mx-2 py-4 rounded custom-shadow custom-scale"
                    dark
                    x-small
                    elevation-12
                    color="textDark"
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleMenu()"
                >
                    <v-icon
                        dark
                        small
                        class="menu-icon-animation-Xaxis"
                    >
                        mdi mdi-arrow-right-thick
                    </v-icon>
                </v-btn>
            </template>
            <span>Open Menu</span>
            </v-tooltip>
            <v-tooltip
                v-else
                offset-y
                right
            >
                <template #activator="{ on, attrs }">
                    <v-btn
                        depressed
                        class="mx-2 py-4 rounded custom-shadow custom-scale"
                        dark
                        x-small
                        elevation-12
                        color="textDark"
                        v-bind="attrs"
                        v-on="on"
                        @click="toggleMenu()"
                    >
                        <v-icon
                            dark
                            small
                            class="menu-icon-animation-Xaxis"
                        >
                            mdi mdi-arrow-left-thick
                        </v-icon>
                    </v-btn>
                </template>
                <span>Close Menu</span>
            </v-tooltip>
        </div>
        <div class="hidden-md-and-up">
            <v-tooltip
              offset-y
              right
            >
            <template #activator="{ on, attrs }">
                <v-btn
                    depressed
                    class="mx-2 py-4 rounded custom-shadow custom-scale"
                    dark
                    x-small
                    elevation-12
                    color="textDark"
                    v-bind="attrs"
                    v-on="on"
                    @click="scrollToMenu()"
                >
                    <v-icon 
                        dark 
                        small
                        class="menu-icon-animation-Yaxis"
                    >
                        mdi mdi-arrow-down-thick
                    </v-icon>
                </v-btn>
            </template>
            <span>Scroll To Menu</span>
            </v-tooltip>
        </div>
    </div>
</template>


<script>
    export default {
        name: 'BaseToggleMenu',
        props: {
            drawer: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {}
        },
        methods: {
            toggleMenu() {
                this.$emit("toggle-menu", this.drawer ? false : true);
            },
            scrollToMenu() {
                window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
            }
        }
    }
</script>

<style scoped>
.menu-icon-animation-Xaxis {
    animation: slideX 1s ease-in-out infinite alternate;
}
.menu-icon-animation-Yaxis {
    animation: slideY 1s ease-in-out infinite alternate; 
}

@keyframes slideX {
    from {
        opacity: 0.7;
        transform: translateX(0);
    }
    to {
        opacity: 1;
        transform: translateX(-3px);
    }
}

@keyframes slideY {
    from {
        opacity: 0.7;
        transform: translateY(0);
    }
    to {
        opacity: 1;
        transform: translateY(-3px);
    }
}
</style>