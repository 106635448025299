<template>
    <div class="text-center">
      <v-menu 
        offset-y 
        left 
        transition="scroll-y-transition"
      >
        <template #activator="{ on: toggleMenu, attrs: menuAttrs }">
              <v-tooltip offset-y left>
                <template #activator="{ on: toggleTooltip, attrs: toolTipAttrs }">
                  <v-btn
                    depressed
                    class="mx-2 py-4 rounded custom-shadow custom-scale"
                    dark
                    x-small
                    elevation-12
                    color="scenariobutton"
                    v-bind="[ menuAttrs, toolTipAttrs ]"
                    v-on="{ ...toggleMenu, ...toggleTooltip }"
                  >
                    <v-icon dark small>
                      mdi mdi-cog-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("colorStyles.menuTitle") }}</span>
              </v-tooltip>
        </template>
        <v-list
          outlined
          class="customWhite"
        >
          <div class="d-flex align-center pa-3">
            <v-icon normal left color="navbutton">
              mdi mdi-layers-plus
            </v-icon>
            <span class="text-subtitle-1 font-weight-light">{{ $t("colorStyles.toolTip.title") }}</span>
          </div>
          <v-divider></v-divider>
          <v-list-item
            v-for="(styles, index) in wmsStyles"
            :key="index"
            class="d-flex align-center custom-hover-item"
          >
            <v-switch
              v-model="activeStyle"
              inset
              dense
              multiple
              :value="styles"
              :label="formatStyleName[styles]"
              color="navbutton"
              @click="toggleWmsStyles(styles)"
            >
            </v-switch>
            <!-- <span class="custom-title">{{ styles }}</span> -->
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </template>


<script>
export default {
    name: "BaseMapColorMenu",
    props: {
      wmsStyles: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      }
    },
    data () {
        return {
          activeStyle: [this.wmsStyles[0]],
          formatStyleName: {
            'photovoltaic_co2': 'GHG Emissions (kt)',
            'photovoltaic_energy': 'Energy Production'
          }
        }
    },
    computed: {
    },
    methods: {
      toggleWmsStyles(event) {

        if (this.activeStyle.length == 0) {
          this.$emit("selected-styles", "default");
          this.activeStyle.push(this.wmsStyles[0]);
        } else {
          this.$emit("selected-styles", event);
          this.activeStyle = this.activeStyle.filter(style => style == event);
        }
        // this.$emit("selected-styles", event);
      }
    }
}
</script>

<style scoped>
  .custom-hover-item:hover {
    background: #eeeeeecf;
  }
  .custom-title:hover {
    cursor: pointer;
    color: #5A85A9;
  }
</style>