<template>
  <v-expansion-panel
    v-if="!item.expanded"
    :expand="true"
    :disabled="hasScenarioData"
    class="mb-2 pa-0"
    style="background: rgba(255, 255, 255, 0.1)"
  >
    <v-expansion-panel-header
      expand-icon="mdi-arrow-down mapbutton--text"
      class="text-h5 font-weight-light"
    >
      <slot name="accordion-name">
        <!-- if no content applied in slot, default span above will be used -->
        <span>{{ item.name }}</span>
      </slot>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="text-h6 font-weight-light mt-3">
      <base-description-toggler
        v-if="item.dsc"
        :text="item.dsc"
        style="white-space: pre-line"
      >
      </base-description-toggler>
      <div v-else class="mt-5"></div>
      <!-- Slot that accepts itself as content in order to archieve nested dropdowns -->
      <slot name="accordion"></slot>
      <v-row v-if="item.layer">
        <v-spacer></v-spacer>
        <div class="pa-0">
          <v-tooltip offset-y left>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                elevation-2
                :color="selected ? 'navbutton' : 'scenariobutton'"
                class="white--text pa-2 rounded"
                :disabled="determineDisabled"
                v-bind="attrs"
                v-on="on"
                @click="toggleItem(item)"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
                <!-- <span class="hidden-md-and-down">{{ $t(`general.scenarioDesktop`) }}</span> -->
                <!-- <span class="hidden-md-and-up">{{ $t(`general.scenarioMobile`) }}</span> -->
                <span class="">{{ $t(`general.scenarioMobile`) }}</span>
              </v-btn>
            </template>
            <span>Toggles: {{ item.name }}</span>
          </v-tooltip>
        </div>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>

  <div
    v-else
    class="d-flex align-center justify-space-between py-2 mb-2 custom-gap"
  >
    <span class="text-caption">{{ item.name }}</span>
    <v-tooltip offset-y left>
      <template #activator="{ on, attrs }">
        <v-btn
          depressed
          elevation-2
          x-small
          :color="selected ? 'navbutton' : 'scenariobutton'"
          class="white--text rounded py-4 px-2"
          :disabled="determineDisabled"
          v-bind="attrs"
          v-on="on"
          @click="toggleItem(item)"
        >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
          <!-- <span class="text-caption hidden-md-and-down">{{ $t(`general.scenarioDesktop`) }}</span> -->
          <!-- <span class="text-caption hidden-md-and-up">{{ $t(`general.scenarioMobile`) }}</span> -->
          <span class="text-caption">{{ $t(`general.scenarioMobile`) }}</span>
        </v-btn>
      </template>
      <span>Toggles: {{ item.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import BaseDescriptionToggler from "./BaseDescriptionToggler.vue";

export default {
  name: "BaseAccordion",
  props: {
    item: {
      type: [Array, Object],
      required: false,
      default: () => {
        return [];
      },
    },
    hasScenarioData: {
      type: Boolean,
      required: false,
      default: false
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseDescriptionToggler,
  },
  data() {
    return {
      opener: [],
    };
  },
  computed: {
    determineDisabled() {
      return this.item.name.includes("Not Provided");
    },
  },
  methods: {
    toggleItem(item) {
      this.$emit("select-item"); // Change button color when clicked.
      this.$emit("toggle-item", item);
    },
  }
};
</script>

<style scoped>
span {
  font-size: 1rem;
}

.custom-active-button {
  background: red;
  color: black;
}

@media screen and (max-width: 860px) {
  span {
    font-size: 0.9rem;
  }
}
</style>
