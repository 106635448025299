<template>
    <v-row no-gutters>
      <v-main class="hidden-sm-and-down">
        <div class="d-flex">
          <v-navigation-drawer
            v-model="drawer"
            hide-overlay
            :width="drawer ? 475 : 0"
          >
          <menu-container>
            <template #scenario>
              <base-scenario-component
                class="my-10"
                :layers="layers"
                :scenarios="scenarios"
                @toggle-layer="determineSelectedLayer($event)"
              >
              </base-scenario-component>
            </template>
          </menu-container>
          </v-navigation-drawer>
          <div class="grow">
            <base-loading 
              v-if="screenLoader"
            >
            </base-loading>
            <map-container
              v-else
              :selected-layer="selectedLayer"
              :layers="layers"
              :drawer="drawer"
              :points="points"
              :geojson="geojson"
              :legends="legends"
              :selected-styles="selectedStyles"
              :selected-polygon="selectedPolygon"
              @close-layer="closeLayer($event)"
              @retrieved-clicked-position="retrievedClickedPosition($event)"
              @toggle-marker-info="toggleMarkerInfo($event)"
              @toggle-menu="toggleMenu($event)"
              @toggle-polygon="togglePolygon($event)"
            >
            </map-container>
          </div>
          <v-navigation-drawer
            v-model="featureDrawer"
            hide-overlay
            :width="featureDrawer ? 250 : 0"
          >
            <base-feature-banner
              :is-loading="isLoading"
              :feature="feature"
              :marker-name="points"
              :feature-time-stamp="featureTimeStamp"
              :features-to-show="keysToShow"
              :selected-layer-name="selectedLayer.layers"
              @close-feature-drawer="closeFeatureDrawer($event)"
            >
            </base-feature-banner>
          </v-navigation-drawer>
        </div>
      </v-main>
      <v-main class="hidden-md-and-up">
        <map-container
            :selected-layer="selectedLayer"
            :layers="layers"
            :drawer="drawer"
            :points="points"
            :geojson="geojson"
            :legends="legends"
            :selected-styles="selectedStyles"
            :selected-polygon="selectedPolygon"
            @close-layer="closeLayer($event)"
            @retrieved-clicked-position="retrievedClickedPosition($event)"
            @toggle-marker-info="toggleMarkerInfo($event)"
            @toggle-menu="toggleMenu($event)"
            @toggle-polygon="togglePolygon($event)"
        >
        </map-container>
          <menu-container>
            <template #scenario>
              <base-scenario-component
                class="my-10"
                :layers="layers"
                :scenarios="scenarios"
                @toggle-layer="determineSelectedLayer($event)"
              >
              </base-scenario-component>
            </template>
          </menu-container>
      </v-main>
      <base-feature-dialog
        :is-loading="isLoading"
        :dialog="featureDialog"
        :feature="feature"
        :marker-name="points"
        :selected-layer-name="selectedLayer.layers"
        :feature-time-stamp="featureTimeStamp"
        :features-to-show="keysToShow"
      >
      </base-feature-dialog>
      <base-marker-dialog
        :is-loading="isLoading"
        :dialog="markerDialog"
        :selected-layer="selectedLayer.layers"
        :marker-name="points"
        :marker-info="markerInfo"
      >
      </base-marker-dialog>
      <base-polygon-dialog
        :is-loading="isLoading"
        :dialog="polygonDialog"
        :selected-layer="selectedLayer.layers"
        :selected-polygon="selectedPolygon"
      >
      </base-polygon-dialog>
      <base-snack-bar
        :snack-bar="snackBarStatus"
        :snack-text="snackText"
      >
      </base-snack-bar>
    </v-row>
  </template>
  
  <script>
  import axios from "axios";
  // Importing mapAction from store
  import { mapActions } from 'vuex';
  // Importing BaseMapComponent
  import MapContainer from '../components/pages/MapContainer.vue';
  // Importing Menu Container
  import MenuContainer from '../components/pages/MenuContainer.vue';
  //Importing Dialog
  import BaseFeatureDialog from '../components/BaseFeatureDialog.vue';
  // Importing Marmker Dialog
  import BaseMarkerDialog from '../components/BaseMarkerDialog.vue';
  // Importing Polygon Dialog
  import BasePolygonDialog from '../components/BasePolygonDialog.vue';
  // Importing Scenarios Component
  import BaseScenarioComponent from '../components/BaseScenarioComponent.vue';
  // Importing Base Loading screen
  import BaseLoading from '../components/base/BaseLoading.vue';
  // Importing Snack bar
  import BaseSnackBar from "../components/BaseSnackBar.vue";
  // Importing base feature banner
  import BaseFeatureBanner from '../components/BaseFeatureBanner.vue';
  // Import WMS Layers
  import { LAYERS } from "../helpers/layerData";
  // Import Scenario's Data
  import { SCENARIOS } from "../helpers/scenarioData";
  // Import keyMapFeatures Helper
  import { KEYMAPFEATURES } from "../helpers/keyMapFeatureData";
  // Import Marker Points
  import { MARKERPOINTS } from "../helpers/pointsOfInterest";
  // Import Legends
  import { LEGENDS } from "../helpers/legendData";
  // Import Roads Data
  import { COPERT_ROADS_DATA } from "../helpers/roadsData";
  // Import copert attica & penteli data 
  import atticaGeoJson from "../helpers/atticaGeoJson.json";
  import penteliGeoJson from "../helpers/penteliGeoJson.json";

  // Used for changing the wms and coordinates projection
  import "proj4leaflet";
  import proj4 from "proj4";
  

    export default {
      name: 'HomePage',
      components: {
        MapContainer,
        BaseFeatureDialog,
        BaseSnackBar,
        BaseLoading,
        MenuContainer,
        BaseFeatureBanner,
        BasePolygonDialog,
        BaseMarkerDialog,
        BaseScenarioComponent,
      },
      data () {
        return {
          screenLoader: true,
          drawer: true,
          featureDrawer: false,
          mapObject: null,
          markerDialog: false,
          featureDialog: false,
          polygonDialog: false,
          isLoading: false,
          selectedLayer: {},
          selectedPolygon: {},
          snackBarStatus: false,
          snackText: "",
          geojson: null,
          feature: [],
          legends: [],
          selectedStyles: "",
          keysToShow: [],
          featureTimeStamp: "",
          // crs: new L.Proj.CRS("EPSG:2100", "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"), // Adjust the CRS definition
          points: [],
          markerInfo: [],
          layers: LAYERS,
          roads: COPERT_ROADS_DATA,
          scenarios: SCENARIOS
        }
      },
      methods: {
          ...mapActions({
            setActiveLayer: 'setActiveLayer'
          }),

          determineSelectedLayer(toggledLayer) {
            if (toggledLayer?.style) {
              this.selectedStyles = toggledLayer.style
            } else {
              this.selectedStyles = "";
            }
            // Checks if the event toggledLayer has a polygon to show 
            if (toggledLayer.type !== 'polygon') {
              this.geojson = null;
              // Checks if the event toggledLayer has a contour property to show on the wms layer
              if (toggledLayer.contour) {
                this.selectedLayer = {
                  ...this.layers.find(layer => layer.layers == toggledLayer.layer),
                  contour: toggledLayer.contour
                }
              } else {
                this.selectedLayer = this.layers.find(layer => layer.layers == toggledLayer.layer);
              }
            } else {
              // this.selectedLayer = {};
              this.selectedLayer = this.layers.find(layer => layer.layers === toggledLayer.layer);
              this.selectedPolygon = {
                ...toggledLayer,
                roads: this.roads.find(road => road.region == toggledLayer.name)
              };

              if (toggledLayer.polygonName == 'atticaGeoJson') {
                this.geojson = atticaGeoJson
              } else {
                this.geojson = penteliGeoJson;
              }
            }
             // Scroll to top when selecting a layer for mobile
             window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          },

          closeLayer() {
            this.selectedLayer = {};
            this.geojson = null;
            this.featureDrawer = false;
            this.setActiveLayer(false);
          },

          togglePolygon(event) {
            this.$vuetify.breakpoint.lgAndUp == true ? '' : this.toggleDialog(event);
          },

          toggleDialog(determineDialog) {
            this.isLoading = true;

            if (determineDialog == "feature") {
              this.featureDialog = !this.featureDialog;
            } else if (determineDialog == "polygon") {
              this.polygonDialog = !this.polygonDialog;
            } else if (determineDialog == "featureDrawer"){
              this.featureDrawer = true;
            } else {
              this.markerDialog = !this.markerDialog;
            }
            const timeout = setTimeout(() => {
              this.isLoading = false;
              clearTimeout(timeout);
            }, 1000);
          },

          closeFeatureDrawer(event) {
            // Close Right Side Drawer
            this.featureDrawer = event;
          },

          async retrievedClickedPosition(event) {
            if (Object.keys(this.selectedLayer).length != 0) {
              // Configure projection params for transformation
              proj4.defs([
                ["EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs"],
                // ["EPSG:3857", "+proj=merc +lon_0=0 +lat_ts=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs"]
                // ["EPSG:3857", "+proj=merc +lon_0=0 +lat_ts=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs"],
                // ["EPSG:32634", "+proj=utm +zone=34 +datum=WGS84 +units=m +no_defs"]
              ]);

              const transformedCoords = proj4("EPSG:4326", "EPSG:3857", [
                event.latlng.lng,
                event.latlng.lat,
              ]);

              // Define a buffer for BBOX only for copert scenario because the layer is too thin (type line). Cannot obtain feature collection.
              const buffer = this.selectedLayer.layers.includes("copert") ? 6000 : 0.1;
              const bbox = [
                transformedCoords[0] - buffer,
                transformedCoords[1] - buffer,
                transformedCoords[0] + buffer,
                transformedCoords[1] + buffer
              ].join(',');

              const width = 101;
              const height = 101;
              const x = Math.floor(width / 2);
              const y = Math.floor(height / 2);
              
              const featureInfoUrl = `${this.selectedLayer.url}?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&FORMAT=application/json&TRANSPARENT=true&QUERY_LAYERS=${this.selectedLayer.layers}&STYLES=&LAYERS=${this.selectedLayer.layers}&EXCEPTIONS=application/vnd.ogc.se_inimage&INFO_FORMAT=application/json&FEATURE_COUNT=50&I=${x}&J=${y}&SRS=EPSG:3857&WIDTH=${width}&HEIGHT=${height}&BBOX=${bbox}`;

              // const featureInfoUrl = `${this.selectedLayer.url}?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&FORMAT=image%2Fpng&TRANSPARENT=true&QUERY_LAYERS=${this.selectedLayer.layers}&STYLES&LAYERS=${this.selectedLayer.layers}&exceptions=application%2Fvnd.ogc.se_inimage&INFO_FORMAT=application/json&FEATURE_COUNT=50&X=${50}&Y=${50}&SRS=EPSG%3A3857&WIDTH=${101}&HEIGHT=${101}&BBOX=${transformedCoords[0] - 1}%2C${transformedCoords[1] - 1}%2C${transformedCoords[0] + 1}%2C${transformedCoords[1] + 1}`;

              try {
                const response = await axios.get(featureInfoUrl);
                if (response.data.features.length > 0) {

                  // Helper function to return only the feature that we want and pass them into the popup component
                  this.keysToShow = KEYMAPFEATURES[this.selectedLayer.layers];
                  this.featureTimeStamp = response.data.timeStamp;
                  this.feature = response.data.features[0].properties;

                  if (Object.keys(this.feature).length > 0) {
                    if (this.selectedLayer.layers.includes("copert")) {
                      this.$vuetify.breakpoint.mdAndUp ? this.toggleDialog("featureDrawer") : this.selectedPolygon = { ...this.selectedPolygon, selectedRoad: this.feature };
                    } else {
                      this.$vuetify.breakpoint.mdAndUp ? this.toggleDialog("featureDrawer") : this.toggleDialog("feature");
                    }
                  }
                } else {
                  this.featureDrawer = false;
                  // Reseting selectedRoad value when no response (user clicks outside of the layer)
                  this.selectedPolygon = { ...this.selectedPolygon, selectedRoad: null };
                }
              } catch (error) {
                console.error("Error fetching feature info:", error);
              }
            }
          },

          async toggleMarkerInfo(markerName) {
            try {
              const chartModuleData = await import(`../helpers/chart/airQuality/${this.selectedLayer.scenario}/${this.selectedLayer.season}/${this.selectedLayer.name}`);
          
              const chartModule = chartModuleData?.default?.find(data => data.name == markerName);

              // If not baseline add baseline predictions to markerInfo.
              if (!this.selectedLayer.layers.includes("baseline")) {

                const chartPredictionsData = await this.findPredictions(markerName);

                this.markerInfo = {
                  name: markerName,
                  data: chartModule.data.map(chartItem => {
                    
                    const matchPredictionItem = chartPredictionsData?.data?.find(chartPredictionItem => chartPredictionItem.date == chartItem.date);
    
                    let combinedData = {};
                    for (let key in chartItem) {
                      combinedData[key] = chartItem[key];
                    }
                    if (matchPredictionItem) {
                      for (let key in matchPredictionItem) {
                        combinedData[key] = matchPredictionItem[key];
                      }
                    }
                    return combinedData;
                  })
                }
              } else {
                // Add predictions for baseline only.
                this.markerInfo = chartModule;
              }

              this.toggleDialog("marker");
            }
            catch (error) {
              console.error("Error fetching chart data", error);
            }
          },
          
          async findPredictions(markerName) {
            try {
              const chartPredictionsData = await import(`../helpers/chart/airQuality/baselinePredictions/${this.selectedLayer.name}`);
          
              return chartPredictionsData.default.find(data => data.name == markerName);
            }
            catch (error) {
              console.error("Error fetching predictions data:", error);
            }
          },

          toggleMenu(event) {
            this.drawer = event;
          },

          async showMarkers(selectedLayer) {
            // Checks if selected layer has a scenario property which means that has chart data to show according the layerData.js schema.
              if (selectedLayer?.scenario) {
              try {
                const determineMarkersToShow = await import(`../helpers/chart/airQuality/${selectedLayer.scenario}/${selectedLayer.season}/${selectedLayer.name}`);
                determineMarkersToShow.default.forEach((data) => {
                  const collectedMarker = MARKERPOINTS.filter((marker) => {
                    return marker.name == data.name;
                  });
                  this.points.push(...collectedMarker);
                });
              } catch (error) {
                console.error("Error fetching markers:", error);
              }
            } else {
              this.points = [];
            }
          },

          determineWmsLegend(selectedLayer) {
            if (!('legend' in selectedLayer)) {
              this.legends = LEGENDS.find(legend => {
                return legend.layer == selectedLayer.layers && legend.style == this.selectedStyles;
              });
            } else {
              this.legends = [];
            }
          },

          toggleSnackBar(selectedLayer) {
            this.snackBarStatus = true;
              if (Object.keys(selectedLayer).length == 0) {
                this.snackText = `Layer deactivated!`
              } else {
                this.snackText = `${selectedLayer.layers} is activated!`;
              }
              if (Object.keys(this.selectedPolygon).length != 0) {
                this.snackText = `Viewing: ${this.selectedPolygon.name}`;
              }
              const timeout = setTimeout(() => {
                this.snackBarStatus = false;
                clearTimeout(timeout);
              }, 1500);
          },
        },
        watch: {
          selectedLayer (newVal) {
            if (newVal) {
              this.points = []; // Restore markers when layer switched
              this.featureDrawer = false;
              this.toggleSnackBar(newVal);
              this.showMarkers(newVal);
              this.determineWmsLegend(newVal);
            }
          },
          selectedStyles (newVal) {
            if (newVal) {
              this.featureDrawer = false;
              this.determineWmsLegend(this.selectedLayer);
            }
          }
        },
        mounted() {
          const timeout = setTimeout(() => {
            this.screenLoader = false;
            clearTimeout(timeout);
          }, 1500);
        }
    }
  </script>
  
  <style scoped>

  </style>