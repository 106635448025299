import { CONFIG } from "../common/config";
import { v4 as uuidv4 } from 'uuid';

export const LAYERS = [
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "julyPM25",
        season: "summer",
        scenario: "baseline",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_regional_summer_pm25',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryNO2",
        season: "winter",
        scenario: "baseline",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_regional_winter_no2',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "julyNO2",
        season: "summer",
        scenario: "baseline",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_regional_summer_no2',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryO3",
        season: "winter",
        scenario: "baseline",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_regional_winter_o3',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "julyO3",
        season: "summer",
        scenario: "baseline",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_regional_summer_o3',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryPM25",
        season: "winter",
        scenario: "baseline",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_regional_winter_pm25',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 14",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_local_winter_no2',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 15",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_local_winter_o3',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 16",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_local_winter_pm25',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 17",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_local_summer_no2',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 18",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_local_summer_o3',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 19",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:baseline_local_summer_pm25',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 26",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:scenario_1_local_winter_no2',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 27",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:scenario_1_local_winter_o3',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 28",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:scenario_1_local_winter_pm25',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryNO2",
        visible: true,
        season: "winter",
        scenario: "electificationOfBuildings",
        format: 'image/png',
        zoomLevel: 10,
        opacity: 0.7,
        layers: 'eiffel:scenario_1_regional_winter_no2',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryO3",
        visible: true,
        season: "winter",
        scenario: "electificationOfBuildings",
        format: 'image/png',
        zoomLevel: 10,
        opacity: 0.7,
        layers: 'eiffel:scenario_1_regional_winter_o3',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryPM25",
        visible: true,
        season: "winter",
        scenario: "electificationOfBuildings",
        format: 'image/png',
        zoomLevel: 10,
        opacity: 0.7,
        layers: 'eiffel:scenario_1_regional_winter_pm25',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 32",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_baseline_energy_final',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 32",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_baseline_emissions',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 32",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_baseline_performance_energy',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 33",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_30_ghg_emissions',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 33",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_30_final_energy',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 33",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_30_energy',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 33",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_30_ghg_savings',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 34",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_60_ghg_emissions',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 34",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_60_final_energy',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 34",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_60_energy',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 34",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_60_ghg_savings',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 34",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_100_ghg_emissions',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 34",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_100_final_energy',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 34",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_100_energy',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 34",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_local_scenario_elecrtif_100_ghg_savings',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryNO2",
        season: "winter",
        scenario: "electrificationVehicles",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:vehicles_regional_winter_no2',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryO3",
        season: "winter",
        scenario: "electrificationVehicles",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:vehicles_regional_winter_o3',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryPM25",
        season: "winter",
        scenario: "electrificationVehicles",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:vehicles_regional_winter_pm25',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 38",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:vehicles_local_winter_no2',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 39",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:vehicles_local_winter_o3',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 40",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:vehicles_local_winter_pm25',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryNO2",
        season: "winter",
        scenario: "electrificationBuildingsVehicles",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:buildings_vehicles_regional_winter_no2',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryO3",
        season: "winter",
        scenario: "electrificationBuildingsVehicles",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:buildings_vehicles_regional_winter_o3',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryPM25",
        season: "winter",
        scenario: "electrificationBuildingsVehicles",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:buildings_vehicles_regional_winter_pm25',
        zoomLevel: 10,
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 44",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:buildings_vehicles_regional_winter_pm25',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 45",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:buildings_vehicles_local_winter_no2',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 46",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:buildings_vehicles_local_winter_o3',
        transparent: true,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 47",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:buildings_vehicles_local_winter_pm25',
        transparent: true,
        identify: false,
    },
    // 2024 new layers added
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 48",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_scenario_elecrtif_30_ghg_emissions',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 49",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_scenario_elecrtif_30_ghg_energy',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 50",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_scenario_elecrtif_30_ghg_final_energy',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 51",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_baseline_emissions',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 52",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_baseline_energy_final',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 53",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_baseline_performance_energy',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 54",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_scenario_elecrtif_60_ghg_emissions',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 55",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_scenario_elecrtif_60_ghg_final_energy',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 56",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_scenario_elecrtif_60_ghg_energy',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 57",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_scenario_elecrtif_100_ghg_emissions',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 58",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_scenario_elecrtif_100_ghg_final_energy',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 59",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_scenario_elecrtif_100_ghg_energy',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 60",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_hybrid_ghg_emissions',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 61",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_hybrid_ghg_final_energy',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 62",
        visible: true,
        format: 'image/png',
        opacity: 0.7,
        layers: 'eiffel:building_regional_hybrid_ghg_energy',
        transparent: true,
        zoomLevel: 10,
        identify: false,
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 63",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_10_savings",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 64",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_10_energy_production",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 65",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_10_adequacy",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 66",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_40_savings",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 67",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_40_energy_production",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 68",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_40_adequacy",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 69",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_70_savings",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 70",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_70_energy_production",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 71",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_70_adequacy",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 72",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_baseline_savings",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 73",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_baseline_energy_production",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 74",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_regional_baseline_adequacy",
        transparent: true,
        zoomLevel: 10,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 75",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_10_savings",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 76",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_10_energy_production",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 77",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_10_adequacy",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 78",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_40_savings",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 79",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_40_energy_production",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 80",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_40_adequacy",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 81",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_70_savings",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 82",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_70_energy_production",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 83",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_70_adequacy",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 84",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_baseline_savings",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 85",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_baseline_energy_production",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 86",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_stepwise_approach_local_baseline_adequacy",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 87",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:photovoltaic_schools",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryNO2",
        season: "winter",
        scenario: "electrificationOfBuildings30",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "air_quality_electification_of_buildings_regional_winter_30_no2",
        zoomLevel: 10,
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryO3",
        season: "winter",
        scenario: "electrificationOfBuildings30",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "air_quality_electification_of_buildings_regional_winter_30_o3",
        zoomLevel: 10,
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryPM25",
        season: "winter",
        scenario: "electrificationOfBuildings30",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "air_quality_electification_of_buildings_regional_winter_30_pm25",
        zoomLevel: 10,
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 88",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "air_quality_electification_of_buildings_local_winter_30_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 89",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "air_quality_electification_of_buildings_local_winter_30_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 90",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "air_quality_electification_of_buildings_local_winter_30_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 91",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:building_regional_scenario_elecrtif_30_ghg_savings",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 92",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:building_regional_scenario_elecrtif_60_ghg_savings",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 93",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:building_regional_scenario_elecrtif_100_ghg_savings",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 94",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:building_regional_hybrid_ghg_savings",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 95",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 12,
        layers: "eiffel:copert_roads_local_baseline",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 96",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 12,
        layers: "eiffel:copert_roads_local_optimistic_scenario",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 97",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 12,
        layers: "eiffel:copert_roads_local_base_scenario",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 98",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 12,
        layers: "eiffel:copert_roads_local_bau_scenario",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 99",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 12,
        layers: "eiffel:copert_roads_local_public_transportation",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 100",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 10,
        layers: "eiffel:copert_roads_regional_baseline",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 101",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 10,
        layers: "eiffel:copert_roads_regional_optimistic_scenario",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 102",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 10,
        layers: "eiffel:copert_roads_regional_base_scenario",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 103",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 10,
        layers: "eiffel:copert_roads_regional_bau_scenario",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 104",
        visible: true,
        format: "image/png",
        opacity: 1,
        zoomLevel: 10,
        layers: "eiffel:copert_roads_regional_public_transportation",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        format: "image/png",
        name: "januaryNO2",
        season: "grFocused",
        scenario: "electrificationOfVehicles",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_electrif_of_vehicles_regional_gr_focused_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        format: "image/png",
        name: "januaryO3",
        season: "grFocused",
        scenario: "electrificationOfVehicles",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_electrif_of_vehicles_regional_gr_focused_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        format: "image/png",
        name: "januaryPM25",
        season: "grFocused",
        scenario: "electrificationOfVehicles",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_electrif_of_vehicles_regional_gr_focused_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 106",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_electrif_of_vehicles_local_gr_focused_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 107",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_electrif_of_vehicles_local_gr_focused_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 108",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_electrif_of_vehicles_local_gr_focused_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryNO2",
        season: "30grFocused",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_30_gr_focused_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryO3",
        season: "30grFocused",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_30_gr_focused_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryPM25",
        season: "30grFocused",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_30_gr_focused_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryNO2",
        season: "60grOptimistic",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryO3",
        season: "60grOptimistic",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryPM25",
        season: "60grOptimistic",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_60_gr_optimistic_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryNO2",
        season: "100grOptimistic",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryO3",
        season: "100grOptimistic",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryPM25",
        season: "100grOptimistic",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_100_gr_optimistic_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryNO2",
        season: "100grFocused",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_100_gr_focused_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryO3",
        season: "100grFocused",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_100_gr_focused_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "januaryPM25",
        season: "100grFocused",
        scenario: "combinedElectrificationInterventions",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        zoomLevel: 10,
        layers: "eiffel:aq_comb_electrif_invert_regional_100_gr_focused_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 109",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 110",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 111",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_30_gr_focused_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 112",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 113",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 114",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_60_gr_optimistic_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 115",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 116",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 117",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_100_gr_optimistic_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 118",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 119",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 120",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_100_gr_focused_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 121",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:building_local_hybrid_ghg_emissions",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 122",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:building_local_hybrid_ghg_final_energy",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 123",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:building_local_hybrid_ghg_energy",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 124",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:building_local_hybrid_ghg_savings",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        name: "januaryNO2",
        season: "grBau",
        scenario: "electrificationOfVehicles",
        format: "image/png",
        zoomLevel: 10,
        opacity: 0.7,
        layers: "eiffel:aq_electrif_of_vehicles_regional_gr_bau_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        name: "januaryO3",
        season: "grBau",
        scenario: "electrificationOfVehicles",
        format: "image/png",
        zoomLevel: 10,
        opacity: 0.7,
        layers: "eiffel:aq_electrif_of_vehicles_regional_gr_bau_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        name: "januaryPM25",
        season: "grBau",
        scenario: "electrificationOfVehicles",
        format: "image/png",
        zoomLevel: 10,
        opacity: 0.7,
        layers: "eiffel:aq_electrif_of_vehicles_regional_gr_bau_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 128",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_electrif_of_vehicles_local_gr_bau_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 129",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_electrif_of_vehicles_local_gr_bau_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 130",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_electrif_of_vehicles_local_gr_bau_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 131",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_60_gr_base_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 132",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_60_gr_base_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 133",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_60_gr_base_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 134",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 135",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        name: "layer 136",
        visible: true,
        format: "image/png",
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_local_30_gr_bau_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        name: "januaryNO2",
        season: "60grBase",
        scenario: "combinedElectrificationInterventions",
        format: "image/png",
        zoomLevel: 10,
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_regional_60_gr_base_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        name: "januaryO3",
        season: "60grBase",
        scenario: "combinedElectrificationInterventions",
        format: "image/png",
        zoomLevel: 10,
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_regional_60_gr_base_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        name: "januaryPM25",
        season: "60grBase",
        scenario: "combinedElectrificationInterventions",
        format: "image/png",
        zoomLevel: 10,
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_regional_60_gr_base_pm25",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        name: "januaryNO2",
        season: "30grBau",
        scenario: "combinedElectrificationInterventions",
        format: "image/png",
        zoomLevel: 10,
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_regional_30_gr_bau_no2",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        name: "januaryO3",
        season: "30grBau",
        scenario: "combinedElectrificationInterventions",
        format: "image/png",
        zoomLevel: 10,
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_regional_30_gr_bau_o3",
        transparent: true,
        identify: false
    },
    {
        id: uuidv4(),
        url: CONFIG.wms_layer,
        visible: true,
        name: "januaryPM25",
        season: "30grBau",
        scenario: "combinedElectrificationInterventions",
        format: "image/png",
        zoomLevel: 10,
        opacity: 0.7,
        layers: "eiffel:aq_comb_electrif_invert_regional_30_gr_bau_pm25",
        transparent: true,
        identify: false
    },
];

export default LAYERS;