<template>
  <div>
    <l-map
      ref="myMap"
      style="border: none !important;"
      :style="{ height: determineHeight }"
      class="custom-shadow"
      width="100%"
      :zoom="zoom"
      :center="center"
      :max-bounds="maxBounds"
      :options="{ ...options, attributionControl: false, tiled: true }"
      @ready="mapReady()"
      @click="onMapClick"
    >
      <l-tile-layer
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
      >
      </l-tile-layer>
      <slot name="tooltip"></slot>
      <slot name="controlZoom"></slot>
      <slot name="controlWMS"></slot>
      <slot name="controlPolygon"></slot>
      <slot name="wms"></slot>
      <slot name="legend"></slot>
      <slot name="marker"></slot>
      <slot name="drawer"></slot>
      <!-- <slot name="controlScale"></slot> -->
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer } from "vue2-leaflet";
// import L from "leaflet";
// import "proj4leaflet";
// import proj4 from "proj4";

// import "proj4";

// import "leaflet-providers"; // Import leaflet-providers

// import { CRS, latLng } from "leaflet";


// const swissCrs = new L.Proj.CRS(
//   'EPSG:2100',
//   '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs',
//   {
//     origin: [0, 0],
//     resolutions: [
//       8192, 4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25
//     ]
//  });


export default {
  name: "BaseMapComponent",
  props: {
    options: {
      type: Object,
      required: true,
    },
    zoom: {
      type: Number,
      required: true,
    },
    center: {
      type: Array,
      required: true,
    },
    maxBounds: {
      type: [Object, Array],
      required: false,
      default: function () {
        return [];
      },
    },
  },
  components: {
    LMap,
    LTileLayer,
  },
  data () {
    return {
      // crs: swissCrs,
      tileProvider: {
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' // Default map tile
      },
      resizeObserver: null,
    };
  },
  computed: {
    determineHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '93vh' : '50vh'; 
    }
  },
  methods: {
    onMapClick(pos) {
      this.$emit("find-coords", pos);
    },
    mapReady() {
      const myMap = this.$refs["myMap"];

      this.resizeObserver = new ResizeObserver(() => {
        myMap?.mapObject?.invalidateSize();
      });
      this.resizeObserver?.observe(myMap?.$el);
      this.$emit("map-ready", myMap?.mapObject);
    }
  }
};
</script>

<style scoped>

</style>
