import { v4 as uuidv4 } from 'uuid';

export const COPERT_ROADS_DATA = [
    {
        region: "Municipality of Penteli",
        scenarios: [
            {
                name: "baseline",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Papandreou",
                        pollutants: {
                            CO2: "6350",
                            CH4: "1.02",
                            N2O: "0.13",
                            CO2EQ: "6415",
                        },
                    },
                    {
                        id: uuidv4(),
                        name: "Venizelou",
                        pollutants: {
                            CO2: "2223",
                            CH4: "0.36",
                            N2O: "0.05",
                            CO2EQ: "2245",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Pentelis",
                        pollutants: {
                            CO2: "4763",
                            CH4: "0.76",
                            N2O: "0.10",
                            CO2EQ: "4812",
                        }
                    },
                ]
            },
            {
                name: "optimistic",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Papandreou",
                        pollutants: {
                            CO2: "4725",
                            CH4: "0.76",
                            N2O: "0.10",
                            CO2EQ: "4773",
                        },
                    },
                    {
                        id: uuidv4(),
                        name: "Venizelou",
                        pollutants: {
                            CO2: "1654",
                            CH4: "0.27",
                            N2O: "0.03",
                            CO2EQ: "1671",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Pentelis",
                        pollutants: {
                            CO2: "3543",
                            CH4: "0.57",
                            N2O: "0.07",
                            CO2EQ: "3580",
                        }
                    },
                ]
            },
            {
                name: "base",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Papandreou",
                        pollutants: {
                            CO2: "5309",
                            CH4: "0.85",
                            N2O: "0.11",
                            CO2EQ: "5363",
                        },
                    },
                    {
                        id: uuidv4(),
                        name: "Venizelou",
                        pollutants: {
                            CO2: "1858",
                            CH4: "0.30",
                            N2O: "0.04",
                            CO2EQ: "1877",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Pentelis",
                        pollutants: {
                            CO2: "3982",
                            CH4: "0.64",
                            N2O: "0.08",
                            CO2EQ: "4022",
                        }
                    },
                ]
            },
            {
                name: "bau",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Papandreou",
                        pollutants: {
                            CO2: "5654",
                            CH4: "0.91",
                            N2O: "0.12",
                            CO2EQ: "5712",
                        },
                    },
                    {
                        id: uuidv4(),
                        name: "Venizelou",
                        pollutants: {
                            CO2: "1979",
                            CH4: "0.32",
                            N2O: "0.04",
                            CO2EQ: "1999",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Pentelis",
                        pollutants: {
                            CO2: "4240",
                            CH4: "0.68",
                            N2O: "0.09",
                            CO2EQ: "4284",
                        }
                    },
                ]
            },
            {
                name: "transportation",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Papandreou",
                        pollutants: {
                            CO2: "4582",
                            CH4: "0.74",
                            N2O: "0.10",
                            CO2EQ: "4629",
                        },
                    },
                    {
                        id: uuidv4(),
                        name: "Venizelou",
                        pollutants: {
                            CO2: "1604",
                            CH4: "0.26",
                            N2O: "0.03",
                            CO2EQ: "1620",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Pentelis",
                        pollutants: {
                            CO2: "3436",
                            CH4: "0.55",
                            N2O: "0.07",
                            CO2EQ: "3472",
                        }
                    },
                ]
            }
        ]
    },
    {
        region: "Region of Attica",
        scenarios: [
            {
                name: "baseline",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Attiki",
                        pollutants: {
                            CO2: "647885",
                            CH4: "101",
                            N2O: "14",
                            CO2EQ: "653485"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Poseidonos",
                        pollutants: {
                            CO2: "434392",
                            CH4: "68",
                            N2O: "9",
                            CO2EQ: "438147"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Athinon",
                        pollutants: {
                            CO2: "407219",
                            CH4: "65",
                            N2O: "9",
                            CO2EQ: "411397",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisias",
                        pollutants: {
                            CO2: "447695",
                            CH4: "72",
                            N2O: "9",
                            CO2EQ: "452289",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisou",
                        pollutants: {
                            CO2: "1295398",
                            CH4: "208",
                            N2O: "27",
                            CO2EQ: "1308691",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Mesogeion",
                        pollutants: {
                            CO2: "247651",
                            CH4: "40",
                            N2O: "5",
                            CO2EQ: "250192",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Sygrou",
                        pollutants: {
                            CO2: "214315",
                            CH4: "34",
                            N2O: "4",
                            CO2EQ: "216514",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Vouliagmenis",
                        pollutants: {
                            CO2: "511970",
                            CH4: "82",
                            N2O: "11",
                            CO2EQ: "517223",
                        }
                    },
                ]
            },
            {
                name: "optimistic",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Attiki",
                        pollutants: {
                            CO2: "482133",
                            CH4: "75",
                            N2O: "10",
                            CO2EQ: "486300"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Poseidonos",
                        pollutants: {
                            CO2: "323037",
                            CH4: "51",
                            N2O: "7",
                            CO2EQ: "325829"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Athinon",
                        pollutants: {
                            CO2: "302971",
                            CH4: "49",
                            N2O: "6",
                            CO2EQ: "306080",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisias",
                        pollutants: {
                            CO2: "333085",
                            CH4: "53",
                            N2O: "7",
                            CO2EQ: "336503",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisou",
                        pollutants: {
                            CO2: "963776",
                            CH4: "155",
                            N2O: "20",
                            CO2EQ: "973666",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Mesogeion",
                        pollutants: {
                            CO2: "184252",
                            CH4: "30",
                            N2O: "4",
                            CO2EQ: "186143",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Sygrou",
                        pollutants: {
                            CO2: "159451",
                            CH4: "26",
                            N2O: "3",
                            CO2EQ: "161087",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Vouliagmenis",
                        pollutants: {
                            CO2: "380906",
                            CH4: "61",
                            N2O: "8",
                            CO2EQ: "384814",
                        }
                    },
                ]
            },
            {
                name: "base",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Attiki",
                        pollutants: {
                            CO2: "541432",
                            CH4: "84",
                            N2O: "12",
                            CO2EQ: "546112"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Poseidonos",
                        pollutants: {
                            CO2: "363284",
                            CH4: "57",
                            N2O: "8",
                            CO2EQ: "366424"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Athinon",
                        pollutants: {
                            CO2: "340435",
                            CH4: "55",
                            N2O: "7",
                            CO2EQ: "343928",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisias",
                        pollutants: {
                            CO2: "374273",
                            CH4: "60",
                            N2O: "8",
                            CO2EQ: "378114",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisou",
                        pollutants: {
                            CO2: "1082953",
                            CH4: "174",
                            N2O: "23",
                            CO2EQ: "1094066",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Mesogeion",
                        pollutants: {
                            CO2: "207036",
                            CH4: "33",
                            N2O: "4",
                            CO2EQ: "209161",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Sygrou",
                        pollutants: {
                            CO2: "179168",
                            CH4: "29",
                            N2O: "4",
                            CO2EQ: "181006",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Vouliagmenis",
                        pollutants: {
                            CO2: "428007",
                            CH4: "69",
                            N2O: "9",
                            CO2EQ: "432399",
                        }
                    },
                ]
            },
            {
                name: "bau",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Attiki",
                        pollutants: {
                            CO2: "577101",
                            CH4: "90",
                            N2O: "12",
                            CO2EQ: "582089"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Poseidonos",
                        pollutants: {
                            CO2: "386584",
                            CH4: "61",
                            N2O: "8",
                            CO2EQ: "389926"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Athinon",
                        pollutants: {
                            CO2: "362547",
                            CH4: "58",
                            N2O: "8",
                            CO2EQ: "366267",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisias",
                        pollutants: {
                            CO2: "398583",
                            CH4: "64",
                            N2O: "8",
                            CO2EQ: "402673",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisou",
                        pollutants: {
                            CO2: "1153293",
                            CH4: "185",
                            N2O: "24",
                            CO2EQ: "1165128",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Mesogeion",
                        pollutants: {
                            CO2: "220484",
                            CH4: "35",
                            N2O: "5",
                            CO2EQ: "222746",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Sygrou",
                        pollutants: {
                            CO2: "190805",
                            CH4: "31",
                            N2O: "4",
                            CO2EQ: "192763",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Vouliagmenis",
                        pollutants: {
                            CO2: "455807",
                            CH4: "73",
                            N2O: "10",
                            CO2EQ: "460484",
                        }
                    },
                ]
            },
            {
                name: "transportation",
                roads: [
                    {
                        id: uuidv4(),
                        name: "Attiki",
                        pollutants: {
                            CO2: "467380",
                            CH4: "73",
                            N2O: "10",
                            CO2EQ: "471420"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Poseidonos",
                        pollutants: {
                            CO2: "313504",
                            CH4: "49",
                            N2O: "6",
                            CO2EQ: "316214"
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Athinon",
                        pollutants: {
                            CO2: "293809",
                            CH4: "47",
                            N2O: "6",
                            CO2EQ: "296823",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisias",
                        pollutants: {
                            CO2: "323012",
                            CH4: "52",
                            N2O: "7",
                            CO2EQ: "326326",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Kifisou",
                        pollutants: {
                            CO2: "934630",
                            CH4: "150",
                            N2O: "20",
                            CO2EQ: "944221",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Mesogeion",
                        pollutants: {
                            CO2: "178680",
                            CH4: "29",
                            N2O: "4",
                            CO2EQ: "180514",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Sygrou",
                        pollutants: {
                            CO2: "154628",
                            CH4: "25",
                            N2O: "3",
                            CO2EQ: "156215",
                        }
                    },
                    {
                        id: uuidv4(),
                        name: "Vouliagmenis",
                        pollutants: {
                            CO2: "369386",
                            CH4: "59",
                            N2O: "8",
                            CO2EQ: "373177",
                        }
                    },
                ]
            }
        ]
    }
];

export default COPERT_ROADS_DATA;